import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
    VideoContent,
} from "JS/Models";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import sortableHeader from "JS/React/HOC/SortableHeader";
import {
    ColumnSort,
    getColumnSort,
    sortData,
    Sorting,
    sortViaAcquiredDate,
    sortViaPlayedDate,
} from "JS/Types";
import { useRouting } from "JS/React/Hooks/Routes";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getPublicUrl } from "JS/Helpers";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useAppSelector } from "JS/Redux/Store";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { useGlobalVideos } from "JS/React/Hooks/Video";
import { config } from "JS/Config";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { AppTypography } from "JS/React/Components/AppTypography";
import { VideoCategoryTitle } from "../index";
import { useGetDownloadedVideo } from "JS/React/Hooks/Database";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { messaging } from "JS/Helpers/UserMessaging";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    emptyList: {
        marginTop: theme.spacing(3),
        color: theme.palette.grey[500],
    },
}));

export interface VideoListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    videos: (VideoContent | Gifts)[];
    paginationInfo: PaginationInfo;
    searchQuery?: string;
    categoryId?: string;
    resetPagination?: () => void;
}

const SortableHeader = sortableHeader<HTMLSpanElement>("span");

export const VideoList = (props: VideoListProps) => {
    const classes = useStyles(props);
    const {
        className,
        videos: content,
        paginationInfo,
        categoryId,
        searchQuery,
        resetPagination,
        ...rest
    } = props;

    const { resumeUserVideos } = useGlobalVideos();

    const { favoritesVideos } = useGlobalFavorites();

    useGetFavorites(!!favoritesVideos && favoritesVideos.length > 0);

    const { setGlobalVideosStack, videosStack } = useGlobalNavStack();

    const { verifyAwsKeys } = useVerifyAwsKeys();

    const [sorting, setSorting] = useState<Sorting>(
        categoryId === VideoCategoryTitle.RECENTLY_PLAYED
            ? [
                  {
                      col: "played",
                      dir: "desc",
                      position: 0,
                  },
              ]
            : videosStack?.sorting,
    );

    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const onSortChange = (columnSort: ColumnSort, oldOrder: number) => {
        resetPagination && resetPagination();
        setSorting([columnSort]);
        setGlobalVideosStack({ sorting: [columnSort] });
    };

    const finalData = useMemo(() => {
        if (sorting[0].col === "acquired_date") {
            const newContent: (VideoContent | Gifts)[] = content.map((d) => {
                if (d.acquired_date) {
                    return d;
                } else {
                    if (d.sender) {
                        if (d.sender.sending_date) {
                            const timeStamp = moment(d.sender.sending_date_time)
                                .unix()
                                .toString();
                            return {
                                ...d,
                                acquired_date: timeStamp,
                            };
                        } else {
                            return d;
                        }
                    } else {
                        return d;
                    }
                }
            });
            return paginateData(
                sortViaAcquiredDate(newContent, sorting),
                paginationInfo,
            ).data;
        }
        if (
            sorting[0].col === "played" &&
            categoryId === VideoCategoryTitle.RECENTLY_PLAYED
        )
            return paginateData(
                sortViaPlayedDate(content, sorting),
                paginationInfo,
            ).data;

        if (
            sorting[0].col === "played" &&
            categoryId !== VideoCategoryTitle.RECENTLY_PLAYED
        )
            return paginateData(
                sortViaPlayedDate(content, sorting),
                paginationInfo,
            ).data;

        return paginateData(sortData(content, sorting), paginationInfo).data;
    }, [content, sorting, paginationInfo]);

    const { getDownloadedStatusById } = useDownloadedStatus(finalData);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (video: VideoContent | Gifts) => {
        const url = await getPublicUrl(
            video.image_url_prefix,
            video.image_url_postfix,
            video.image_file_name,
        );

        return {
            id: video.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d as VideoContent);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    const isResumeable = (filteredVideo: VideoContent | Gifts) => {
        if (
            resumeUserVideos &&
            resumeUserVideos.filter((x) => x.nid === filteredVideo.nid).length >
                0
        ) {
            const resumeData = resumeUserVideos.find(
                (x) => x.nid === filteredVideo.nid,
            );
            return !!resumeData;
        } else {
            return false;
        }
    };

    const isFavouriteContent = (filteredVideo: VideoContent | Gifts) => {
        if (
            favoritesVideos &&
            favoritesVideos.find((x) => x.nid === filteredVideo.nid)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const { handleListItemClick } = useHandlers(searchQuery, categoryId);

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            <Box display={"flex"} justifyContent={"space-around"}>
                <SortableHeader
                    upDirection="asc"
                    downDirection="desc"
                    sort={getColumnSort(sorting, {
                        col: "title",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>A-Z</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "release_date",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Released</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "acquired_date",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Acquired</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "played",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Played</span>
                </SortableHeader>
            </Box>

            <RenderIf
                isTrue={!!finalData.length}
                fallback={
                    <AppTypography
                        className={classes.emptyList}
                        align="center"
                        variant="h6"
                    >
                        {messaging.common.noItemsFound}
                    </AppTypography>
                }
            >
                <Box>
                    {finalData.map((filteredVideo, idx) => {
                        const status = getDownloadedStatusById(
                            filteredVideo?.nid,
                        );

                        return (
                            <AppContentListItem
                                nid={filteredVideo?.nid}
                                isDownloadInProgress={
                                    status?.isDownloadInProgress
                                }
                                isInDownloadQueue={status?.isInDownloadQueue}
                                isDownloaded={status?.isDownloaded}
                                inProgressDownloaded={
                                    status?.inProgressDownloaded
                                }
                                type="media"
                                key={idx}
                                sender={(filteredVideo as any).sender}
                                isReceived={filteredVideo.isReceived}
                                isResume={isResumeable(filteredVideo)}
                                title={filteredVideo.title}
                                skuId={filteredVideo.sku_id}
                                attachedSku={filteredVideo?.attach_skus}
                                description={filteredVideo.description}
                                releaseDate={filteredVideo.release_date}
                                acquiredDate={filteredVideo.acquired_date}
                                played={filteredVideo.played}
                                sortType={sorting[0].col}
                                showPlayIcon={
                                    filteredVideo.is_purchased ||
                                    filteredVideo.isReceived ||
                                    filteredVideo.publish_free === "1"
                                }
                                showFavIcon={isFavouriteContent(filteredVideo)}
                                isFavourite={isFavouriteContent(filteredVideo)}
                                imageUrl={
                                    thumbnails && thumbnails[filteredVideo.nid]
                                }
                                isAllowClick
                                onListItemClick={() =>
                                    handleListItemClick(filteredVideo, true)
                                }
                                onItemPlayClick={() =>
                                    handleListItemClick(filteredVideo, true)
                                }
                            />
                        );
                    })}
                </Box>
            </RenderIf>
        </div>
    );
};

const useDownloadedStatus = (data: (VideoContent | Gifts)[]) => {
    const { isVideoDownloaded } = useDownloadHelpers();
    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const firstRef = useRef(true);
    const inProgressRef = useRef(inProgressNid);
    const downloadedContentNidsRef = useRef(downloadedContentNids);

    const [downloadStatus, setDownloadedStatus] = useState<
        {
            nid: string;
            isDownloadInProgress: boolean;
            isInDownloadQueue: boolean;
            isDownloaded: Promise<boolean>;
            inProgressDownloaded: boolean;
        }[]
    >([]);

    useEffect(() => {
        inProgressRef.current = inProgressNid;
    }, [inProgressNid]);

    useEffect(() => {
        downloadedContentNidsRef.current = downloadedContentNids;
    }, [downloadedContentNids]);

    const calculateDownloadStatus = (data: (VideoContent | Gifts)[]) => {
        const status = data.map((d) => ({
            nid: d.nid,
            isDownloadInProgress: inProgressRef.current === d.nid,
            isInDownloadQueue:
                isInDownloadQueue(d.nid) && inProgressRef.current !== d.nid,
            isDownloaded: isVideoDownloaded(d.nid),
            inProgressDownloaded: downloadedContentNidsRef.current?.includes(
                `${config.user.memberId}-${d.nid}`,
            ),
        }));

        setDownloadedStatus(status);
    };

    const getDownloadedStatusById = (nid: string) => {
        return downloadStatus.find((x) => x.nid === nid);
    };

    useEffect(() => {
        const calculate = () => {
            if (data && data.length) {
                firstRef.current = false;
                calculateDownloadStatus(data);
            }
        };

        if (firstRef.current) {
            calculate();
        }

        const statusInterval = setInterval(() => {
            calculate();
        }, 15000);

        return () => clearInterval(statusInterval);
    }, [data]);

    return {
        getDownloadedStatusById,
    };
};

const useDownloadHelpers = () => {
    const { getDownloadedVideoById } = useGetDownloadedVideo();

    const isVideoDownloaded = async (nid: string) => {
        const response = await getDownloadedVideoById(
            `${config.user.memberId}-${nid}`,
        );

        return response ? true : false;
    };

    return {
        isVideoDownloaded,
    };
};

const useHandlers = (searchQuery: string, categoryId: string) => {
    const { linkProvider } = useRouting();
    const { logFirebaseEvent } = useFirebaseLogger();
    const history = useHistory();

    const handleListItemClick = (
        filteredVideo: VideoContent | Gifts,
        isAutoPlay: boolean,
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredVideo.isReceived
                    ? EventCategories.GIFT_VIDEOS
                    : EventCategories.VIDEOS,
                skuId: filteredVideo.sku_id,
                nId: filteredVideo.nid,
                contentTitle: filteredVideo.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredVideo.isReceived
                    ? EventNames.GIFT_VIDEO_SEARCH_OPENED
                    : EventNames.VIDEO_SEARCH_VIDEO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .videos()
                .detail(
                    filteredVideo.nid,
                    categoryId ? categoryId : filteredVideo.content_category_id,
                    isAutoPlay.toString(),
                ),
        );
    };

    return {
        handleListItemClick,
    };
};
