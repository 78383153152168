import { Box, InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getPublicUrl } from "JS/Helpers";
import {
    AudioContent,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import sortableHeader from "JS/React/HOC/SortableHeader";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import {
    ColumnSort,
    getColumnSort,
    sortData,
    Sorting,
    sortViaAcquiredDate,
    sortViaPlayedDate,
} from "JS/Types";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useAppSelector } from "JS/Redux/Store";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { config } from "JS/Config";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

export interface AudioFavListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    audios: AudioContent[];
    paginationInfo: PaginationInfo;
    searchQuery?: string;
    categoryId?: string;
}

const SortableHeader = sortableHeader<HTMLSpanElement>("span");

export const AudioFavList = (props: AudioFavListProps) => {
    const classes = useStyles(props);
    const {
        className,
        audios: content,
        paginationInfo,
        searchQuery,
        categoryId,
        ...rest
    } = props;

    const { verifyAwsKeys } = useVerifyAwsKeys();

    const { resumeUserBundles, resumeUserAudios } = useGlobalAudios();

    const { setGlobalFavoritesAudiosStack, favoritesAudiosStack } =
        useGlobalNavStack();
    const { handleListItemClick } = useHandlers(props);

    const { favoritesAudios } = useGlobalFavorites();
    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const [sorting, setSorting] = useState<Sorting>(
        favoritesAudiosStack?.sorting || [
            {
                col: "title",
                dir: "asc",
                position: 0,
            },
        ],
    );
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const onSortChange = (columnSort: ColumnSort, oldOrder: number) => {
        setSorting([columnSort]);
        setGlobalFavoritesAudiosStack({ sorting: [columnSort] });
    };

    const finalData = useMemo(() => {
        if (sorting[0].col === "acquired_date")
            return paginateData(
                sortViaAcquiredDate(content, sorting),
                paginationInfo,
            ).data;
        if (sorting[0].col === "played")
            return paginateData(
                sortViaPlayedDate(content, sorting),
                paginationInfo,
            ).data;
        return paginateData(sortData(content, sorting), paginationInfo).data;
    }, [content, sorting, paginationInfo]);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (audio: AudioContent) => {
        const url = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        return {
            id: audio.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    const isAudioDownloaded = async (nid: string) => {
        const response = await getDexieConnectionRefresh().audios.get(
            `${config.user.memberId}-${nid}`,
        );
        return response ? true : false;
    };
    const isResumeable = (filteredAudio: AudioContent) => {
        if (filteredAudio.media.length > 1) {
            let filterResumeBundle =
                resumeUserBundles &&
                resumeUserBundles.filter((x) => x.nid === filteredAudio.nid);
            return filterResumeBundle.length > 0;
        } else {
            if (
                resumeUserAudios &&
                resumeUserAudios.filter((x) => x.nid === filteredAudio.nid)
                    .length > 0
            ) {
                const resumeData = resumeUserAudios.find(
                    (x) => x.nid === filteredAudio.nid,
                );
                return !!resumeData;
            } else {
                return false;
            }
        }
    };

    const isFavouriteContent = (filteredAudio: AudioContent) => {
        if (
            favoritesAudios &&
            favoritesAudios.find((x) => x.nid === filteredAudio.nid)
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            <Box display={"flex"} justifyContent={"space-around"}>
                <SortableHeader
                    sort={getColumnSort(sorting, {
                        col: "title",
                        position: sorting.length,
                    })}
                    upDirection="asc"
                    downDirection="desc"
                    onSortChanged={onSortChange}
                >
                    <span>A-Z</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "release_date",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Released</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "acquired_date",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Acquired</span>
                </SortableHeader>
                <SortableHeader
                    upDirection="desc"
                    downDirection="asc"
                    sort={getColumnSort(sorting, {
                        col: "played",
                        position: sorting.length,
                    })}
                    onSortChanged={onSortChange}
                >
                    <span>Played</span>
                </SortableHeader>
            </Box>
            <Box>
                {finalData?.map((filteredAudio, idx) => (
                    <AppContentListItem
                        nid={filteredAudio.nid}
                        isDownloadInProgress={
                            inProgressNid === filteredAudio.nid
                        }
                        isInDownloadQueue={isInDownloadQueue(filteredAudio.nid)}
                        isDownloaded={isAudioDownloaded(filteredAudio.nid)}
                        inProgressDownloaded={downloadedContentNids?.includes(
                            `${config.user.memberId}-${filteredAudio.nid}`,
                        )}
                        key={idx}
                        type="media"
                        isReceived={filteredAudio.isReceived}
                        isResume={isResumeable(filteredAudio)}
                        title={filteredAudio.title}
                        skuId={filteredAudio.sku_id}
                        attachedSku={filteredAudio?.attach_skus}
                        description={filteredAudio.description}
                        releaseDate={filteredAudio.release_date}
                        acquiredDate={filteredAudio.acquired_date}
                        sender={(filteredAudio as any).sender}
                        sortType={sorting[0].col}
                        played={filteredAudio.played}
                        showPlayIcon={
                            filteredAudio.is_purchased ||
                            filteredAudio.publish_free === "1"
                        }
                        showFavIcon={isFavouriteContent(filteredAudio)}
                        isFavourite={isFavouriteContent(filteredAudio)}
                        imageUrl={thumbnails && thumbnails[filteredAudio.nid]}
                        isAllowClick
                        onListItemClick={() =>
                            handleListItemClick(filteredAudio, "false")
                        }
                        onItemPlayClick={() =>
                            handleListItemClick(filteredAudio, "false")
                        }
                    />
                ))}
            </Box>
        </div>
    );
};

const useHandlers = (props: AudioFavListProps) => {
    const { searchQuery, categoryId } = props;

    const { logFirebaseEvent } = useFirebaseLogger();
    const { linkProvider } = useRouting();
    const history = useHistory();

    const handleListItemClick = (
        filteredAudio: AudioContent,
        isAutoPlay: string,
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredAudio.isReceived
                    ? EventCategories.GIFT_AUDIOS
                    : EventCategories.AUDIOS,
                nId: filteredAudio.nid,
                skuId: filteredAudio.sku_id,
                contentTitle: filteredAudio.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredAudio.isReceived
                    ? EventNames.GIFT_AUDIO_SEARCH_OPENED
                    : EventNames.AUDIO_SEARCH_AUDIO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .favorites()
                .favoriteAudiosDetail(
                    filteredAudio.nid,
                    categoryId ? categoryId : filteredAudio.content_category_id,
                    "true",
                    isAutoPlay,
                ),
        );
    };

    return { handleListItemClick };
};
