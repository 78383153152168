import { EventItem } from "JS/React/Components/EventItem";
import { Theme, InternalStandardProps } from "@mui/material";
import { Layout } from "../Layout";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import clsx from "clsx";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGetEvents, useGlobalEvents } from "JS/React/Hooks/Events";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { Tabs } from "./EventContentDetail";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface EventsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const Events = (props: EventsProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { homeStack } = useGlobalNavStack();
    const { events: eventList } = useGlobalEvents();
    useSeamlessUpdate();

    const { linkProvider } = useRouting();
    const history = useHistory();

    const {
        loading: loadingEvents,
        isErrorNoEvents,
        isErrorNoRegistered,
    } = useGetEvents(false);

    return (
        <Layout>
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title={"My Event Registrations"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {loadingEvents && !isErrorNoEvents && !isErrorNoRegistered && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.tickets}
                    />
                )}
                {isErrorNoEvents && (
                    <AppTypography style={{ color: "#0D456D" }}>
                        There is no active event at this time.
                    </AppTypography>
                )}
                {isErrorNoRegistered && (
                    <AppTypography style={{ color: "#0D456D" }}>
                        You have not registered for any events yet.
                    </AppTypography>
                )}
                {!loadingEvents &&
                    eventList.map((event) => (
                        <EventItem
                            key={`${event.EventName}-${event.EventStartDateAndTime}`}
                            event={event}
                            onEventItemClick={(e, eventName) => {
                                history.push(
                                    linkProvider.react
                                        .events()
                                        .detail(eventName, Tabs.PRIMARY_USER),
                                );
                            }}
                        />
                    ))}
            </div>
        </Layout>
    );
};
