import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles } from "@mui/styles";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";

import { AppHeader } from "JS/React/Components/AppHeader";
import {
    FirebaseEventAction,
    EventCategories,
    Members,
    VideoContent,
    AllTabs,
} from "JS/Models";
import { AppButton } from "JS/React/Components/AppButton";
import { ContentPurchase } from "JS/React/Components/ContentPurchase/ContentPurchase";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import {
    useGlobalCourses,
    useCourseDetailWithOutMedia,
} from "JS/React/Hooks/Course/Course";
import {
    useAvailableCredits,
    usePurchaseByCard,
    useAddContentToCart,
    useRedeemGiftByCredits,
} from "JS/React/Hooks/Purchase";
import { useGlobalGifts, useSendGifts } from "JS/React/Hooks/Gifts";
import { useSearchedLosMembers, useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { Icons, Icon } from "JS/React/Components/Icon";
import { SelectMembersDialog } from "JS/React/Components/ContentPurchase/SelectMembersDialog";
import { AlertDialog } from "JS/React/Components/AlertDialog";
import { GiftQuantity } from "JS/React/Components/ContentPurchase/GiftQuantity";
import {
    getGiftCategory,
    getPublicUrl,
    isRedeemable,
    isSufficientCreditAvailable,
    sortStepsInDetail,
} from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { StepsListItem } from "../Courses/StepsListItem";
import { Layout } from "../Layout";
import { GiftCategories } from ".";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        buybutton: {
            textTransform: "capitalize",
            color: theme.palette.grey[800],
        },
        warpperBtnSend: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        sendIcon: {
            cursor: "pointer",
            color: theme.palette.grey[600],
            fontSize: "22px",
            marginRight: theme.spacing(2),
        },
        membersWrapper: {
            background: theme.palette.grey[300],
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        sendToMemberBtn: {
            height: "35px",
            paddingLeft: "0",
            paddingRight: "0",
        },
        quantityBubble: {
            width: "30px",
            height: "30px",
            borderRadius: "100px",
            backgroundColor: theme.palette.grey[900],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.colors.white,
        },
        dialogContent: {
            marginTop: "20px",
        },
        dialogTypography: {
            textAlign: "center",
            color: theme.palette.grey[600],
            marginBottom: "10px",
        },
        infoIcon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
    }),
);

export interface PurchaseGiftDetailProps
    extends InternalStandardProps<GridProps> {}

export const PurchaseGiftDetail = (props: PurchaseGiftDetailProps) => {
    const classes = useStyles(props);
    const { className } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();
    const params: any = useParams();
    const { contentId, categoryId, selectedTab } = params;
    const { enqueueSnackbar } = useSnackbar();
    const { homeStack } = useGlobalNavStack();
    const [thumbnail, setThumbnail] = useState<string>(null);
    const [gifts, setGifts] = useState([]);

    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);
    const [giftQuantity, setGiftQuantity] = useState<number>(1);
    const [giftsLoaded, setGiftsLoaded] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] =
        useState<boolean>(false);
    const [stepsThumbnails, setStepsThumbnails] = useState<{
        [key: string]: string;
    }>(null);
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const handleSuccessDialogClosed = () => {
        setIsSuccessDialogOpen(false);
        setSelectedMembers([]);
    };

    const { searchLosResults, searchQueryLos, setSearchQueryLos } =
        useSearchedLosMembers();

    const { sendGiftToUser, loading: sendingGifts } = useSendGifts();

    const onToggleItemSelect = (value) => {
        if (
            selectedMembers.findIndex(
                (x) => x.member_id === value.member_id,
            ) !== -1
        ) {
            let tempArray = [...selectedMembers];
            tempArray.splice(
                tempArray.findIndex((x) => x.member_id === value.member_id),
                1,
            );
            setSelectedMembers(tempArray);
        } else {
            if (selectedMembers.length < gift?.quantity) {
                setSelectedMembers([...selectedMembers, value]);
            } else {
                enqueueSnackbar(messaging.gifts.inSufficientGiftQuantity, {
                    variant: "warning",
                });
            }
        }
    };

    const onCloseSelectMembers = () => {
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            selectMembers: false,
        });
    };

    const updateSentGiftsQuantity = () => {
        if (sentAudioGifts?.find((sag) => sag.sku_id === gift?.sku_id)) {
            const updatedSentAudios = sentAudioGifts?.map((sag) => {
                if (sag.sku_id === gift?.sku_id)
                    return {
                        ...sag,
                        quantity: +sag.quantity - selectedMembers?.length,
                    };
                return { ...sag };
            });
            setGlobalGifts({ sentAudioGiftsList: updatedSentAudios });
        } else if (sentVideoGifts?.find((svg) => svg.sku_id === gift?.sku_id)) {
            const updatedSentVideos = sentVideoGifts?.map((svg) => {
                if (svg.sku_id === gift?.sku_id)
                    return {
                        ...svg,
                        quantity: +svg.quantity - selectedMembers?.length,
                    };
                return { ...svg };
            });
            setGlobalGifts({ sentVideoGiftsList: updatedSentVideos });
        } else if (
            purchasedGiftCourses?.find((pgc) => pgc.sku_id === gift?.sku_id)
        ) {
            const updatedPurchasedGifts = purchasedGiftCourses?.map((pgc) => {
                if (pgc.sku_id === gift?.sku_id) {
                    const quantity: number =
                        +pgc.quantity - selectedMembers?.length;
                    return { ...pgc, quantity };
                } else {
                    return { ...pgc };
                }
            });
            setGlobalPurchasedCourses({
                purchasedGiftCourses: updatedPurchasedGifts,
            });
        }
    };

    const onSendSelectedMembers = async () => {
        if (selectedMembers.length >= 1) {
            sendGiftToUser(
                [
                    {
                        sku: gift?.sku_id,
                        nid: gift?.nid,
                        title: gift?.title,
                        category: getGiftCategory(selectedTab),
                    },
                ],
                selectedMembers,
            ).then((val) => {
                if (val?.response?.status === true) {
                    updateSentGiftsQuantity();
                    onCloseSelectMembers();
                    setIsSuccessDialogOpen(true);
                }
            });
        } else {
            enqueueSnackbar("Please select at-least one LOS Member.", {
                variant: "warning",
            });
        }
    };

    const {
        setGlobalGifts,
        audioGifts,
        videoGifts,
        sentAudioGifts,
        sentVideoGifts,
    } = useGlobalGifts();

    const {
        purchasableCoursesForMembers,
        setGlobalPurchasableCourses,
        purchasedGiftCourses,
        setGlobalPurchasedCourses,
    } = useGlobalCourses();

    useEffect(() => {
        if (categoryId !== GiftCategories.SEND_MEMBER) {
            let updatedGifts = [];
            if (audioGifts) updatedGifts = [...updatedGifts, ...audioGifts];
            if (videoGifts) updatedGifts = [...updatedGifts, ...videoGifts];
            if (purchasableCoursesForMembers)
                updatedGifts = [
                    ...updatedGifts,
                    ...purchasableCoursesForMembers,
                ];
            if (sentAudioGifts)
                updatedGifts = [...updatedGifts, ...sentAudioGifts];
            if (sentVideoGifts)
                updatedGifts = [...updatedGifts, ...sentVideoGifts];
            setGifts(updatedGifts);
            setGiftsLoaded(true);
        } else {
            let updatedGifts = [];
            if (sentAudioGifts)
                updatedGifts = [...updatedGifts, ...sentAudioGifts];
            if (sentVideoGifts)
                updatedGifts = [...updatedGifts, ...sentVideoGifts];
            if (purchasableCoursesForMembers && purchasedGiftCourses) {
                const quantityInjectedCourses =
                    purchasableCoursesForMembers?.map((pcm) => {
                        const matchingSkuGift = purchasedGiftCourses?.find(
                            (pgc) => pgc.sku_id === pcm.sku_id,
                        );
                        if (matchingSkuGift)
                            return {
                                ...pcm,
                                quantity: matchingSkuGift.quantity,
                            };
                        return { ...pcm };
                    });
                updatedGifts = [...updatedGifts, ...quantityInjectedCourses];
            }
            setGifts(updatedGifts);
            setGiftsLoaded(true);
        }
    }, [
        audioGifts,
        videoGifts,
        sentAudioGifts,
        sentVideoGifts,
        purchasableCoursesForMembers,
        categoryId,
        purchasedGiftCourses,
    ]);

    const gift = useMemo(() => {
        return gifts?.find((gift) => gift.nid === contentId);
    }, [gifts, contentId]);

    useEffect(() => {
        if (!gift && giftsLoaded) {
            goBack();
        }
    }, [gift, giftsLoaded]);

    const loading = false;

    const { availableCredits, getAvailableAudioCredits } =
        useAvailableCredits();
    const { redeemGiftByCredits, loading: redeemingContent } =
        useRedeemGiftByCredits();

    const { addToCart, cart } = useAddContentToCart();
    const { purchaseByCard, loading: purchasingContent } = usePurchaseByCard();
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const [purchaseEnabled, setPurchaseEnabled] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        gift?.is_redeemable_by_tokens === "1"
            ? PaymentMethod.MY_CREDITS
            : PaymentMethod.CARD,
    );

    useEffect(() => {
        setPaymentMethod(
            isRedeemable(gift, availableCredits)
                ? PaymentMethod.MY_CREDITS
                : PaymentMethod.CARD,
        );
    }, [
        gift?.is_redeemable_by_tokens,
        availableCredits,
        gift?.number_of_tokens,
    ]);

    const [dialogs, setDialogs] = useState({
        transactionComplete: false,
        transactionConfirmation: false,
        selectMembers: false,
    });

    const activeGiftItem = {
        title: gift?.title,
        giftPurchasedEnabled: true,
        quantity: giftQuantity,
    };

    const purchaseText =
        paymentMethod === PaymentMethod.MY_CREDITS
            ? `Credits Required: ${
                  activeGiftItem?.quantity === 0
                      ? gift?.number_of_tokens
                      : +gift?.number_of_tokens * activeGiftItem?.quantity
              }`
            : `Total: $ ${
                  activeGiftItem?.quantity === 0
                      ? gift?.content_price ?? 0
                      : (
                            +gift?.content_price * activeGiftItem?.quantity
                        ).toFixed(2)
              }`;

    const goBack = () => {
        if (!homeStack?.isHomeTrack) {
            history.push(
                linkProvider.react.gifts().listing(categoryId, selectedTab),
            );
        } else {
            history.goBack();
        }
    };

    const onBuyContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            const { nid, sku_id, title } = gift;
            const action: Partial<FirebaseEventAction> = {
                nId: nid,
                contentTitle: title,
                skuId: sku_id,
            };
            if (selectedTab === AllTabs.AUDIOS)
                action.category = EventCategories.GIFT_AUDIOS;
            else if (selectedTab === AllTabs.VIDEOS)
                action.category = EventCategories.GIFT_VIDEOS;
            else if (selectedTab === AllTabs.COURSES)
                action.category = EventCategories.GIFT_COURSE;
            redeemGiftByCredits(gift?.sku_id, giftQuantity, action).then(
                (val) => {
                    if (val?.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                        });
                        let toMapGifts = [];
                        const contentItemTypeName =
                            gift?.content_item_type_name;
                        let type;
                        if (contentItemTypeName?.includes("MP3")) {
                            toMapGifts = audioGifts;
                            type = "audio";
                        }
                        if (contentItemTypeName?.includes("Video")) {
                            toMapGifts = videoGifts;
                            type = "video";
                        }
                        if (contentItemTypeName?.includes("Course")) {
                            toMapGifts = purchasableCoursesForMembers;
                            type = "course";
                        }
                        const toSaveGifts: VideoContent[] = toMapGifts.map(
                            (d) => {
                                if (d.nid === contentId) {
                                    return {
                                        ...d,
                                        is_purchased: true,
                                    };
                                } else {
                                    return d;
                                }
                            },
                        );
                        getAvailableAudioCredits();
                        let giftsData: any = {};
                        if (type === "audio")
                            giftsData.audioGiftsList = toSaveGifts;
                        else if (type === "video")
                            giftsData.videoGiftsList = toSaveGifts;
                        if (type !== "course") setGlobalGifts(giftsData);
                        else setGlobalPurchasableCourses(toSaveGifts);
                        setPurchaseEnabled(false);
                    }
                },
            );
        }

        if (paymentMethod === PaymentMethod.CARD) {
            const { nid, sku_id, title } = gift;
            const action: Partial<FirebaseEventAction> = {
                nId: nid,
                contentTitle: title,
                skuId: sku_id,
            };
            if (selectedTab === AllTabs.AUDIOS)
                action.category = EventCategories.GIFT_AUDIOS;
            else if (selectedTab === AllTabs.VIDEOS)
                action.category = EventCategories.GIFT_VIDEOS;
            else if (selectedTab === AllTabs.COURSES)
                action.category = EventCategories.GIFT_COURSE;
            purchaseByCard(cart.cartId, giftQuantity, gift.sku_id, action).then(
                (val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                        });
                        let toMapGifts = [];
                        const contentItemTypeName =
                            gift?.content_item_type_name;
                        let type;
                        if (contentItemTypeName?.includes("MP3")) {
                            toMapGifts = audioGifts;
                            type = "audio";
                        }
                        if (contentItemTypeName?.includes("Video")) {
                            toMapGifts = videoGifts;
                            type = "video";
                        }
                        if (contentItemTypeName?.includes("Course")) {
                            toMapGifts = purchasableCoursesForMembers;
                            type = "course";
                        }
                        const toSaveGifts: VideoContent[] = toMapGifts.map(
                            (d) => {
                                if (d.nid === contentId) {
                                    return {
                                        ...d,
                                        is_purchased: true,
                                    };
                                } else {
                                    return d;
                                }
                            },
                        );
                        getAvailableAudioCredits();
                        let giftsData: any = {};
                        if (type === "audio")
                            giftsData.audioGiftsList = toSaveGifts;
                        else if (type === "video")
                            giftsData.videoGiftsList = toSaveGifts;
                        if (type !== "course") setGlobalGifts(giftsData);
                        else setGlobalPurchasableCourses(toSaveGifts);
                        setPurchaseEnabled(false);
                    }
                },
            );
        }
    };

    const onBuyConfirm = async () => {
        if (paymentMethod === PaymentMethod.CARD) {
            addToCart(gift.sku_id, giftQuantity).then((res) => {
                if (res?.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: true,
                    });
                }
            });
        } else {
            setDialogs({
                ...dialogs,
                transactionConfirmation: true,
            });
        }
    };

    const handleSelectMembers = () => {
        setDialogs({
            ...dialogs,
            selectMembers: true,
        });
    };

    const onQuantityChange = (event, action) => {
        if (action === "INCREMENT") {
            setGiftQuantity((qt) => +qt + 1);
        } else {
            if (giftQuantity > 1) setGiftQuantity((qt) => +qt - 1);
        }
    };

    useEffect(() => {
        if (gift) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [gift]);

    const fetchThumbnails = async () => {
        if (!thumbnail) {
            const thumb = await getPublicUrl(
                gift.image_url_prefix,
                gift.image_url_postfix,
                gift.image_file_name,
            );

            setThumbnail(thumb);
        }
    };

    const { isInsufficeintCredits, isRedeemDisabled } =
        isSufficientCreditAvailable(
            paymentMethod,
            availableCredits,
            activeGiftItem.quantity,
            +gift?.number_of_tokens,
        );

    const isCourseGift = useMemo(
        () => gift?.content_item_type_name?.includes("Course"),
        [gift?.content_item_type_name],
    );

    useEffect(() => {
        setShowDescription(!isCourseGift);
    }, [isCourseGift]);

    const { detail: courseDetail, loading: detailLoading } =
        useCourseDetailWithOutMedia(isCourseGift ? gift?.sku_id : null);

    const sortedSteps = useMemo(() => {
        return courseDetail ? sortStepsInDetail(courseDetail.steps) : [];
    }, [courseDetail]);

    useEffect(() => {
        if (courseDetail) {
            verifyAwsKeys().then(() => {
                fetchStepsThumbnails();
            });
        }
    }, [courseDetail]);

    const fetchStepsThumbnails = () => {
        courseDetail.steps.forEach(async (s) => {
            if (
                !stepsThumbnails ||
                (stepsThumbnails && !stepsThumbnails[s.nid])
            ) {
                const thumb = await getPublicUrl(
                    s.media_data[0].image.image_url_prefix,
                    s.media_data[0].image.image_url_postfix,
                    s.media_data[0].image.image_file_name,
                );

                setStepsThumbnails((prev) => ({
                    ...prev,
                    [s.nid]: thumb,
                }));
            }
        });
    };

    return (
        <Layout
            onBuyClick={onBuyConfirm}
            method={paymentMethod}
            isPurchasing={purchaseEnabled && !dialogs.transactionComplete}
            purchaseNavText={purchaseText}
            isBuyDisabled={activeGiftItem.quantity < 1 || isRedeemDisabled}
            availableCredits={availableCredits}
        >
            <AppHeader
                title={gift?.title}
                canGoBack
                searchable={false}
                onBackClick={goBack}
            />
            <Grid container className={clsx(classes.root, className)}>
                {gift && (
                    <>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                className={classes.greyA100}
                                fontWeight={"bold"}
                                variant="h6"
                            >
                                {gift.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            className={classes.grey500}
                            xs={12}
                            display="flex"
                        >
                            <>
                                <>
                                    {gift.is_redeemable_by_tokens === "1" && (
                                        <Typography
                                            className={classes.constMargin}
                                            fontWeight={"bold"}
                                            variant="body1"
                                        >
                                            {`${gift.number_of_tokens} Credit${
                                                +gift?.number_of_tokens > 1
                                                    ? "s"
                                                    : ""
                                            }`}
                                        </Typography>
                                    )}
                                    {gift.is_redeemable_by_tokens === "1" &&
                                        +gift.content_price > 0 && (
                                            <Typography variant="body1">
                                                |
                                            </Typography>
                                        )}
                                    {+gift.content_price > 0 && (
                                        <Typography
                                            className={classes.constMargin}
                                            fontWeight={"bold"}
                                            pl={1}
                                            variant="body1"
                                        >
                                            {`$${parseFloat(
                                                gift.content_price,
                                            ).toFixed(2)}`}
                                        </Typography>
                                    )}
                                </>
                            </>
                            <Typography variant="body1">
                                {gift.sku_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.playerContainer}>
                            {thumbnail ? (
                                <img
                                    alt={gift?.title}
                                    src={thumbnail}
                                    className={classes.mediaImage}
                                />
                            ) : (
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                    className={classes.dummyImageBox}
                                >
                                    <span className={classes.graphicWrapper}>
                                        <GraphicEqIcon
                                            className={classes.graphicIcon}
                                            sx={{
                                                fontSize: "60px",
                                            }}
                                        />
                                    </span>
                                </Box>
                            )}
                        </Grid>
                        {!purchaseEnabled && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    {categoryId !==
                                        GiftCategories.SEND_MEMBER && (
                                        <Button
                                            onClick={() =>
                                                setPurchaseEnabled(true)
                                            }
                                            className={clsx(classes.buybutton)}
                                            startIcon={
                                                <Icon icon={Icons.buyIcon} />
                                            }
                                        >
                                            Buy
                                        </Button>
                                    )}
                                    <RenderIf isTrue={isCourseGift}>
                                        <IconButton
                                            onClick={() =>
                                                setShowDescription(
                                                    (oldState) => !oldState,
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                className={classes.infoIcon}
                                                icon={["fas", "circle-info"]}
                                            />
                                        </IconButton>
                                    </RenderIf>

                                    {isSuccessDialogOpen && (
                                        <AlertDialog
                                            fixed
                                            onClose={handleSuccessDialogClosed}
                                        >
                                            <Typography
                                                className={clsx(
                                                    classes.dialogTypography,
                                                )}
                                            >
                                                Send Complete
                                            </Typography>
                                            <Typography
                                                className={clsx(
                                                    classes.dialogTypography,
                                                )}
                                            >
                                                {selectedMembers?.length || ""}{" "}
                                                {selectedTab} file
                                                {selectedMembers?.length > 1
                                                    ? "s were"
                                                    : " was"}{" "}
                                                sent
                                            </Typography>
                                        </AlertDialog>
                                    )}

                                    {categoryId ===
                                        GiftCategories.SEND_MEMBER && (
                                        <>
                                            <Grid
                                                container
                                                className={
                                                    classes.warpperBtnSend
                                                }
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    className={
                                                        classes.warpperBtnSend
                                                    }
                                                >
                                                    <AppButton
                                                        onClick={
                                                            handleSelectMembers
                                                        }
                                                        className={
                                                            classes.sendToMemberBtn
                                                        }
                                                        rounded
                                                        buttonVariant="grey-text"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={[
                                                                "far",
                                                                "paper-plane",
                                                            ]}
                                                            className={
                                                                classes.sendIcon
                                                            }
                                                        />
                                                        Send to Members
                                                    </AppButton>
                                                </Grid>
                                            </Grid>
                                            <Box
                                                className={clsx(
                                                    classes.quantityBubble,
                                                )}
                                            >
                                                {gift.quantity > 99
                                                    ? "99+"
                                                    : gift.quantity}
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                                {gift.release_date && (
                                    <Grid item xs={12}>
                                        <Typography
                                            className={clsx(
                                                classes.grey500,
                                                classes.date,
                                            )}
                                            variant="body2"
                                        >
                                            {moment(
                                                +gift.release_date * 1000,
                                            ).format("LL")}
                                        </Typography>
                                    </Grid>
                                )}
                                <RenderIf isTrue={showDescription}>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.grey800}
                                            variant="body2"
                                        >
                                            {gift.description}
                                        </Typography>
                                    </Grid>
                                </RenderIf>
                            </>
                        )}

                        {purchaseEnabled && (
                            <ContentPurchase
                                marginBottom={"30px"}
                                onBackClick={() => {
                                    setPurchaseEnabled(false);
                                }}
                                buying={purchasingContent || redeemingContent}
                                cart={cart}
                                backTitle="Gift Purchase"
                                credits={availableCredits}
                                requiredCredits={gift?.number_of_tokens}
                                method={paymentMethod}
                                totalPrice={`$${parseFloat(
                                    gift.content_price,
                                ).toFixed(2)}`}
                                contentPrice={gift?.content_price}
                                onMethodChange={(method) => {
                                    if (
                                        method === PaymentMethod.MY_CREDITS &&
                                        gift.is_redeemable_by_tokens === "1"
                                    ) {
                                        setPaymentMethod(method);
                                    } else {
                                        if (
                                            method === PaymentMethod.MY_CREDITS
                                        ) {
                                            enqueueSnackbar(
                                                messaging.redeeming
                                                    .notRedeemable,
                                                { variant: "warning" },
                                            );
                                        }
                                    }

                                    if (method === PaymentMethod.CARD) {
                                        setPaymentMethod(method);
                                    }
                                }}
                                dialogs={dialogs}
                                setDialogs={setDialogs}
                                onBuy={onBuyContent}
                                isRedemeableByToken={
                                    gift.is_redeemable_by_tokens
                                }
                            />
                        )}
                    </>
                )}

                <RenderIf
                    isTrue={
                        !detailLoading && !purchaseEnabled && !showDescription
                    }
                >
                    {sortedSteps?.map((d, idx) => (
                        <Grid item xs={12} key={idx}>
                            <StepsListItem
                                isDisabled={true}
                                isQuiz={d.content_item_type_name === "quiz"}
                                stepThumbnail={
                                    stepsThumbnails && stepsThumbnails[d.nid]
                                }
                                step={d}
                                isLocked={true}
                            />
                        </Grid>
                    ))}
                </RenderIf>

                <RenderIf
                    isTrue={
                        (!gift && loading) || (isCourseGift && detailLoading)
                    }
                >
                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                        <CircularProgress className={classes.spinner} />
                    </Grid>
                </RenderIf>
            </Grid>
            {dialogs?.selectMembers && (
                <SelectMembersDialog
                    open={true}
                    availableQuantity={
                        gift?.quantity - selectedMembers?.length > 0
                            ? gift?.quantity - selectedMembers?.length
                            : 0
                    }
                    searchable={true}
                    searchPlaceholder="Search by name or LTD ID"
                    searchQuery={searchQueryLos}
                    setSearchQuery={setSearchQueryLos}
                    selectedMembers={selectedMembers}
                    checkboxList={searchLosResults}
                    onToggleItemSelect={onToggleItemSelect}
                    onSend={onSendSelectedMembers}
                    onClose={onCloseSelectMembers}
                    giftSendLoading={sendingGifts}
                />
            )}
            {purchaseEnabled && !dialogs?.selectMembers && (
                <GiftQuantity
                    activeGiftItem={activeGiftItem}
                    onQuantityChange={onQuantityChange}
                    method={paymentMethod}
                    credits={availableCredits}
                    isInsufficeintCredits={isInsufficeintCredits}
                />
            )}
        </Layout>
    );
};
