import { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { RecommendationService } from "JS/Services/Recommendation";
import { AppResponse } from "JS/Types";
import { AudioContent, RecommendationBatch, Recommendations } from "JS/Models";
import {
    RecommendationsState,
    setGlobalRecommendations,
} from "JS/Redux/Recommendations";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useRouting } from "./Routes";
import { AudioCategoryTitle } from "../Scenes/Audios";
import {
    BlackRecommendationsState,
    setGlobalBlackRecommendations,
} from "JS/Redux/BlackRecommendations";
import { useVerifyAwsKeys } from "./Media";
import { checkIfBatchAllowsNotify, checkIfBatchIncludesIbos } from "JS/Helpers";
import { uniqBy } from "lodash-es";
import { captureSentryError } from "JS/Helpers/SentryHelper";

export const rService = new RecommendationService();

export const useRecommendations = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<Recommendations[]>>(null);

    const { setGlobalRecommendations, recommendationState } =
        useGlobalRecommendations();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return rService
            .getRecommendedAudios()
            .then(async (res) => {
                const response = res.response;
                setResponse(res);
                if (!response?.status) {
                    enqueueSnackbar(response?.message, {
                        variant: "error",
                    });
                } else {
                    const apiRecommendations = response ? response.data : [];
                    setGlobalRecommendations({
                        recommendations: apiRecommendations,
                    });

                    return {
                        recommendations: apiRecommendations,
                    };
                }
                return {
                    recommendations: [],
                };
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    recommendations: [],
                };
            })
            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        recommendations: recommendationState?.recommendations,
        loading,
        loaded,
    };
};

export const useRecommendationsBatch = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const { recommendationState, setGlobalRecommendations } =
        useGlobalRecommendations();
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const refetch = useCallback(async () => {
        setLoading(true);
        await verifyAwsKeys();
        return rService
            .getS3AudioRecommendationBatch()
            .then((batch) => {
                setGlobalRecommendations({
                    batch: batch,
                    lastFetched: moment().unix(),
                });
                return batch;
            })
            .catch((err) => {
                if (
                    err.code &&
                    typeof err.code === "string" &&
                    err.code !== "NetworkingError" &&
                    !err.code.includes("permission")
                )
                    captureSentryError(err, {
                        location: "recommendations-batch",
                    });
                return {
                    batchId: null,
                    ibos: [],
                } as RecommendationBatch;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        batch: recommendationState.batch,
        loading,
        refetch,
    };
};

export const useFilteredRecommendations = (
    recommendations: Recommendations[] = [],
    audios: AudioContent[] = [],
) => {
    const filteredRecommendations = useMemo(() => {
        const recommendedNids = recommendations?.map((d) => `${d.nid}`);
        const audiosNids = audios?.map((d) => d.nid);
        return {
            filteredRecommendedAudios: uniqBy(
                audios?.filter((a) => recommendedNids?.includes(`${a.nid}`)),
                (x) => x.nid,
            ),
            filteredRecommendations: uniqBy(
                recommendations?.filter((r) =>
                    audiosNids?.includes(`${r.nid}`),
                ),
                (x) => x.nid,
            ),
        };
    }, [recommendations, audios]);

    return filteredRecommendations;
};

export const useGlobalRecommendations = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.recommendations);

    const setRecommendations = (state: Partial<RecommendationsState>) => {
        dispatch(setGlobalRecommendations(state));
    };

    return {
        recommendationState: allData,
        setGlobalRecommendations: setRecommendations,
    };
};

export const useGlobalBlackRecommendations = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.blackRecommendations);
    const setBlackRecommendations = (
        state: Partial<BlackRecommendationsState>,
    ) => {
        dispatch(setGlobalBlackRecommendations(state));
    };

    return {
        blackRecommendationState: allData,
        setGlobalBlackRecommendations: setBlackRecommendations,
    };
};

export const useOpenAutoPopup = () => {
    const {
        recommendationState: { noOfPopups, recommendations, batch },
    } = useGlobalRecommendations();

    const { showRedirectionDialog, loadingConsent } = useAppSelector(
        (state) => state.consentForm,
    );

    const history = useHistory();
    const { linkProvider } = useRouting();
    const params: any = useParams();
    const { categoryId } = params;

    const onlyOnce =
        recommendations?.length === 0 ? noOfPopups < 1 : noOfPopups < 3;

    const includeIbos = batch && checkIfBatchIncludesIbos(batch);
    const allowsNotify = batch && checkIfBatchAllowsNotify(batch);
    const { enqueueSnackbar } = useSnackbar();

    const allowOpen =
        categoryId !== AudioCategoryTitle.RECOMMENDED &&
        onlyOnce &&
        includeIbos &&
        allowsNotify;

    const openPopup = (must: boolean = false) => {
        if (!showRedirectionDialog && !loadingConsent && (allowOpen || must)) {
            history.push(linkProvider.react.recommendations().index(true));
        }
    };

    return {
        openPopup,
    };
};
