import { IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import FallbackWaveImage from "Images/Content/audio-waves.png";
import "./style.css";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useMediaPlayer } from "JS/React/Hooks/MediaPlayer";
import { checkIfUrlIsBlob, getS3CompletePathFromPreSigned } from "JS/Helpers";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import { MainPlayerOverlay } from "./MainPlayerOverlay";
import { RefreshButton } from "./RefreshButton";

export const MediaPlayer = (props: MediaPlayerProps) => {
    const imageURL = useMemo(() => {
        if (!props.imageURL || props.imageURL === "a") {
            return FallbackWaveImage;
        }
        return props.imageURL;
    }, [props.imageURL]);

    const classes = useStyles({ ...props, imageURL })();
    const {
        isAudio,
        playerRef,
        playing: parentPlaying = true,
        title,
        isMini,
        mediaURL,
        onNext,
        onError,
        onPrevious,
        loading,
        onUpdateExpiredUrl: onPlayerError,
        onReady,
        ...rest
    } = props;

    const { miniPlayer } = useMiniPlayer();

    const {
        isError,
        contentUrl,
        maintainPlaying,
        handleMediaError,
        handlePlaybackRate,
        handleReady,
    } = useHandlers({ ...props, imageURL });

    const playerHeight = isAudio ? "54px" : "100%";

    const [initialPlaying] = useState(parentPlaying);
    const [playing, setPlaying] = useState(parentPlaying);

    useEffect(() => {
        setPlaying(parentPlaying);
    }, [parentPlaying]);

    useEffect(() => {
        if (!isMini && !miniPlayer.url && isAudio) {
            setPlaying(initialPlaying);
        }
    }, [miniPlayer.url]);

    const playingState = useMemo(() => {
        const toRet = playing || maintainPlaying || miniPlayer?.isPlaying;
        return toRet;
    }, [miniPlayer?.isPlaying, playing, maintainPlaying]);

    const thumbnail = useMemo(() => {
        const condition =
            isAudio ||
            isMini ||
            miniPlayer.url ||
            miniPlayer?.isPlaying === true;
        if (condition) return false;

        return imageURL;
    }, [isMini, isAudio, playing, maintainPlaying, miniPlayer]);

    return (
        <div
            className={clsx(
                classes.root,
                isAudio && classes.playerBackgroundImg,
                !isAudio &&
                    !isMini &&
                    miniPlayer.url &&
                    classes.playerBackgroundImg,
            )}
            onContextMenu={(e) => e.preventDefault()}
        >
            {loading && isAudio && !isMini && !miniPlayer.url && (
                <MainPlayerOverlay />
            )}

            {!isMini && isError && <RefreshButton />}

            {!isMini && miniPlayer.url ? (
                <></>
            ) : (
                <ReactPlayer
                    playbackRate={miniPlayer.playbackRate}
                    playsinline={true}
                    ref={playerRef}
                    width="100%"
                    height={playerHeight}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    url={contentUrl}
                    light={thumbnail}
                    playIcon={
                        <IconButton aria-label="Play">
                            <FontAwesomeIcon
                                className={classes.playIcon}
                                icon={["fas", "play"]}
                            />
                        </IconButton>
                    }
                    playing={playingState}
                    controls={
                        !isMini ? !isMini && !miniPlayer.url && !loading : false
                    }
                    onError={handleMediaError}
                    onPlaybackRateChange={handlePlaybackRate}
                    onReady={handleReady}
                    config={{
                        file: {
                            forceAudio: isAudio,
                            attributes: {
                                controlsList: "nodownload",
                                disablePictureInPicture: true,
                            },
                        },
                    }}
                    {...rest}
                />
            )}
        </div>
    );
};

const useHandlers = (props: MediaPlayerProps) => {
    const {
        mediaURL,
        isMini,
        onError = () => {},
        onUpdateExpiredUrl: onPlayerError,
        onPlaybackRateChange = () => {},
        onReady = () => {},
    } = props;
    const { maintainPlaying, onMediaPlayerError } = useMediaPlayer(isMini);
    const { setGlobalMiniPlayer } = useMiniPlayer();

    useMediaSession(props);

    const [contentUrl, setContentUrl] = useState(mediaURL);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        setContentUrl(mediaURL);
    }, [mediaURL]);

    const onErr = (e: any) => {
        setIsError(true);
        onError(e);
    };

    const handleMediaError = (e) => {
        const isBlob = checkIfUrlIsBlob(contentUrl);
        onMediaPlayerError(
            e,
            !isBlob ? getS3CompletePathFromPreSigned(contentUrl) : null,
            setContentUrl,
            onPlayerError,
            onErr,
        );
    };

    const handlePlaybackRate = (rate: number) => {
        setGlobalMiniPlayer({
            playbackRate: rate,
        });
        onPlaybackRateChange(rate);
    };

    const handleReady = (player: ReactPlayer) => {
        setIsError(false);
        onReady(player);
    };

    return {
        maintainPlaying,
        contentUrl,
        isError,
        handleMediaError,
        handlePlaybackRate,
        handleReady,
    };
};

const useMediaSession = (props: MediaPlayerProps) => {
    const { title, imageURL } = props;
    const { miniPlayer } = useMiniPlayer();

    useEffect(() => {
        if ("mediaSession" in navigator) {
            const artwork = [];
            if (miniPlayer.url && miniPlayer.thumbnail) {
                artwork.push({
                    src: miniPlayer.thumbnail,
                    size: "719x1080",
                    type: "image/jpeg",
                });
            } else if (imageURL) {
                artwork.push({
                    src: imageURL,
                    size: "719x1080",
                    type: "image/jpeg",
                });
            }
            navigator.mediaSession.metadata = new MediaMetadata({
                title: miniPlayer.title ? miniPlayer.title : title,
                artwork,
            });
        }
    }, [title, miniPlayer]);
};

const useStyles = (props) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                position: "relative",
            },
            playerBackgroundImg: {
                backgroundImage: `url(${props.imageURL})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                backgroundColor: `${theme.palette.background.default}`,
            },
            playIcon: {
                fontSize: "40px",
                color: `${theme.colors.white}`,
            },
        }),
    );

export interface MediaPlayerProps extends ReactPlayerProps {
    mediaURL: string;
    imageURL: string;
    isAudio?: boolean;
    playerRef?: any;
    title: string;
    isMini?: boolean;
    loading?: boolean;
    onNext?: () => void;
    onPrevious?: () => void;
    onUpdateExpiredUrl?: () => void;
    onPlaybackRateChange?: (rate: number) => void;
    onReady?: (player: ReactPlayer) => void;
}
