import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { appConstants } from "JS/Helpers/Contants";
import { config } from "JS/Config";
import { v4 as uuid } from "uuid";
import { DeleteTokenData } from "JS/Models";
import { AppResponse } from "JS/Types";
import { doXHR, routes } from "JS/Services/BaseService";

export const saveToken = (token: string) => {
    localStorage.setItem(appConstants.localStorage.fcmToken, token);
};

export const getFirebaseToken = () => {
    const fcmConfig = config.firebase.fcm;
    const app = initializeApp(fcmConfig, fcmConfig.appName);
    const messaging = getMessaging(app);
    return getToken(messaging, {
        vapidKey: fcmConfig.vapidKey,
    });
};
export const logReason = (reason: any) => {
    if (!reason?.code?.includes("permission"))
        captureSentryError(reason, {
            firebaseTokenGen: "true",
        });
};
export const getWebcastUUID = (): string => {
    const WEBCAST_UUID_KEY = appConstants.localStorage.webcastUUID;
    let local = localStorage.getItem(WEBCAST_UUID_KEY);
    if (local === null)
        localStorage.setItem(WEBCAST_UUID_KEY, uuid().toLocaleUpperCase());
    return localStorage.getItem(WEBCAST_UUID_KEY);
};

export const getLocalFcmToken = () => {
    return localStorage.getItem(appConstants.localStorage.fcmToken);
};
export const getLocalRefreshedOnInitialized = () => {
    return (
        localStorage.getItem(
            appConstants.localStorage.refreshedOnInitialized,
        ) === "true"
    );
};
export const setLocalRefreshedOnInitialized = (val: boolean) => {
    return localStorage.setItem(
        appConstants.localStorage.refreshedOnInitialized,
        `${val}`,
    );
};

export async function deleteDeviceTokenApi(
    memberId: string,
    token: DeleteTokenData["token"],
) {
    const toSend: DeleteTokenData = {
        token,
    };

    const formData = new FormData();
    formData.append("token", `${toSend.token}`);

    return (
        await doXHR<AppResponse<{}>>({
            url: routes.server.api.pushNotifications.deleteDeviceToken(
                memberId,
            ),
            method: "POST",
            data: formData,
            headers: {
                authorization: `Bearer ${config.accessToken}`,
            },
        })
    ).data;
}
