import { useEffect, useMemo, useState } from "react";
import { Theme, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortBy } from "lodash";
import clsx from "clsx";
import { getPublicUrl } from "JS/Helpers";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { MixedContentItem } from "./MixedContentItem";
import { PaginationInfo, paginateData } from "JS/Types/Pagination";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { MappedListingType } from ".";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { HomeSegment } from "JS/React/Hooks/NavigationEngine";
import { Course } from "JS/Models";

export const MixedContentListing = (props: MixedContentListingProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const {
        className,
        searchQuery,
        paginationInfo,
        setPaginationInfo,
        content,
        ...rest
    } = props;

    const { finalMixedContent, thumbnails } = useMixContentSearch({
        content,
        searchQuery,
        paginationInfo,
        setPaginationInfo,
    });

    const handleRedirectToDetail = (content: MappedListingType) => {
        const mixContentParameter = {
            isMixedContent: true,
        };
        if (content.contentType === "Audio") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .audioDetail(
                        content.nid,
                        "All",
                        "false",
                        mixContentParameter,
                    ),
            );
        }
        if (content.contentType === "Video") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .videoDetail(
                        content.nid,
                        "All",
                        "true",
                        mixContentParameter,
                    ),
            );
        }
        if (content.contentType === "Course") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .courseDetail(
                        content.nid,
                        content.isReceived ? "true" : "false",
                        HomeSegment.ALL,
                        mixContentParameter,
                    ),
            );
        }
    };

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            {finalMixedContent?.map((item) => {
                return (
                    <MixedContentItem
                        key={item.nid}
                        title={item.title}
                        description={item.description}
                        contentType={item.contentType}
                        imageUrl={thumbnails && thumbnails[item.nid]}
                        onListItemClick={() => handleRedirectToDetail(item)}
                    />
                );
            })}
        </div>
    );
};

const useMixContentSearch = (props: MixContentSearchProps) => {
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);
    const { setGlobalHomeStack } = useGlobalNavStack();

    const { content, searchQuery, paginationInfo, setPaginationInfo } = props;

    const searchResults = useMemo(() => {
        return content?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery?.toLocaleLowerCase()?.trim()) ||
                d.description
                    .toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase()?.trim()),
        );
    }, [content, searchQuery]);

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalHomeStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    const sortedMixedContent = useMemo(
        () => sortBy(sortBy(searchResults, "title"), "position"),
        [searchResults],
    );
    const finalMixedContent = useMemo(() => {
        const paginatedData = paginateData(
            sortedMixedContent,
            paginationInfo,
        ).data;

        return paginatedData?.map((item) => {
            if (item.contentType === "Course") {
                return {
                    ...item,
                    ...(item as Course).image,
                };
            }
            return item;
        });
    }, [sortedMixedContent, paginationInfo]);

    const createImageUrl = async (content) => {
        const url = await getPublicUrl(
            content.image_url_prefix,
            content.image_url_postfix,
            content.image_file_name,
        );

        return {
            id: content.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalMixedContent?.forEach((item) => {
            if (!thumbnails || (thumbnails && !thumbnails[item.nid])) {
                const promise = createImageUrl(item);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    useEffect(() => {
        if (finalMixedContent) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalMixedContent]);

    return {
        finalMixedContent,
        thumbnails,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface MixedContentListingProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    searchQuery: string;
    setPaginationInfo: React.Dispatch<React.SetStateAction<PaginationInfo>>;
    paginationInfo: PaginationInfo;
    content: MappedListingType[];
}

export interface MixContentSearchProps {
    content: MappedListingType[];
    searchQuery: string;
    paginationInfo: PaginationInfo;
    setPaginationInfo: React.Dispatch<React.SetStateAction<PaginationInfo>>;
}
