import { useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";

import FallbackWaveform from "Images/Content/FallbackWaveform.png";

export interface MixedContentItemProps
    extends InternalStandardProps<GridProps> {
    title: string;
    description: string;
    contentType: string;
    imageUrl: string;
    onListItemClick: React.MouseEventHandler<HTMLDivElement>;
}

export const MixedContentItem = (props: MixedContentItemProps) => {
    const classes = useStyles(props);
    const { title, description, contentType, imageUrl, onListItemClick } =
        props;
    const [moreDescription, setMoreDescription] = useState<boolean>(false);

    return (
        <Card
            elevation={0}
            className={clsx(classes.root, classes.cardContainer)}
        >
            <Box
                className={clsx(classes.imageContainer)}
                onClick={onListItemClick}
            >
                <img
                    src={imageUrl || FallbackWaveform}
                    style={{ width: "60px" }}
                    alt="thumbnail"
                />
            </Box>
            <CardContent
                onClick={onListItemClick}
                className={clsx(classes.cardContent)}
            >
                <Typography
                    className={clsx(
                        classes.title,
                        classes.grey600,
                        classes.shortContent,
                    )}
                >
                    {title}
                </Typography>
                <Typography
                    className={clsx(
                        moreDescription
                            ? classes.clampText
                            : classes.shortContent,
                        classes.grey600,
                    )}
                    fontSize={"12px"}
                >
                    {description}
                </Typography>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className={clsx(
                            classes.primaryColor,
                            classes.moreButton,
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            setMoreDescription(!moreDescription);
                        }}
                    >
                        <Typography
                            className={clsx(
                                classes.primaryColor,
                                classes.moreText,
                            )}
                        >
                            {moreDescription ? "Less" : "More"}
                        </Typography>
                    </Button>
                </Box>
                <Typography
                    className={clsx(classes.grey600, classes.mediaType)}
                >
                    {`Content Type: ${contentType}`}
                </Typography>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default} !important`,
        },
        cardContainer: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer !important",
            marginBottom: "20px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        cardContent: {
            width: "100%",
            padding: "8px !important",
            overflow: "hidden",
        },
        shortContent: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        title: {
            fontSize: "18px",
        },
        primaryColor: {
            color: theme.palette.primary.main,
        },
        moreButton: {
            padding: "0",
            paddingLeft: "10px",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        imageContainer: {
            width: "80px",
            display: "flex",
            justifyContent: "center",
            position: "relative",
        },
        moreText: {
            textTransform: "lowercase",
            fontSize: "12px",
            fontWeight: 500,
        },
        mediaType: {
            fontSize: "17px",
        },
    }),
);
