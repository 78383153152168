import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { useHistory } from "react-router-dom";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { AudioContent } from "JS/Models";
import { AppHeader } from "JS/React/Components/AppHeader";
import { PaginationInfo } from "JS/Types/Pagination";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { AudioFavList } from "./Components/AudioFavList";
import { uniqBy } from "lodash-es";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { messaging } from "JS/Helpers/UserMessaging";
import { FavoritesTypes } from ".";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

export interface CategoriesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const FavoritesAudiosListing = (props: any) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const {
        homeStack,
        favoritesAudiosStack,
        setGlobalFavoritesAudiosStack,
        resetFavoritesAudiosStack,
    } = useGlobalNavStack();
    const [searchQuery, setSearchQuery] = useState<string>(
        favoritesAudiosStack?.searchQuery || "",
    );
    const [headerTitle, setHeaderTitle] = useState("Favorite Audios");
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 25,
        total: 0,
    });

    const history = useHistory();

    const { audios, isAudioUpdateRequired } = useGlobalAudios();
    const { loading: audiosLoading } = useAudios(!!audios && audios.length > 0, isAudioUpdateRequired);

    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const { loading: receivedMemberAudioGiftsLoading } =
        useReceivedMemberAudioGifts(
            !!receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
        );

    const { loading: receivedProspectAudioGiftsLoading } =
        useReceivedProspectAudioGifts(
            !!receivedProspectAudioGifts &&
                receivedProspectAudioGifts.length > 0,
        );

    const { favoritesAudios } = useGlobalFavorites();
    const { loading: favLoading } = useGetFavorites(
        !!favoritesAudios && favoritesAudios.length > 0,
    );

    const { linkProvider } = useRouting();

    const filteredAudios = useMemo(() => {
        return uniqBy(
            [
                ...audios,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ],
            (x) => x.nid,
        );
    }, [audios, receivedMemberAudioGifts, receivedProspectAudioGifts]);

    const filterFavoritesAudios = useMemo(() => {
        return filteredAudios.filter((audio) =>
            favoritesAudios.some((favAud) => favAud.nid === audio.nid),
        );
    }, [filteredAudios, favoritesAudios]);

    const searchResults = useMemo(() => {
        return filterFavoritesAudios?.filter(
            (d) =>
                d?.title
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()) ||
                d?.description
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()),
        );
    }, [filterFavoritesAudios, searchQuery]);

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalFavoritesAudiosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    const isAudiosContentLoaded =
        !audiosLoading &&
        !receivedMemberAudioGiftsLoading &&
        !receivedProspectAudioGiftsLoading;

    useEffect(() => {
        window.scrollTo(0, 500);
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            paginationInfo={isAudiosContentLoaded && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 25,
                });
            }}
        >
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Audios"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Audios by title/description"}
                    onBackClick={() => {
                        setSearchQuery("");
                        resetFavoritesAudiosStack();
                        if (!homeStack?.isHomeTrack) {
                            history.push(
                                linkProvider.react.favorites().index(),
                            );
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {isAudiosContentLoaded && !favLoading ? (
                    <AudioFavList
                        paginationInfo={paginationInfo}
                        audios={searchResults as AudioContent[]}
                        searchQuery={searchQuery}
                        categoryId={FavoritesTypes.FAVAUDIOS}
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            favLoading
                                ? messaging.loader.favorites
                                : messaging.loader.audios
                        }
                    />
                )}
            </div>
        </Layout>
    );
};
