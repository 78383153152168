import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { Box, InternalStandardProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import debounce from "lodash-es/debounce";

import { useRouting } from "JS/React/Hooks/Routes";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { VideoDexie, AudioDexie } from "JS/Database/Dexie";
import { useGlobalVideos } from "JS/React/Hooks/Video";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { useVideoIndexFromDb } from "JS/React/Hooks/Database/Video";
import { useMemberAudiosFromDb } from "JS/React/Hooks/Database/Audio";
import { useScrollWithoutDelay } from "JS/React/Hooks/Media";
import { AudioContent, Gifts, VideoContent } from "JS/Models";
import {
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
} from "JS/React/Hooks/Gifts";
import { getUniqueValues, sortArrayByKey } from "JS/Helpers";
import { InfiniteScrollManager } from "JS/React/Container/InfiniteScrollManager";
import { PaginationInfo, paginateData } from "JS/Types/Pagination";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { messaging } from "JS/Helpers/UserMessaging";

export const DownloadsOffline = (props: DownloadsOfflineProps) => {
    const history = useHistory();
    const classes = useStyles(props);
    const { handleRedirectToDetail } = useHandlers();

    const { className } = props;
    const params: any = useParams();
    const { category: selectedTab } = params;

    const {
        globalAudios,
        memberAudios,
        receivedMemberAudioGifts,
        audiosLoading,
        isResumeableAudios,
    } = useOfflineAudios(selectedTab);
    const {
        globalVideos,
        memberVideos,
        receivedMemberVideoGifts,
        videosLoading,
        isResumeableVideos,
    } = useOfflineVideos(selectedTab);

    useScrollWithoutDelay();

    const onBackClick = () => history.goBack();

    const downloadedSkuIds: string[] = useMemo(() => {
        if (selectedTab === Tabs.AUDIOS)
            return memberAudios.map((x) => x.skuId);

        if (selectedTab === Tabs.VIDEOS)
            return memberVideos.map((x) => x.skuId);

        return [];
    }, [memberAudios, memberVideos, selectedTab]);

    const nonDownloadedContent = useMemo(() => {
        if (selectedTab === Tabs.AUDIOS) {
            const sorted = sortArrayByKey(
                [
                    ...getUniqueValues(globalAudios, "nid"),
                    ...getUniqueValues(receivedMemberAudioGifts, "nid"),
                ],
                "title",
                "ASC",
            );
            return sorted.filter((x) => !downloadedSkuIds.includes(x.sku_id));
        }
        if (selectedTab === Tabs.VIDEOS) {
            const sorted = sortArrayByKey(
                [
                    ...getUniqueValues(globalVideos, "nid"),
                    ...getUniqueValues(receivedMemberVideoGifts, "nid"),
                ],
                "title",
                "ASC",
            );
            return sorted.filter((x) => !downloadedSkuIds.includes(x.sku_id));
        }
    }, [
        globalAudios,
        globalVideos,
        downloadedSkuIds,
        selectedTab,
        receivedMemberAudioGifts,
        receivedMemberVideoGifts,
    ]);

    const allAudios = useMemo(() => {
        const sorted = sortArrayByKey(memberAudios, "name", "ASC");
        return [...sorted, ...nonDownloadedContent];
    }, [memberAudios, nonDownloadedContent]);

    const allVideos = useMemo(() => {
        const sorted = sortArrayByKey(memberVideos, "name", "ASC");
        return [...sorted, ...nonDownloadedContent];
    }, [memberVideos, nonDownloadedContent]);

    const {
        finalAudioData,
        finalVideoData,
        debSearch,
        debouncedSearch,
        paginationInfo,
        setPaginationInfo,
        setDebSearch,
    } = useContentFilters(allAudios, allVideos, selectedTab);

    const downloadedLoading = videosLoading || audiosLoading;

    const noItemsToShow = useMemo(() => {
        if (selectedTab === Tabs.VIDEOS) return finalVideoData?.length === 0;
        return finalAudioData?.length === 0;
    }, [selectedTab, finalAudioData, finalVideoData]);

    return (
        <div className={clsx(className, classes.root)}>
            <AppHeader
                title={selectedTab}
                canGoBack
                showTabs={false}
                onBackClick={onBackClick}
                searchQuery={debSearch}
                setSearchQuery={(val) => {
                    setDebSearch(val);
                    debouncedSearch(val);
                }}
                searchPlaceholder={"Search by title/description"}
            />

            {!downloadedLoading && noItemsToShow && (
                <Typography
                    align="center"
                    variant="h6"
                    className={classes.content}
                >
                    {messaging.common.noItemsFound}
                </Typography>
            )}

            {downloadedLoading && (
                <Box display={"flex"} justifyContent="center">
                    <AppCircularProgress />
                </Box>
            )}

            {!downloadedLoading && (
                <InfiniteScrollManager
                    paginationInfo={!noItemsToShow && paginationInfo}
                    onLastContentHit={() => {
                        setPaginationInfo({
                            ...paginationInfo,
                            perPage: paginationInfo.perPage + 25,
                        });
                    }}
                >
                    {selectedTab === Tabs.AUDIOS &&
                        finalAudioData?.map(
                            (audio: AudioContent | AudioDexie, idx) => {
                                let content: {
                                    title: string;
                                    skuId: string;
                                    nid: string;
                                };

                                let isDownloaded = false;
                                if ((audio as AudioDexie).skuId) {
                                    const a = audio as AudioDexie;

                                    content = {
                                        skuId: a.skuId,
                                        title: a.name,
                                        nid: a.id,
                                    };
                                    isDownloaded = true;
                                } else {
                                    const a = audio as AudioContent;
                                    content = {
                                        skuId: a.sku_id,
                                        title: a.title,
                                        nid: a.nid,
                                    };
                                }

                                return (
                                    <AppContentListItem
                                        key={idx}
                                        nid={content.nid}
                                        type="media"
                                        title={content.title}
                                        skuId={content.skuId}
                                        description={audio.description}
                                        releaseDate={audio.release_date}
                                        disabled={!isDownloaded}
                                        isResume={
                                            isDownloaded &&
                                            isResumeableAudios(
                                                audio as AudioDexie,
                                            )
                                        }
                                        imageUrl={""}
                                        showPlayIcon
                                        isAllowClick={isDownloaded}
                                        onListItemClick={() =>
                                            handleRedirectToDetail(
                                                (audio as AudioDexie).id,
                                                "audio",
                                                false,
                                            )
                                        }
                                        onItemPlayClick={() =>
                                            handleRedirectToDetail(
                                                (audio as AudioDexie).id,
                                                "audio",
                                                false,
                                            )
                                        }
                                    />
                                );
                            },
                        )}

                    {selectedTab === Tabs.VIDEOS &&
                        finalVideoData?.map((video, idx) => {
                            let content: {
                                title: string;
                                skuId: string;
                                nid: string;
                            };
                            let isDownloaded = false;
                            if ((video as VideoDexie).skuId) {
                                const a = video as VideoDexie;
                                content = {
                                    title: a.name,
                                    skuId: a.skuId,
                                    nid: a.id,
                                };
                                isDownloaded = true;
                            } else {
                                const a = video as VideoContent;
                                content = {
                                    title: a.title,
                                    skuId: a.sku_id,
                                    nid: a.nid,
                                };
                            }

                            return (
                                <AppContentListItem
                                    key={idx}
                                    nid={content.nid}
                                    type="media"
                                    title={content.title}
                                    skuId={content.skuId}
                                    description={video.description}
                                    releaseDate={video.release_date}
                                    disabled={!isDownloaded}
                                    isResume={
                                        isDownloaded &&
                                        isResumeableVideos(video as VideoDexie)
                                    }
                                    imageUrl={""}
                                    showPlayIcon
                                    isAllowClick={isDownloaded}
                                    onListItemClick={() =>
                                        handleRedirectToDetail(
                                            (video as VideoDexie).id,
                                            "video",
                                            true,
                                        )
                                    }
                                    onItemPlayClick={() =>
                                        handleRedirectToDetail(
                                            (video as VideoDexie).id,
                                            "video",
                                            true,
                                        )
                                    }
                                />
                            );
                        })}
                </InfiniteScrollManager>
            )}
        </div>
    );
};

const useOfflineAudios = (selectedTab: Tabs) => {
    const {
        resumeUserAudios,
        resumeUserBundles,
        audios: globalAudios,
    } = useGlobalAudios();
    const { memberAudios, loading: audiosLoading } = useMemberAudiosFromDb(
        selectedTab !== Tabs.AUDIOS,
    );

    const { receivedMemberAudioGifts } = useReceivedMemberAudioGifts(true);

    const isResumeableAudios = (filterAudio: AudioDexie) => {
        const contentNid = filterAudio?.id.split("-")[1];
        if (filterAudio.isBundle) {
            let filterResumeBundle =
                resumeUserBundles &&
                resumeUserBundles.filter((x) => x.nid === contentNid);
            if (filterResumeBundle.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                resumeUserAudios &&
                resumeUserAudios.filter((x) => x.nid === contentNid).length > 0
            ) {
                const resumeData = resumeUserAudios.find(
                    (x) => x.nid === contentNid,
                );
                return !!resumeData;
            } else {
                return false;
            }
        }
    };

    return {
        globalAudios,
        memberAudios,
        receivedMemberAudioGifts,
        audiosLoading,
        isResumeableAudios,
    };
};

const useOfflineVideos = (selectedTab: Tabs) => {
    const { memberVideos, loading: videosLoading } = useVideoIndexFromDb(
        selectedTab !== Tabs.VIDEOS,
    );
    const { receivedMemberVideoGifts } = useReceivedMemberVideoGifts(true);

    const { resumeUserVideos, videos: globalVideos } = useGlobalVideos();

    const isResumeableVideos = (filteredVideo: VideoDexie) => {
        const contentNid = filteredVideo?.id.split("-")[1];
        if (
            resumeUserVideos &&
            resumeUserVideos.filter((x) => x.nid === contentNid).length > 0
        ) {
            const resumeData = resumeUserVideos.find(
                (x) => x.nid === contentNid,
            );
            return !!resumeData;
        } else {
            return false;
        }
    };

    return {
        memberVideos,
        receivedMemberVideoGifts,
        globalVideos,
        videosLoading,
        isResumeableVideos,
    };
};

const useContentFilters = (
    allAudios: (AudioContent | VideoContent | AudioDexie | Gifts)[],
    allVideos: (AudioContent | VideoContent | VideoDexie | Gifts)[],
    selectedTab: Tabs,
) => {
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 50,
        total: 0,
    });

    const [searchQuery, setSearchQuery] = useState("");
    const [debSearch, setDebSearch] = useState("");

    const match = (toMatch: string) => {
        return toMatch
            ?.toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase().trim());
    };

    const searchedAudios = useMemo(() => {
        return allAudios?.filter(
            (d) =>
                match((d as AudioContent).title) ||
                match(d.description) ||
                match((d as AudioDexie).name),
        );
    }, [allAudios, searchQuery]);

    const searchedVideos = useMemo(() => {
        return allVideos?.filter(
            (d) =>
                match((d as VideoContent).title) ||
                match(d.description) ||
                match((d as VideoDexie).name),
        );
    }, [allVideos, searchQuery]);

    useEffect(() => {
        const resetPagination = () => {
            setPaginationInfo({
                ...paginationInfo,
                total:
                    selectedTab === Tabs.AUDIOS
                        ? searchedAudios.length
                        : searchedVideos.length,
            });
        };

        resetPagination();
    }, [searchedAudios, searchedVideos, selectedTab]);

    const finalAudioData = useMemo(() => {
        return paginateData(searchedAudios, paginationInfo).data;
    }, [paginationInfo, searchedAudios]);

    const finalVideoData = useMemo(() => {
        return paginateData(searchedVideos, paginationInfo).data;
    }, [paginationInfo, searchedVideos]);

    const debouncedSearch = useMemo(() => {
        return debounce(
            (val) => {
                setSearchQuery(val);
            },
            500,
            {
                leading: false,
                trailing: true,
            },
        );
    }, []);

    return {
        debSearch,
        finalAudioData,
        finalVideoData,
        debouncedSearch,
        paginationInfo,
        setDebSearch,
        setPaginationInfo,
    };
};

const useHandlers = () => {
    const { linkProvider } = useRouting();
    const history = useHistory();

    const handleRedirectToDetail = (
        nid: string,
        type?: string,
        isAutoPlay: boolean = false,
    ) => {
        history.push(
            linkProvider.react
                .offline()
                .downloaddetail(nid, type, `${isAutoPlay}`),
        );
    };

    return { handleRedirectToDetail };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
        sendCheckboxContainer: {
            textAlign: "center",
            margin: "15px 0",
        },
        checkboxControlLabel: {
            "& .MuiTypography-body1": {
                color: `${theme.palette.grey[700]} !important`,
                fontSize: "18px",
            },
        },
        bottomSheetBtn: {
            height: `${theme.footer.height}`,
            width: "100%",
            paddingTop: "0",
            color: theme.palette.grey[700],
            borderTop: `0.2px solid ${theme.palette.grey[300]}`,
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
        bottomSheetPadding: {
            paddingBottom: "120px",
        },
        dialog: {
            width: "90vw",
        },
        closeBtn: {
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
        },
        dialogContent: {
            marginTop: "20px",
        },
        dialogTypography: {
            textAlign: "center",
            color: theme.palette.grey[600],
            marginBottom: "10px",
        },
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
    }),
);

export enum Tabs {
    AUDIOS = "Audios",
    VIDEOS = "Videos",
}

export interface DownloadsOfflineProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}
