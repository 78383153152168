import {
    Box,
    Grid,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalPlaylists, usePlaylistResume } from "JS/React/Hooks/Playlist";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import qs from "qs";
import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { sortArrayByKey, shuffle, getDownloadedAudios } from "JS/Helpers";
import {
    AudioContent,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
    ResumeAudioContent,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalPlaylistControls } from "JS/React/Hooks/PlaylistControls";
import moment from "moment";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import {
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
    toSentOfflineGift,
} from "JS/Models/Firebase/GiftDashboard";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";

export interface PlaylistPlayerProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const PlaylistPlayer = (props: PlaylistPlayerProps) => {
    const classes = useStyles(props);
    const playerRef = useRef<any>();
    const { handleChangeSeek } = usePlayerHandlers(playerRef);
    const [isReady, setIsReady] = useState(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState(false);

    const [isSingleLoop, setSingleLoop] = useState(false);
    const [contentUrl, setContentUrl] = useState<string>("");

    const [currentPlayingState, setCurrentPlayingState] = useState(true);
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        setCurrentPlayingState,
    );

    const {
        audios: allAudios,
        resumeUserAudios,
        resumeUserBundles,
        updateResumeBundles,
        setGlobalAudios,
        updateRecentlyPlayed,
        updateResumeAudios,
    } = useGlobalAudios();
    const { userPlaylists: playlists } = useGlobalPlaylists();
    const [playlistAudios, setPlaylistAudios] = useState<
        (AudioContent | Gifts)[]
    >([]);
    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        setGlobalGifts,
    } = useGlobalGifts();
    const params: any = useParams();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { id } = params;
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { index = 0, bundleIndex = 0 } = parsedQueryString;

        return {
            index: index.toString(),
            bundleIndex: bundleIndex.toString(),
        };
    }, [location?.search]);
    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };
    const mediaIndex = +queryParams.index;
    const bundleIndex = +queryParams.bundleIndex;

    const playlist = useMemo(() => {
        return playlists?.find((p) => p?.playlist_id === id);
    }, [playlists, history, id, linkProvider.react, mediaIndex]);

    const { playlistControls, toggleGlobalShuffle, toggleGlobalLoop } =
        useGlobalPlaylistControls();

    const audios = useMemo(() => {
        return playlist?.audios
            ?.map((a) => a)
            ?.sort((a, b) => +a.sort_order - +b.sort_order);
    }, [playlist]);

    const [audiosToPlay, setAudiosToPlay] =
        useState<(AudioContent | Gifts)[]>();

    useEffect(() => {
        if (audiosToPlay) {
            if (audiosToPlay.length === 0)
                history.replace(linkProvider.react.playlist().detail(id));
            else if (audiosToPlay.length - 1 < mediaIndex)
                setQueryParams({ index: "0", bundleIndex: "0" });
        }
    }, [audiosToPlay]);

    const allAudioNids = useMemo(() => {
        return allAudios
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, allAudios]);

    const rMGNids = useMemo(() => {
        return receivedMemberAudioGifts
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, receivedMemberAudioGifts]);

    const rMPNids = useMemo(() => {
        return receivedProspectAudioGifts
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, receivedProspectAudioGifts]);

    const preparePlaylistAudios = async () => {
        const audiosInPlaylist = playlist?.audios
            ?.map((a) => a)
            ?.sort((a, b) => +a.sort_order - +b.sort_order)
            ?.map((a) => {
                const selfAudio = allAudios.find((aa) => aa.nid === a.nid);
                if (selfAudio) return selfAudio;
                const memberGiftAudio = receivedMemberAudioGifts.find(
                    (rmg) => rmg.nid === a.nid,
                );
                if (memberGiftAudio) return memberGiftAudio;
                return receivedProspectAudioGifts.find(
                    (rgp) => rgp.nid === a.nid,
                );
            })
            .filter((a) => a !== undefined);

        await getDownloadedAudios(audiosInPlaylist)
            .then((res) => {
                if (res?.length) {
                    const sortedAudios = audiosInPlaylist
                        .map((a) => res.find((r) => r.nid === a.nid))
                        .filter((a) => a !== undefined);
                    setAudiosToPlay(sortedAudios);
                    setPlaylistAudios(sortedAudios);
                }
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    useEffect(() => {
        preparePlaylistAudios();
    }, [audios, allAudioNids.length, rMGNids.length, rMPNids.length]);

    useEffect(() => {
        let playlist = [];
        if (playlistControls.shuffle && playlistAudios?.length > 0) {
            const current = playlistAudios[mediaIndex];
            const shuffled = shuffle(
                playlistAudios.filter((a) => a.nid !== current?.nid),
            );
            const newPlaylist: (AudioContent | Gifts)[] = [];
            newPlaylist.push(current);
            shuffled.forEach((a) => newPlaylist.push(a));
            playlist = [...newPlaylist];
            setQueryParams({
                index: "0",
                bundleIndex: bundleIndex.toString(),
            });
        } else {
            let current = undefined;
            let indexInPlaylist = undefined;
            if (playlistAudios && playlistAudios.length > 0)
                current = audiosToPlay[mediaIndex];
            if (current) {
                indexInPlaylist = playlistAudios?.indexOf(
                    playlistAudios?.find((a) => a.nid === current.nid),
                );
                playlist = [...playlistAudios];
                setQueryParams({
                    index: indexInPlaylist
                        ? indexInPlaylist?.toString()
                        : mediaIndex?.toString(),
                    bundleIndex: bundleIndex?.toString(),
                });
            }
        }
        mapDownloadedAudios(playlist);
    }, [playlistAudios, playlistControls.shuffle]);

    const mapDownloadedAudios = async (playlistData: any) => {
        await getDownloadedAudios(playlistData)
            .then((res) => {
                if (res?.length) {
                    setAudiosToPlay(
                        playlistData
                            .map((a) => res.find((r) => r.nid === a.nid))
                            .filter((a) => a !== undefined),
                    );
                }
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    const next = () => {
        const currentAudio = audiosToPlay[mediaIndex];
        const isBundle = currentAudio?.media?.length > 1;
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            index:
                isBundle && bundleIndex < currentAudio?.media?.length - 1
                    ? mediaIndex.toString()
                    : ((mediaIndex + 1) % audiosToPlay?.length).toString(),
            bundleIndex:
                isBundle && bundleIndex < currentAudio?.media?.length - 1
                    ? (bundleIndex + 1).toString()
                    : "0",
        });
    };
    const previous = () => {
        const currentAudio = audiosToPlay[mediaIndex];
        const isBundle = currentAudio?.media?.length > 1;
        setIsReady(false);
        setIsReadyToPlay(false);
        let newMediaIndex = mediaIndex;
        if (mediaIndex === 0) {
            if (isBundle) {
                if (bundleIndex === 0) newMediaIndex = audiosToPlay?.length - 1;
                else newMediaIndex = mediaIndex;
            } else newMediaIndex = audiosToPlay?.length - 1;
        } else if (!isBundle || bundleIndex === 0)
            newMediaIndex = mediaIndex - 1;
        let newBundleIndex = bundleIndex;
        if (isBundle && bundleIndex !== 0) {
            newBundleIndex = bundleIndex - 1;
        } else if (newMediaIndex !== mediaIndex) {
            const prevAudio = audiosToPlay[newMediaIndex];
            if (prevAudio?.media?.length > 1)
                newBundleIndex = prevAudio?.media?.length - 1;
            else newBundleIndex = 0;
        }
        setCurrentPlayingState(true);
        setQueryParams({
            index: newMediaIndex?.toString(),
            bundleIndex: newBundleIndex?.toString(),
        });
    };

    const { logFirebaseEvent } = useFirebaseLogger();

    const { miniPlayer } = useMiniPlayerHandlers();

    const audio = audiosToPlay && audiosToPlay[mediaIndex];

    useEffect(() => {
        if (audiosToPlay && audiosToPlay[mediaIndex]) {
            const audio = audiosToPlay[mediaIndex];
            if (audio) {
                const isBundle = audio.media.length > 1;

                const { nid, sku_id, title } = audio;
                const action: FirebaseEventAction = {
                    action: EventActions.PLAY,
                    nId: nid,
                    category: audio.isReceived
                        ? EventCategories.GIFT_AUDIOS
                        : EventCategories.AUDIOS,
                    contentTitle: isBundle
                        ? audio?.media[bundleIndex]?.media_title
                        : title,
                    skuId: isBundle ? audio.media_file_name : sku_id,
                };

                const getEventName = () => {
                    if (audio.isReceived) {
                        return EventNames.GIFT_AUDIO_PLAYED;
                    } else {
                        return EventNames.AUDIO_PLAYED;
                    }
                };

                logFirebaseEvent(getEventName(), action);
            }
        }
    }, [audio?.nid, mediaIndex, bundleIndex]);

    useEffect(() => {
        if (audio) {
            const { nid } = audio;
            const isBundle = audio.media?.length > 1;
            const contentNid = isBundle ? `${nid}-${bundleIndex + 1}` : nid;

            getDexieConnectionRefresh()
                .audios.get(`${config.user.memberId}-${contentNid}`)
                .then((res) => {
                    if (res) {
                        setContentUrl(URL.createObjectURL(res.blob));
                    }
                })
                .catch((err) => {
                    console.log({ err });
                });
        }
    }, [audio, bundleIndex, playlist]);

    const title = () => {
        if (audiosToPlay) {
            const current = audiosToPlay[mediaIndex];
            if (current) {
                if (current.media.length > 1)
                    return current.media[bundleIndex]?.media_title;
                else return current.title;
            } else return "";
        } else return "";
    };

    const onMediaPlayerReady = useCallback(() => {
        setIsReadyToPlay(true);
        if (audiosToPlay && audiosToPlay[mediaIndex]) {
            const currentAudio = audiosToPlay[mediaIndex];
            const isBundle = currentAudio.media.length > 1;

            if (!isReady && currentAudio) {
                if (isBundle) {
                    const resumeBundleData: ResumeAudioContent =
                        resumeUserBundles
                            ? resumeUserBundles.find(
                                  (x) =>
                                      x.nid === currentAudio.nid &&
                                      x.title ===
                                          currentAudio.media[bundleIndex]
                                              ?.media_title,
                              )
                            : ({} as ResumeAudioContent);
                    if (
                        resumeBundleData &&
                        Math.ceil(+resumeBundleData.lastPlayed) !==
                            Math.ceil(+resumeBundleData.duration)
                    ) {
                        playerRef.current.seekTo(
                            resumeBundleData.lastPlayed,
                            "seconds",
                        );
                    }
                } else {
                    const resumeData: ResumeAudioContent = resumeUserAudios
                        ? resumeUserAudios.find(
                              (x) => x.nid === currentAudio?.nid,
                          )
                        : ({} as ResumeAudioContent);
                    if (
                        resumeData &&
                        Math.ceil(+resumeData.lastPlayed) !==
                            Math.ceil(+resumeData.duration)
                    ) {
                        playerRef.current.seekTo(
                            resumeData.lastPlayed,
                            "seconds",
                        );
                    }
                }
                const data: ResumeAudioContent = {
                    nid: currentAudio.nid,
                    sku_id: currentAudio.sku_id,
                    title: isBundle
                        ? currentAudio?.media[bundleIndex]?.media_title
                        : currentAudio?.title,
                    lastPlayed: playerRef.current.getCurrentTime(),
                    maximumPlayed: playerRef.current.getCurrentTime(),
                    duration: playerRef.current.getDuration(),
                };

                isBundle ? updateResumeBundles(data) : updateResumeAudios(data);
                handleRecentlyPlayed();
                setIsReady(true);
            }
        }
    }, [isReady, audiosToPlay, mediaIndex, bundleIndex]);

    const handleRecentlyPlayed = () => {
        const currentAudio = audiosToPlay[mediaIndex];

        const timeStamp = moment().unix().toString();

        if (allAudios?.filter((x) => x.nid === currentAudio?.nid).length > 0) {
            const toSaveAudios: AudioContent[] = allAudios.map((d) => {
                if (d.nid === currentAudio?.nid) {
                    return {
                        ...d,
                        played: timeStamp,
                    };
                } else {
                    return d;
                }
            });
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter(
                (video) => video.nid === currentAudio?.nid,
            ).length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (d) => {
                    if (d.nid === currentAudio?.nid) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts?.map((d) => {
                    if (d.nid === currentAudio?.nid) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayed({ ...audio, played: timeStamp });
    };

    const onPlayerProgress = async () => {
        updateResumeData({
            lastPlayed: playerRef.current.getCurrentTime(),
            maximumPlayed: playerRef.current.getCurrentTime(),
        });
    };

    const updateResumeData = (playedTime: {
        lastPlayed: string;
        maximumPlayed: string;
    }) => {
        const currentAudio = audiosToPlay && audiosToPlay[mediaIndex];
        if (currentAudio) {
            const isBundle = currentAudio.media.length > 1;
            const data: ResumeAudioContent = {
                nid: currentAudio.nid,
                sku_id: currentAudio.sku_id,
                title: isBundle
                    ? currentAudio?.media[bundleIndex]?.media_title
                    : currentAudio.title,
                lastPlayed: playedTime.lastPlayed,
                maximumPlayed: playedTime.maximumPlayed,
            };
            isBundle ? updateResumeBundles(data) : updateResumeAudios(data);
        }
    };

    const currentAudio = audiosToPlay ? audiosToPlay[mediaIndex] : undefined;

    const { savePlaylistResumeState } = usePlaylistResume(
        id,
        currentAudio?.nid,
        bundleIndex,
    );

    const isBundle = currentAudio?.media?.length > 1;

    const onComplete = () => {
        if (isReceivedMemberAudio) {
            let action = undefined;
            if (isBundle)
                action = {
                    index: bundleIndex + 1,
                };
            logGiftCompleted(action);
        }
        if (!isSingleLoop) {
            updateResumeData({
                lastPlayed: "0",
                maximumPlayed: "0",
            });
        }
        if (!playlistControls.loop && mediaIndex === audiosToPlay?.length - 1)
            savePlaylistResumeState({
                id,
                resumeAudioNid: currentAudio?.nid,
                resumeBundleIndex: 0,
            });
        if (playlistControls.loop || mediaIndex < audiosToPlay?.length - 1)
            next();
    };

    const giftDashboardData = useMemo(() => {
        return toSentGift(currentAudio);
    }, [currentAudio]);

    const getPlayerAction = () => {
        return getPlayerPlayedTimeToLog(
            playerRef,
            isBundle ? bundleIndex : null,
        );
    };

    const { isGift } = useReceivedMemberAudioGifts(true);

    const isReceivedMemberAudio = isGift(currentAudio?.nid);

    const { logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        SentGiftType.AUDIO,
        currentAudio?.sender?.member_id,
        isReceivedMemberAudio && currentPlayingState && !miniPlayer?.url,
        getPlayerAction,
        true,
    );

    return (
        <>
            <AppHeader
                title={title()}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    history.push(
                        linkProvider.react
                            .offline()
                            .playlist()
                            .detail(playlist.playlist_id),
                    );
                }}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"space-between"}
                    >
                        <Box
                            display={"flex"}
                            alignItems="center"
                            className={classes.forwardBackItem}
                            onClick={previous}
                        >
                            <FontAwesomeIcon
                                className={clsx(
                                    classes.fwdBackIcon,
                                    classes.backIcon,
                                )}
                                icon={["fas", "chevron-left"]}
                            />
                            <Typography
                                className={classes.fwdBackTypo}
                                fontWeight={"bold"}
                                variant="h5"
                            >
                                Previous
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems="center"
                            className={classes.forwardBackItem}
                            onClick={next}
                        >
                            <Typography
                                className={classes.fwdBackTypo}
                                fontWeight={"bold"}
                                variant="h5"
                            >
                                Next
                            </Typography>
                            <FontAwesomeIcon
                                className={clsx(
                                    classes.fwdBackIcon,
                                    classes.fwdIcon,
                                )}
                                icon={["fas", "chevron-right"]}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.playerContainer}>
                <MediaPlayer
                    title={title()}
                    playerRef={playerRef}
                    mediaURL={contentUrl}
                    imageURL={""}
                    onReady={onMediaPlayerReady}
                    progressInterval={PLAYER_PROGRESS_INTERVAL}
                    onProgress={onPlayerProgress}
                    onEnded={onComplete}
                    loop={isSingleLoop}
                    isAudio={true}
                    onNext={next}
                    onPrevious={previous}
                    playing={currentPlayingState && !miniPlayer.url}
                    onPlay={handlePlayerPlay}
                    onPause={handlePlayerPause}
                    loading={!isReadyToPlay}
                    onUpdateExpiredUrl={() => {
                        setIsReady(false);
                        setIsReadyToPlay(true);
                    }}
                    onError={() => {
                        setIsReadyToPlay(true);
                    }}
                />
            </Grid>
            <Grid
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <PlayerActionBtns
                    handleChangeSeek={handleChangeSeek}
                    isShuffle={playlistControls.shuffle}
                    isMultiLoop={playlistControls.loop}
                    isLoop={isSingleLoop}
                    onClickShuffle={() => {
                        toggleGlobalShuffle();
                        setIsReady(false);
                    }}
                    onClickMultiLoop={() => toggleGlobalLoop()}
                    onClickLoop={() => setSingleLoop(!isSingleLoop)}
                />
            </Grid>
            {audiosToPlay && (
                <Grid item xs={12} marginTop={1}>
                    <Typography className={classes.grey800} variant="body2">
                        {audiosToPlay[mediaIndex]?.description}
                    </Typography>
                </Grid>
            )}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            position: "relative",
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        metaData: {
            fontSize: "14px",
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },
        extraIconBox: {
            marginLeft: "5px",
        },

        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);
