import { captureSentryError } from "JS/Helpers/SentryHelper";
import { FBCourseStats, FBStepStats } from "JS/Models/Firebase/Courses";
import {
    observeCourseStats,
    observeStepStats,
} from "JS/Services/Firebase/Observer";
import { isEqual } from "lodash-es";
import { useState } from "react";
import { useFirebaseAuth } from "./Auth";

export const useObserveCourseStats = (sku: string) => {
    const { coursesFirebaseSignIn } = useFirebaseAuth();
    const [observedCourseStats, setObservedCourseStats] =
        useState<FBCourseStats>(null);

    if (sku) {
        coursesFirebaseSignIn()
            .then((res) => {
                observeCourseStats(sku, (stats) => {
                    if (!isEqual(stats, observedCourseStats)) {
                        setObservedCourseStats(stats);
                    }
                });
            })
            .catch((err) => {
                captureSentryError(err, {
                    location: `useObserveCourseStats`,
                });
            });
    }

    return { observedCourseStats };
};

export const useObserveStepStats = (courseSku: string, stepSku: string) => {
    const [observedStepStats, setObservedStepStats] =
        useState<FBStepStats>(null);

    if (courseSku && stepSku) {
        observeStepStats(courseSku, stepSku, (stats) => {
            if (!isEqual(stats, observedStepStats)) {
                setObservedStepStats(stats);
            }
        });
    }

    return { observedStepStats };
};
