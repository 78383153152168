import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    Theme,
    Box,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { AppButton } from "./AppButton";
import { AppTypography } from "./AppTypography";
import { AppCircularProgress } from "./Progress/AppCircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        dialogWarpper: {
            backgroundColor: `${theme.palette.background.default}`,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        smallTitle: {
            fontSize: "1rem",
        },
        checkLabel: {
            fontSize: "14px",
            color: theme.palette.common.black,
            whiteSpace: "nowrap",
        },
        noPadding: {
            padding: 0,
        },
        chkBoxItem: {
            color: `${theme.palette.grey[700]}`,
            "&.Mui-checked": {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        mt1: {
            marginTop: theme.spacing(1),
        },
    }),
);

export interface AppConfimationDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    onConfirm?: () => void;
    confirmText?: string;
    popupMainText: string;
    cancelButtonText: string;
    smallBodyText?: string;
    btnConfirmLoader?: boolean;
    showConfirmFirst?: boolean;
    showTitle?: boolean;
    titleText?: string;
    titleSize?: "small";
    type?: "download-warning";
    doNotShow?: boolean;
    setDoNotShow?: (val: boolean) => void;
}

export const AppConfimationDialog = (props: AppConfimationDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        onClose,
        onConfirm,
        confirmText,
        cancelButtonText,
        popupMainText,
        btnConfirmLoader,
        showConfirmFirst,
        showTitle,
        titleText,
        titleSize,
        type,
        smallBodyText,
        doNotShow,
        setDoNotShow,
        ...rest
    } = props;

    return (
        <Dialog
            role="dialog"
            aria-modal="true"
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}
        >
            <Box className={clsx(className, classes.dialogWarpper)}>
                {showTitle && (
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={12}>
                                <AppTypography
                                    variant="h6"
                                    aria-label={titleText}
                                >
                                    {titleText}
                                </AppTypography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                )}
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <AppTypography
                                variant="h6"
                                className={clsx(
                                    classes.grey600,
                                    titleSize === "small" && classes.smallTitle,
                                )}
                            >
                                {popupMainText}
                            </AppTypography>
                        </Grid>
                        {smallBodyText && (
                            <Grid item xs={12} className={classes.mt1}>
                                <AppTypography
                                    variant="body1"
                                    className={clsx(classes.grey600)}
                                >
                                    {smallBodyText}
                                </AppTypography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                {type === "download-warning" && (
                    <Box>
                        <FormControlLabel
                            classes={{
                                label: classes.checkLabel,
                            }}
                            label="Don't show again"
                            control={
                                <Checkbox
                                    style={{
                                        marginLeft: "32px",
                                        marginRight: "5px",
                                    }}
                                    checked={doNotShow}
                                    onChange={(e) =>
                                        setDoNotShow(e.target.checked)
                                    }
                                    className={clsx(
                                        classes.noPadding,
                                        classes.chkBoxItem,
                                    )}
                                    size="small"
                                />
                            }
                        />
                    </Box>
                )}
                <DialogActions
                    style={{
                        display: "flex",
                    }}
                >
                    {showConfirmFirst && (
                        <>
                            <AppButton
                                buttonVariant="grey-text"
                                role="button"
                                onClick={onClose}
                            >
                                {cancelButtonText}
                            </AppButton>
                            {confirmText &&
                                (!btnConfirmLoader ? (
                                    <AppButton
                                        role="button"
                                        buttonVariant="grey-text"
                                        onClick={onConfirm}
                                    >
                                        {confirmText}
                                    </AppButton>
                                ) : (
                                    <AppCircularProgress />
                                ))}
                        </>
                    )}
                    {!showConfirmFirst && (
                        <>
                            {confirmText &&
                                (!btnConfirmLoader ? (
                                    <AppButton
                                        role="button"
                                        buttonVariant="grey-text"
                                        onClick={onConfirm}
                                    >
                                        {confirmText}
                                    </AppButton>
                                ) : (
                                    <AppCircularProgress />
                                ))}
                            <AppButton
                                buttonVariant="grey-text"
                                role="button"
                                onClick={onClose}
                            >
                                {cancelButtonText}
                            </AppButton>
                        </>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );
};
