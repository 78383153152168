import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";

import {
    getDownloadedPartsNids,
    getPublicUrl,
    isBundleInProgress,
} from "JS/Helpers";
import {
    AudioContent,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
} from "JS/Models";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import sortableHeader from "JS/React/HOC/SortableHeader";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import {
    ColumnSort,
    getColumnSort,
    sortData,
    Sorting,
    sortViaAcquiredDate,
    sortViaPlayedDate,
} from "JS/Types";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useAppSelector } from "JS/Redux/Store";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { config } from "JS/Config";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useGetDownloadedAudio } from "JS/React/Hooks/Database";
import { messaging } from "JS/Helpers/UserMessaging";
import { AudioCategoryTitle } from "..";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    emptyList: {
        marginTop: theme.spacing(3),
        color: theme.palette.grey[500],
    },
    fab: {
        position: "fixed",
        right: "10px",
        bottom: `calc(${theme.footer.height} + 70px)`,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: "1",
    },
    floatingButton: {
        background: theme.palette.primary.main,
        "&:hover": {
            background: theme.palette.primary.main,
        },
    },
}));

export interface AudioListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    audios: (AudioContent | Gifts)[];
    paginationInfo: PaginationInfo;
    searchQuery?: string;
    categoryId?: string;
    isChecked?: boolean;
    showSortHeader?: boolean;
    onToggleSelectItem?: (nid: string, checked: boolean) => void;
    selectedPlaylistContent?: string[];
    resetPagination?: () => void;
}

const SortableHeader = sortableHeader<HTMLSpanElement>("span");

export const AudioList = (props: AudioListProps) => {
    const classes = useStyles(props);
    const {
        className,
        audios: content,
        paginationInfo,
        searchQuery,
        categoryId,
        isChecked,
        showSortHeader,
        onToggleSelectItem,
        selectedPlaylistContent,
        resetPagination,
        ...rest
    } = props;

    const { verifyAwsKeys } = useVerifyAwsKeys();

    const { resumeUserBundles, resumeUserAudios } = useGlobalAudios();

    const { favoritesAudios } = useGlobalFavorites();

    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { setGlobalAudiosStack, audiosStack } = useGlobalNavStack();

    const { handleRedirectToDetail } = useHandlers(searchQuery, categoryId);

    const [sorting, setSorting] = useState<Sorting>(
        categoryId === AudioCategoryTitle.RECENTLY_PLAYED
            ? [
                  {
                      col: "played",
                      dir: "desc",
                      position: 0,
                  },
              ]
            : audiosStack?.sorting,
    );
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const onSortChange = (columnSort: ColumnSort, oldOrder: number) => {
        resetPagination && resetPagination();
        setSorting([columnSort]);
        setGlobalAudiosStack({ sorting: [columnSort] });
    };

    const finalData = useMemo(() => {
        if (sorting[0].col === "acquired_date") {
            const newContent: (AudioContent | Gifts)[] = content.map((d) => {
                if (d.acquired_date) {
                    return d;
                } else {
                    if (d.sender) {
                        if (d.sender.sending_date) {
                            const timeStamp = moment(d.sender.sending_date_time)
                                .unix()
                                .toString();
                            return {
                                ...d,
                                acquired_date: timeStamp,
                            };
                        } else {
                            return d;
                        }
                    } else {
                        return d;
                    }
                }
            });
            return paginateData(
                sortViaAcquiredDate(newContent, sorting),
                paginationInfo,
            ).data;
        }
        if (sorting[0].col === "played")
            return paginateData(
                sortViaPlayedDate(content, sorting),
                paginationInfo,
            ).data;
        return paginateData(sortData(content, sorting), paginationInfo).data;
    }, [content, sorting, paginationInfo]);

    const { getDownloadedStatusById } = useDownloadedStatus(finalData);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (audio: AudioContent | Gifts) => {
        const url = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        return {
            id: audio.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d as AudioContent);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    const isResumeable = (filteredAudio: AudioContent | Gifts) => {
        if (filteredAudio.media.length > 1) {
            let filterResumeBundle =
                resumeUserBundles &&
                resumeUserBundles.filter((x) => x.nid === filteredAudio.nid);
            if (filterResumeBundle.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                resumeUserAudios &&
                resumeUserAudios.filter((x) => x.nid === filteredAudio.nid)
                    .length > 0
            ) {
                const resumeData = resumeUserAudios.find(
                    (x) => x.nid === filteredAudio.nid,
                );
                return !!resumeData;
            } else {
                return false;
            }
        }
    };

    const isFavouriteContent = (filteredAudio: AudioContent | Gifts) => {
        if (
            favoritesAudios &&
            favoritesAudios.find((x) => x.nid === filteredAudio.nid)
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {!!showSortHeader && (
                <Box display={"flex"} justifyContent={"space-around"}>
                    <SortableHeader
                        sort={getColumnSort(sorting, {
                            col: "title",
                            position: sorting.length,
                        })}
                        upDirection="asc"
                        downDirection="desc"
                        onSortChanged={onSortChange}
                    >
                        <span>A-Z</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "release_date",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Released</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "acquired_date",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Acquired</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "played",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Played</span>
                    </SortableHeader>
                </Box>
            )}
            {finalData.length === 0 && (
                <AppTypography
                    className={classes.emptyList}
                    align="center"
                    variant="h6"
                >
                    {messaging.common.noItemsFound}
                </AppTypography>
            )}
            <Box>
                {finalData?.map((filteredAudio, idx) => {
                    const downloadStatus = getDownloadedStatusById(
                        filteredAudio?.nid,
                    );

                    return (
                        <AppContentListItem
                            nid={filteredAudio?.nid}
                            isDownloadInProgress={
                                downloadStatus?.isDownloading &&
                                !downloadStatus?.inProgressDownloaded
                            }
                            isInDownloadQueue={
                                downloadStatus?.isInDownloadedQueue &&
                                !downloadStatus?.isDownloading
                            }
                            isDownloaded={downloadStatus?.isAudioDownloaded}
                            inProgressDownloaded={
                                downloadStatus?.inProgressDownloaded
                            }
                            selectMode={!!isChecked}
                            onGiftCheckChanged={(
                                nid: string,
                                checked: boolean,
                            ) =>
                                !!isChecked
                                    ? onToggleSelectItem(nid, checked)
                                    : () => {}
                            }
                            key={idx}
                            type="media"
                            isReceived={filteredAudio.isReceived}
                            isResume={isResumeable(filteredAudio)}
                            title={filteredAudio.title}
                            skuId={filteredAudio.sku_id}
                            attachedSku={filteredAudio?.attach_skus}
                            description={filteredAudio.description}
                            releaseDate={filteredAudio.release_date}
                            acquiredDate={filteredAudio.acquired_date}
                            sender={(filteredAudio as any).sender}
                            sortType={sorting[0].col}
                            played={filteredAudio.played}
                            showPlayIcon={
                                filteredAudio.is_purchased ||
                                filteredAudio.isReceived ||
                                filteredAudio.publish_free === "1"
                            }
                            showFavIcon={isFavouriteContent(filteredAudio)}
                            isFavourite={isFavouriteContent(filteredAudio)}
                            imageUrl={
                                thumbnails && thumbnails[filteredAudio.nid]
                            }
                            isAllowClick
                            onListItemClick={() =>
                                handleRedirectToDetail(filteredAudio, false)
                            }
                            onItemPlayClick={() =>
                                handleRedirectToDetail(filteredAudio, true)
                            }
                        />
                    );
                })}
            </Box>
        </div>
    );
};

const useDownloadedStatus = (data: (AudioContent | Gifts)[]) => {
    const { inProgressDownloaded, isAudioDownloaded, isDownloading } =
        useDownloadHelpers();

    const firstRef = useRef(true);

    const [downloadStatus, setDownloadedStatus] = useState<
        {
            nid: string;
            isDownloading: boolean;
            inProgressDownloaded: boolean;
            isInDownloadedQueue: boolean;
            isAudioDownloaded: Promise<boolean>;
        }[]
    >([]);

    const calculateDownloadStatus = (data: (AudioContent | Gifts)[]) => {
        const status = data.map((d) => ({
            nid: d.nid,
            isDownloading: isDownloading(d.nid, d.media?.length),
            inProgressDownloaded: inProgressDownloaded(d.nid, d.media?.length),
            isInDownloadedQueue: isInDownloadQueue(d.nid),
            isAudioDownloaded: isAudioDownloaded(d.nid, d.media?.length),
        }));

        setDownloadedStatus(status);
    };

    const getDownloadedStatusById = (nid: string) => {
        return downloadStatus.find((x) => x.nid === nid);
    };

    useEffect(() => {
        const calculate = () => {
            if (data && data.length) {
                firstRef.current = false;
                calculateDownloadStatus(data);
            }
        };

        if (firstRef.current) {
            calculate();
        }

        const statusInterval = setInterval(() => {
            calculate();
        }, 15000);

        return () => clearInterval(statusInterval);
    }, [data]);

    return {
        getDownloadedStatusById,
    };
};

const useDownloadHelpers = () => {
    const { getDownloadedAudioById } = useGetDownloadedAudio();

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const inProgressRef = useRef(inProgressNid);
    const downloadedContentNidsRef = useRef(downloadedContentNids);

    useEffect(() => {
        inProgressRef.current = inProgressNid;
    }, [inProgressNid]);

    useEffect(() => {
        downloadedContentNidsRef.current = downloadedContentNids;
    }, [downloadedContentNids]);

    const inProgressDownloaded = (nid: string, length: number): boolean => {
        if (downloadedContentNidsRef.current) {
            if (length > 1) {
                const downloadedIds = downloadedContentNidsRef.current.filter(
                    (downloadedNid) =>
                        downloadedNid.startsWith(
                            `${config.user.memberId}-${nid}`,
                        ),
                );
                return downloadedIds?.length === length;
            } else {
                return downloadedContentNidsRef.current.includes(
                    `${config.user.memberId}-${nid}`,
                );
            }
        } else {
            return false;
        }
    };

    const isDownloading = (nid: string, length: number) => {
        if (length > 1) return isBundleInProgress(nid);
        else return inProgressRef.current?.includes(nid);
    };

    const isAudioDownloaded = async (audioNid: string, length: number) => {
        if (length > 1) {
            const downloadedPartsNids = await getDownloadedPartsNids(audioNid);
            return downloadedPartsNids?.length === length;
        } else {
            const response = await getDownloadedAudioById(
                `${config.user.memberId}-${audioNid}`,
            );

            return response ? true : false;
        }
    };

    return {
        inProgressDownloaded,
        isDownloading,
        isAudioDownloaded,
    };
};

const useHandlers = (searchQuery: string, categoryId: string) => {
    const { linkProvider } = useRouting();
    const { logFirebaseEvent } = useFirebaseLogger();
    const history = useHistory();

    const handleRedirectToDetail = (
        filteredAudio: Gifts | AudioContent,
        isAutoPlay: boolean = false,
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredAudio.isReceived
                    ? EventCategories.GIFT_AUDIOS
                    : EventCategories.AUDIOS,
                nId: filteredAudio.nid,
                skuId: filteredAudio.sku_id,
                contentTitle: filteredAudio.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredAudio.isReceived
                    ? EventNames.GIFT_AUDIO_SEARCH_OPENED
                    : EventNames.AUDIO_SEARCH_AUDIO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .audios()
                .detail(
                    filteredAudio.nid,
                    categoryId ? categoryId : filteredAudio.content_category_id,
                    `${isAutoPlay}`,
                ),
        );
    };

    return { handleRedirectToDetail };
};
