import { INavigationDetails } from "JS/Models/NavigationModels";
import { routesForContext } from "JS/Routing";
import { AudioCategoryTitle } from "JS/React/Scenes/Audios";
import { VideoCategoryTitle } from "JS/React/Scenes/Videos";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { useCourses, useGlobalCourses } from "JS/React/Hooks/Course/Course";
import { useState } from "react";

const navigationDestinations = {
    HOME: "Home",
    CATEGORY: "Category",
    SUB_CATEGORY: "SubCategory",
    CONTENT_DETAIL: "ContentDetail",
    WEB_CAST: "Webcast",
    PLAYLIST: "Playlist",
    MY_EVENTS: "MyEvents",
    DOCUMENTS: "Documents",
    GIFT_DASHBOARD: "GiftDashboard",
    MIXED_CONTENT: "MixedContent",
};

export const HomeSegment = {
    ALL: "All",
    MY: "My",
    SAVED: "Saved",
};

const contentTypes = {
    AUDIO: "Audio",
    VIDEO: "Video",
    COURSE: "Course",
};

const getContentCategoryIDOfCategorizedAudio = (
    categorizedAudios,
    category,
) => {
    const categorizedAudio = categorizedAudios.filter(
        (audio) => audio.content_category_title === category,
    );
    if (categorizedAudio.length) {
        return categorizedAudio[0].content_category_id;
    }
    return "";
};

const getContentCategoryIDOfCategorizedVideo = (
    categorizedVideos,
    category,
) => {
    const categorizedVideo = categorizedVideos.filter(
        (video) => video.content_category_title === category,
    );
    if (categorizedVideo.length) {
        return categorizedVideo[0].content_category_id;
    }
    return "";
};

const getNIDOfVideo = (videos, contentSKU) => {
    const video = videos.filter((video) => video.sku_id === contentSKU);
    if (video.length) {
        return video[0].nid;
    }
    return "";
};

const getNIDOfAudio = (audios, contentSKU) => {
    const audio = audios.filter((audio) => audio.sku_id === contentSKU);
    if (audio.length) {
        return audio[0].nid;
    }
    return "";
};

const getNIDOfCourse = (courses, contentSKU) => {
    const course = courses.filter((course) => course.sku_id === contentSKU);
    if (course.length) {
        return course[0].nid;
    }
    return "";
};

export const useNavigation = () => {
    const linkProvider = routesForContext()();
    const history = useHistory();
    const { categorizedAudios, audios } = useGlobalAudios();
    const { refetch: fetchAudios } = useAudios(true, false);
    const { videos, categorizedVideos } = useGlobalVideos();
    const { refetch: fetchVideos } = useVideos(true, false);
    const { courses: allCourses } = useGlobalCourses();
    const { refetch: fetchCourses, courses } = useCourses(
        !allCourses || allCourses.length === 0,
        false,
    );
    const { showError } = useError();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [backdropText, setBackdropText] = useState("");

    const handleNavigationToContentHomeScreen = (
        navigationDetails: INavigationDetails,
    ) => {
        switch (navigationDetails?.contentType) {
            case contentTypes.AUDIO:
                switch (navigationDetails?.segment) {
                    case HomeSegment.SAVED:
                        history.push(linkProvider.react.audios().index());
                        break;
                    case HomeSegment.MY:
                        history.push(
                            linkProvider.react
                                .audios()
                                .listing(AudioCategoryTitle.MY_AUDIOS),
                        );
                        break;
                    case HomeSegment.ALL:
                        history.push(
                            linkProvider.react
                                .audios()
                                .listing(AudioCategoryTitle.ALL_AUDIOS),
                        );
                }
                break;
            case contentTypes.VIDEO:
                switch (navigationDetails?.segment) {
                    case HomeSegment.MY:
                        history.push(
                            linkProvider.react
                                .videos()
                                .listing(VideoCategoryTitle.MY_VIDEOS),
                        );
                        break;
                    case HomeSegment.ALL:
                        history.push(
                            linkProvider.react
                                .videos()
                                .listing(VideoCategoryTitle.ALL_VIDEOS),
                        );
                        break;
                    default:
                        history.push(linkProvider.react.videos().index());
                }
                break;

            case contentTypes.COURSE:
                switch (navigationDetails?.segment) {
                    case HomeSegment.SAVED:
                        history.push(
                            linkProvider.react
                                .courses()
                                .index(navigationDetails?.segment),
                        );
                        break;
                    case HomeSegment.MY:
                        history.push(
                            linkProvider.react
                                .courses()
                                .index(navigationDetails?.segment),
                        );
                        break;
                    default:
                        history.push(linkProvider.react.courses().index());
                }
                break;
        }
    };

    const handleNavigationToContentCategoryScreen = async (
        navigationDetails: INavigationDetails,
    ) => {
        setShowBackdrop(true);
        let content_category_id = "";

        switch (navigationDetails.contentType) {
            case contentTypes.AUDIO:
                setBackdropText(`Fetching Audios`);
                let audioCategories = categorizedAudios;
                if (audioCategories.length === 0)
                    audioCategories = (await fetchAudios()).categoryList;
                setShowBackdrop(false);
                content_category_id = getContentCategoryIDOfCategorizedAudio(
                    audioCategories,
                    navigationDetails?.category,
                );
                if (content_category_id) {
                    history.push(
                        linkProvider.react
                            .audios()
                            .listing(content_category_id),
                    );
                } else {
                    showError(
                        `Audio of category ${navigationDetails?.category}  is not found`,
                    );
                }
                break;
            case contentTypes.VIDEO:
                setBackdropText(`Fetching Videos`);
                let videoCategories = categorizedVideos;
                if (videoCategories.length === 0)
                    videoCategories = (await fetchVideos()).categoryList;
                setShowBackdrop(false);
                content_category_id = getContentCategoryIDOfCategorizedVideo(
                    videoCategories,
                    navigationDetails?.category,
                );
                if (content_category_id) {
                    history.push(
                        linkProvider.react
                            .videos()
                            .listing(`${content_category_id}`),
                    );
                } else {
                    showError(
                        `Video of category ${navigationDetails?.category}  is not found`,
                    );
                }
                break;
        }
    };
    const handleNavigationToContentDetailScreen = async (
        navigationDetails: INavigationDetails,
    ) => {
        setShowBackdrop(true);
        let nid = "";
        switch (navigationDetails?.contentType) {
            case contentTypes.AUDIO:
                setBackdropText(`Fetching Audios`);
                let allAudios = audios;
                if (allAudios.length === 0)
                    allAudios = (await fetchAudios()).allAudios;
                setShowBackdrop(false);
                nid = getNIDOfAudio(allAudios, navigationDetails?.contentSKU);
                if (nid) {
                    history.push(linkProvider.react.audios().detail(nid));
                } else {
                    showError(
                        `Audio with ${navigationDetails?.contentSKU} is not found`,
                    );
                }
                break;
            case contentTypes.VIDEO:
                setBackdropText(`Fetching Videos`);
                let allVideos = videos;
                if (allAudios.length === 0)
                    allVideos = (await fetchVideos()).allVideos;
                setShowBackdrop(false);
                nid = getNIDOfVideo(allVideos, navigationDetails?.contentSKU);
                if (nid) {
                    history.push(
                        linkProvider.react
                            .videos()
                            .detail(nid, VideoCategoryTitle.ALL_VIDEOS, "true"),
                    );
                } else {
                    showError(
                        `Video with ${navigationDetails?.contentSKU} is not found`,
                    );
                }
                break;
            case contentTypes.COURSE:
                setBackdropText(`Fetching Courses`);
                let allCourses = courses;
                if (allCourses.length === 0)
                    allCourses = (await fetchCourses()).data.courses;
                setShowBackdrop(false);
                nid = getNIDOfCourse(allCourses, navigationDetails?.contentSKU);
                if (nid) {
                    history.push(linkProvider.react.courses().detail(nid));
                } else {
                    showError(
                        `Course with ${navigationDetails?.contentSKU} is not found`,
                    );
                }
                break;
        }
    };

    const navigate = async (action: INavigationDetails) => {
        switch (action.navigationDestination) {
            case navigationDestinations.HOME:
                handleNavigationToContentHomeScreen(action);
                break;
            case navigationDestinations.CATEGORY:
                handleNavigationToContentCategoryScreen(action);
                break;
            case navigationDestinations.CONTENT_DETAIL:
                handleNavigationToContentDetailScreen(action);
                break;
            case navigationDestinations.PLAYLIST:
                history.push(linkProvider.react.playlist().index("home"));
                break;
            case navigationDestinations.WEB_CAST:
                history.push(linkProvider.react.webcast().index());
                break;
            case navigationDestinations.MY_EVENTS:
                history.push(linkProvider.react.events().index());
                break;
            case navigationDestinations.DOCUMENTS:
                history.push(linkProvider.react.documents().index());
                break;
            case navigationDestinations.GIFT_DASHBOARD:
                history.push(linkProvider.react.giftDashboard().index());
                break;
            case navigationDestinations.MIXED_CONTENT:
                history.push(
                    linkProvider.react.mixedContent().index(action.filePath),
                );
                break;
        }
    };
    return {
        navigate,
        showBackdrop,
        backdropText,
    };
};

export const useError = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showError = (message: string) => {
        enqueueSnackbar(message, {
            variant: "warning",
        });
    };

    return {
        showError,
    };
};
