import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { InternalStandardProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { intersectionWith, omit, uniqBy } from "lodash";
import qs from "qs";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { Layout } from "../Layout";
import { MixedContentListing } from "./MixedContentListing";
import { PaginationInfo } from "JS/Types/Pagination";
import { useMixedContent } from "JS/React/Hooks/MixedContent";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { MixedDataObject } from "JS/Models/MixedConent";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { useCourses, useGlobalCourses } from "JS/React/Hooks/Course/Course";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { AudioContent, Course, VideoContent } from "JS/Models";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `70vh`,
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
    }),
);

export interface MixedContentProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const MixedContent = (props: MixedContentProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();

    const [searchQuery, setSearchQuery] = useState(
        homeStack?.searchQuery ? homeStack?.searchQuery : "",
    );
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 25,
        total: 0,
    });
    const {
        loading,
        audiosLoading,
        videosLoading,
        coursesLoading,
        mappedMixedContent,
    } = useMixListingHandlers();

    const onBackClick = () => {
        history.push(linkProvider.react.home());
    };

    const isContentLoaded = !(
        loading ||
        audiosLoading ||
        videosLoading ||
        coursesLoading
    );

    return (
        <Layout
            paginationInfo={isContentLoaded && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 25,
                });
            }}
        >
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title="Mixed Content"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search content by title/description"}
                    canGoBack={true}
                    onBackClick={onBackClick}
                />
                <RenderIf
                    isTrue={isContentLoaded}
                    fallback={
                        <AppBackdropProgress
                            open={true}
                            backdropText={messaging.loader.content}
                        />
                    }
                >
                    <RenderIf
                        isTrue={mappedMixedContent.length > 0}
                        fallback={
                            <Typography
                                align="center"
                                variant="h6"
                                className={classes.content}
                            >
                                {messaging.common.noItemsFound}
                            </Typography>
                        }
                    >
                        <MixedContentListing
                            searchQuery={searchQuery}
                            setPaginationInfo={setPaginationInfo}
                            paginationInfo={paginationInfo}
                            content={mappedMixedContent}
                        />
                    </RenderIf>
                </RenderIf>
            </div>
        </Layout>
    );
};

const useMixListingHandlers = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { filePath = "" } = parsedQueryString;

        return { filePath: filePath.toString() };
    }, [location?.search]);

    const { loading, mixedContentJson } = useMixedContent(queryParams.filePath);
    const { audios, isAudioUpdateRequired } = useGlobalAudios();
    const { loading: audiosLoading } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );

    const { videos, isVideoUpdateRequired } = useGlobalVideos();
    const { loading: videosLoading } = useVideos(
        !!videos && videos.length > 0,
        isVideoUpdateRequired,
    );

    const { courses, isCourseUpdateRequired } = useGlobalCourses();
    const { loading: coursesLoading } = useCourses(
        !!courses && courses.length > 0,
        isCourseUpdateRequired,
    );

    const mappedMixedContent: MappedListingType[] = useMemo(() => {
        if (mixedContentJson) {
            const filteredAudios =
                audios && mixedContentJson.audios
                    ? audios
                          .filter((a) =>
                              mixedContentJson.audios
                                  .map((a) => a.sku)
                                  .includes(a.sku_id),
                          )
                          .map((audio) => {
                              return {
                                  ...audio,
                                  ...mixedContentJson.audios.find(
                                      (a) => a.sku === audio.sku_id,
                                  ),
                              };
                          })
                    : [];
            const filteredVideos =
                videos && mixedContentJson.videos
                    ? videos
                          .filter((v) =>
                              mixedContentJson.videos
                                  .map((v) => v.sku)
                                  .includes(v.sku_id),
                          )
                          .map((video) => {
                              return {
                                  ...video,
                                  ...mixedContentJson.videos.find(
                                      (v) => v.sku === video.sku_id,
                                  ),
                              };
                          })
                    : [];
            const filteredCourses =
                courses && mixedContentJson.courses
                    ? courses
                          .filter((c) =>
                              mixedContentJson.courses
                                  .map((c) => c.sku)
                                  .includes(c.sku_id),
                          )
                          .map((course) => {
                              return {
                                  ...course,
                                  ...mixedContentJson.courses.find(
                                      (c) => c.sku === course.sku_id,
                                  ),
                              };
                          })
                    : [];
            return uniqBy(
                [...filteredAudios, ...filteredVideos, ...filteredCourses],
                "nid",
            );
        } else return [];
    }, [mixedContentJson, audios, videos, courses]);
    return {
        loading,
        audiosLoading,
        videosLoading,
        coursesLoading,
        mappedMixedContent,
    };
};

export type MappedListingType =
    | (MixedDataObject & AudioContent)
    | (MixedDataObject & VideoContent)
    | (MixedDataObject & Course);
