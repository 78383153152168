import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import {
    useCreatePlaylist,
    useDeletePlaylist,
    useGlobalPlaylists,
    usePlaylists,
    useRenamePlaylist,
    useSortPlaylists,
} from "JS/React/Hooks/Playlist";
import { useEffect, useMemo, useState } from "react";
import { Layout } from "../Layout";
import { useSnackbar } from "notistack";
import { Box, Fab, Grid, ListItem } from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory, useLocation } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { InternalStandardProps } from "@mui/material";
import clsx from "clsx";
import { InputDialog } from "JS/React/Components/InputDialog";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { EventActions, EventCategories, EventNames, Playlist } from "JS/Models";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import qs from "qs";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import React from "react";
import { sortPlaylists } from "JS/Helpers";
import { AppDivider } from "JS/React/Components/AppDivider";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        list: {
            overflowY: "auto",
            width: "100%",
            height: `calc(100vh - ${theme.footer.height} - 60px)`,
            paddingBottom: `calc(3*${theme.footer.height})`,
        },
        fab: {
            position: "fixed",
            right: "10px",
            bottom: `calc(${theme.footer.height} + 10px)`,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        floatingButton: {
            background:
                theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.grey[600],
            "&:hover": {
                background:
                    theme.palette.mode === "dark"
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
            },
        },
        sortableListItem: {
            color: theme.palette.common.black,
        },
    }),
);

export interface PlaylistProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

interface SortablePlaylistContainerProps {
    items: Playlist[];
    isEdit: boolean;
    setPlaylistIdForDelete: React.Dispatch<React.SetStateAction<string>>;
    setPlaylistIdForRename: React.Dispatch<React.SetStateAction<string>>;
}
interface SortableElementProps {
    value: Playlist;
    isEdit: boolean;
    setPlaylistIdForDelete: React.Dispatch<React.SetStateAction<string>>;
    setPlaylistIdForRename: React.Dispatch<React.SetStateAction<string>>;
}

const DragHandle = SortableHandle(() => (
    <Box onClick={(e) => e.stopPropagation()} pl={1}>
        <FontAwesomeIcon icon={["fas", "bars"]} />
    </Box>
));

const SortablePlaylist = SortableElement((props: SortableElementProps) => {
    const history = useHistory();
    const { linkProvider } = useRouting();
    const {
        value: p,
        isEdit,
        setPlaylistIdForDelete,
        setPlaylistIdForRename,
    } = props;
    const classes = useStyles(props);
    return (
        <Box key={`sortable-playlist-wrapper-${p.playlist_id}`}>
            <Grid
                display={"flex"}
                flexDirection={"row"}
                key={`sortable-playlist-grid-${p.playlist_id}`}
                className={classes.sortableListItem}
            >
                {isEdit && (
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        key={`sortable-playlist-edit-wrapper-${p.playlist_id}`}
                    >
                        <Box
                            onClick={() =>
                                setPlaylistIdForDelete(p.playlist_id)
                            }
                            key={`sortable-playlist-remove-wrapper-${p.playlist_id}`}
                        >
                            <AppDownloadingIcons iconType="trash" />
                        </Box>
                        <Box
                            pl={1}
                            onClick={() =>
                                setPlaylistIdForRename(p.playlist_id)
                            }
                            key={`sortable-playlist-rename-wrapper-${p.playlist_id}`}
                        >
                            <FontAwesomeIcon icon={["fas", "pen"]} />
                        </Box>
                        <DragHandle />
                    </Box>
                )}
                <Box
                    key={`sortable-playlist-item-wrapper-${p.playlist_id}`}
                    width={"100%"}
                >
                    <ListItem
                        onClick={(_) =>
                            history.push(
                                linkProvider.react
                                    .playlist()
                                    .detail(p.playlist_id),
                            )
                        }
                        key={`sortable-playlist-item-${p.playlist_id}`}
                    >
                        <AppTypography
                            key={`sortable-playlist-item-name-${p.playlist_id}`}
                        >
                            {p.playlist_name}{" "}
                            {p.showCount ? `(${p.count})` : ""}
                        </AppTypography>
                    </ListItem>
                </Box>
            </Grid>
            <AppDivider />
        </Box>
    );
});

const SortablePlaylists = SortableContainer(
    (props: SortablePlaylistContainerProps) => {
        const {
            items,
            isEdit,
            setPlaylistIdForDelete,
            setPlaylistIdForRename,
        } = props;
        const classes = useStyles(props);
        return (
            <Grid className={classes.list} key={"playlist-listing-grid"}>
                {items.map((item, i) => (
                    <SortablePlaylist
                        value={item}
                        index={i}
                        key={`playlist-items-listing-${i}`}
                        isEdit={isEdit}
                        setPlaylistIdForDelete={setPlaylistIdForDelete}
                        setPlaylistIdForRename={setPlaylistIdForRename}
                    />
                ))}
            </Grid>
        );
    },
);

export const PlaylistListing = (props: PlaylistProps) => {
    const classes = useStyles(props);
    const { className } = props;
    const { homeStack } = useGlobalNavStack();
    const { loading, error } = usePlaylists(false);
    const { userPlaylists: playlistsInStore } = useGlobalPlaylists();
    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [isSortChanged, setSortChanged] = useState(false);
    const location = useLocation();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { segment = "" } = parsedQueryString;

        return {
            segment: segment.toString(),
        };
    }, [location?.search]);
    const {
        loading: createLoading,
        error: createError,
        success: createSuccess,
        create,
    } = useCreatePlaylist();
    const {
        loading: deleteLoading,
        error: deleteError,
        success: deleteSuccess,
        deletePlaylist,
    } = useDeletePlaylist();
    const [playlistIdForDelete, setPlaylistIdForDelete] = useState<string>();
    const {
        loading: renameLoading,
        error: renameError,
        success: renameSuccess,
        rename,
    } = useRenamePlaylist();
    const {
        loading: sortLoading,
        error: sortError,
        success: sortSuccess,
        sort,
    } = useSortPlaylists();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const [isCreate, setCreate] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [playlistIdForRename, setPlaylistIdForRename] = useState<string>();

    useMemo(() => {
                setPlaylists(sortPlaylists(playlistsInStore));
    }, [playlistsInStore]);

    const createPlaylist = (input: string) => {
        setCreate(false);
        if (input && input.length <= 45) {
            create(input);
        } else {
            enqueueSnackbar(
                !input || input.length === 0
                    ? messaging?.playlist?.emptyInput
                    : messaging?.playlist?.characterInput,
                {
                    variant: "warning",
                },
            );
        }
    };

    useEffect(() => {
        if (createError)
            enqueueSnackbar(createError.error, {
                variant: "error",
            });
    }, [createError]);

    const { logFirebaseEvent } = useFirebaseLogger();
    useEffect(() => {
        if (createSuccess) {
            logFirebaseEvent(EventNames.AUDIO_PLAYLIST_ADDED, {
                action: EventActions.ADDED,
                category: EventCategories.AUDIO_PLAYLIST,
                playlistId: createSuccess.playlist_id,
            });
            enqueueSnackbar(createSuccess.success, {
                variant: "success",
            });
        }
    }, [createSuccess]);

    useEffect(() => {
        if (deleteError) {
            setPlaylistIdForDelete(undefined);
            enqueueSnackbar(deleteError.error, {
                variant: "error",
            });
        }
    }, [deleteError]);

    useEffect(() => {
        if (deleteSuccess) {
            logFirebaseEvent(EventNames.AUDIO_PLAYLIST_DELETED, {
                action: EventActions.DELETE,
                category: EventCategories.AUDIO_PLAYLIST,
                playlistId: playlistIdForDelete,
            });
            setPlaylistIdForDelete(undefined);
            setEdit(false);
            enqueueSnackbar(deleteSuccess.success, {
                variant: "success",
            });
        }
    }, [deleteSuccess]);

    useEffect(() => {
        if (renameError) {
            setPlaylistIdForRename(undefined);
            enqueueSnackbar(renameError.error, {
                variant: "error",
            });
        }
    }, [renameError]);

    useEffect(() => {
        if (renameSuccess) {
            setPlaylistIdForRename(undefined);
            setEdit(false);
            enqueueSnackbar(renameSuccess.success, {
                variant: "success",
            });
        }
    }, [renameSuccess]);

    useEffect(() => {
        if (sortSuccess) {
            setSortChanged(false);
            enqueueSnackbar(sortSuccess.success, {
                variant: "success",
            });
        }
    }, [sortSuccess]);

    useEffect(() => {
        if (sortError) {
            setSortChanged(false);
            enqueueSnackbar(sortError.error, {
                variant: "error",
            });
        }
    }, [sortError]);

    const renamePlaylist = (newName: string) => {
        if (newName && newName.length <= 45) {
            rename(newName, playlistIdForRename);
        } else {
            enqueueSnackbar(
                !newName || newName.length === 0
                    ? "Sorry, there must be some letters (a-z), numbers (0-9) in playlist name."
                    : "The playlist title must not exceed 45 characters.",
                {
                    variant: "warning",
                },
            );
        }
    };

    const playlistNameForRename = useMemo(() => {
        return playlists?.find((p) => p.playlist_id === playlistIdForRename)
            ?.playlist_name;
    }, [playlistIdForRename, playlists]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSortChanged(true);
        setPlaylists(arrayMoveImmutable(playlists, oldIndex, newIndex));
    };
    useSeamlessUpdate();

    return (
        <Layout>
            <AppHeader
                title={"Audio Playlists"}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    if (!homeStack?.isHomeTrack) {
                        history.push(linkProvider.react.more().index());
                    } else {
                        history.replace(linkProvider.react.home());
                    }
                }}
            />
            {!loading && !error && (
                <SortablePlaylists
                    key={"playlist-listing"}
                    items={playlists}
                    useDragHandle={true}
                    onSortEnd={onSortEnd}
                    isEdit={isEdit}
                    setPlaylistIdForDelete={setPlaylistIdForDelete}
                    setPlaylistIdForRename={setPlaylistIdForRename}
                />
            )}
            <Box className={clsx(className, classes.fab)}>
                <Fab
                    color="primary"
                    aria-label="add"
                    className={clsx(className, classes.floatingButton)}
                    onClick={() => setCreate(true)}
                >
                    <FontAwesomeIcon icon={["fas", "plus"]} />
                </Fab>
                <Box height={"5px"} />
                <Fab
                    color="primary"
                    aria-label="edit"
                    className={clsx(className, classes.floatingButton)}
                    onClick={() => {
                        if (isEdit && isSortChanged) sort(playlists);
                        setEdit(!isEdit);
                        setSortChanged(false);
                    }}
                >
                    {isEdit ? (
                        <FontAwesomeIcon icon={["fas", "circle-check"]} />
                    ) : (
                        <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                    )}
                </Fab>
            </Box>
            {isCreate && (
                <InputDialog
                    open={true}
                    title={"Create Playlist"}
                    hint={"Please enter playlist name."}
                    cancelText={"Cancel"}
                    okText={"Create"}
                    cancelClicked={() => setCreate(false)}
                    okClicked={createPlaylist}
                />
            )}
            {playlistIdForRename && !renameLoading && (
                <InputDialog
                    open={true}
                    title={"Rename Playlist"}
                    hint={"Please enter playlist name."}
                    cancelText={"Cancel"}
                    okText={"Rename"}
                    existingInput={playlistNameForRename}
                    cancelClicked={() => setPlaylistIdForRename(undefined)}
                    okClicked={renamePlaylist}
                />
            )}
            {loading && !error && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.playlist}
                />
            )}
            {createLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.create_playlist}
                />
            )}
            {renameLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Renaming Playlist"}
                />
            )}
            {sortLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Updating playlist position"}
                />
            )}
            {error && <AppTypography>{error.error}</AppTypography>}
            {playlistIdForDelete && !deleteLoading && (
                <AppConfimationDialog
                    open
                    onConfirm={() => deletePlaylist(playlistIdForDelete)}
                    onClose={() => setPlaylistIdForDelete(undefined)}
                    popupMainText="Are you sure you want to delete this playlist?"
                    confirmText="Yes"
                    cancelButtonText="No"
                />
            )}
            {deleteLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Deleting Playlist"}
                />
            )}
        </Layout>
    );
};
