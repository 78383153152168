import { EventItem } from "JS/React/Components/EventItem";
import { Theme } from "@mui/material";
import { Layout } from "../Layout";
import { InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTypography } from "JS/React/Components/AppTypography";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGetWebcast, useGlobalWebcast } from "JS/React/Hooks/Webcast";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useMemo } from "react";
import qs from "qs";

export interface WebcastProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const Webcast = (props: WebcastProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { webcasts } = useGlobalWebcast();

    const { loading, isError } = useGetWebcast(false);
    useSeamlessUpdate();
    const { queryParams, handleBackNavigation } = useHandler();

    return (
        <Layout>
            <AppHeader
                title={"Webcast"}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            <div {...rest} className={clsx(className, classes.root)}>
                {loading && !isError && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.webcast}
                    />
                )}
                {isError && (
                    <AppTypography className={classes.textColor}>
                        There is no scheduled webcast at this time.
                    </AppTypography>
                )}
                {!loading &&
                    !isError &&
                    webcasts
                        .map((w) => w)
                        .sort((w1, w2) =>
                            w1.Description.localeCompare(w2.Description),
                        )
                        .sort((w1, w2) => w1.priority - w2.priority)
                        .map((w, idx) => (
                            <EventItem
                                key={`${w.Description}${idx}`}
                                event={{
                                    EventName: w.Description,
                                    EventStartDateAndTime: w.StartDate,
                                    EventEndDateAndTime: w.EndDate,
                                    last_updated_date: w.EndDate,
                                    Tickets: [],
                                }}
                                onEventItemClick={(_, eventName) => {
                                    history.push(
                                        linkProvider.react
                                            .webcast()
                                            .detail(
                                                eventName,
                                                queryParams?.backNav === "true"
                                                    ? true
                                                    : false,
                                            ),
                                    );
                                }}
                            />
                        ))}
            </div>
        </Layout>
    );
};

const useHandler = () => {
    const location = useLocation();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { backNav = "" } = parsedQueryString;

        return {
            backNav: backNav.toString(),
        };
    }, [location?.search]);

    const handleBackNavigation = () => {
        if (queryParams?.backNav === "true") {
            history.goBack();
        } else {
            if (!homeStack?.isHomeTrack) {
                history.push(linkProvider.react.more().index());
            } else {
                history.push(linkProvider.react.home());
            }
        }
    };
    return { queryParams, handleBackNavigation };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        textColor: {
            color: `${theme.palette.primary.main}`,
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
    }),
);
