import { config } from "JS/Config";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class FBAuthService extends BaseService {
    async getFirebaseToken(dbName: "webcasts" | "courses") {
        return (
            await this.doXHR<AppResponse<string>>({
                url: this.routes.server.api.users.getFirebaseToken(
                    config?.user?.memberId,
                    dbName,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
