import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    ListItemText,
    MenuItem,
    MenuList,
    Theme,
    Typography,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    useGiftRemainingQuantity,
    useGlobalGifts,
    useVideoGifts,
    useSendGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
    useProsGiftRemainingQuantity,
} from "JS/React/Hooks/Gifts";
import {
    useAvailableCredits,
    usePurchaseByCard,
    useAddContentToCart,
    useRedeemByCredits,
    useRedeemGiftByCredits,
} from "JS/React/Hooks/Purchase";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Gifts, VideoContent } from "JS/Models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPublicUrl, getSizeOfContent } from "JS/Helpers/S3Helper";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { AppButton } from "JS/React/Components/AppButton";
import moment from "moment";
import { ContentPurchase } from "JS/React/Components/ContentPurchase/ContentPurchase";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import { useSnackbar } from "notistack";
import { GiftQuantity } from "JS/React/Components/ContentPurchase/GiftQuantity";
import {
    giftTypeIdToString,
    giftTypeStringToId,
    isSufficientCreditAvailable,
    invalidContentSize,
    isRedeemable,
} from "JS/Helpers";
import { SelectMembersDialog } from "JS/React/Components/ContentPurchase/SelectMembersDialog";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import {
    FirebaseEventAction,
    EventCategories,
    EventNames,
    EventActions,
} from "JS/Models";
import { messaging } from "JS/Helpers/UserMessaging";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    useCheckContentDownloaded,
    useHandleMediaGifts,
    useSearchedLosMembers,
    useVerifyAwsKeys,
} from "JS/React/Hooks/Media";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import {
    DownloadStatus,
    addToDownloadQueue,
    getDownloadQueueLength,
    isInDownloadQueue,
} from "JS/Helpers/ContentDownloadHelper";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { config } from "JS/Config";
import { setDownloadedContentNids } from "JS/Redux/download";
import {
    useGetFavorites,
    useGlobalFavorites,
    useMarkFavorites,
    useUnMarkFavorites,
} from "JS/React/Hooks/Favorites";
import { PLAYER_PROGRESS_INTERVAL, appConstants } from "JS/Helpers/Contants";
import {
    AddToDownloadOptions,
    GiftsTypes,
    MiniPlayerOptions,
} from "JS/Models/Common";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { useDownloadingStatus } from "JS/React/Hooks/Downloading";
import { DownloadDisabled } from "JS/React/Components/DownloadDisabled";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { useResumeRecentlyVideo } from "JS/React/Hooks/ResumeRecently";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { AppDivider } from "JS/React/Components/AppDivider";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import { AppMenuListing } from "JS/React/Components/AppMenuListing";
import {
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { VideoCategoryTitle } from ".";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import qs from "qs";

export const VideoContentDetail = (props: VideoContentDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const params: any = useParams();
    const { linkProvider } = useRouting();
    const { homeStack, setGlobalVideosStack } = useGlobalNavStack();
    const { contentId, categoryId, isFavorite, isAutoPlay } = params;
    const { enqueueSnackbar } = useSnackbar();

    //For Downloaded Video
    const [downloadedVideoUrl, setDownloadedVideoUrl] = useState<string>("");
    const [isVideoDownloaded, setIsVideoDownloaded] = useState<boolean>(false);
    const [addedToDownloadQueue, setAddedToDownloadQueue] =
        useState<boolean>(false);
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [sizeOfContent, setSizeOfContent] = useState(0);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [queueLimitReached, setQueueLimitReached] = useState(false);
    const [videoURL, setVideoURL] = useState("");
    const [currentPlayingState, setCurrentPlayingState] = useState(
        isAutoPlay === "true",
    );

    const [playing, setPlaying] = useState(false);

    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        (val) => {
            setPlaying(val);
            setCurrentPlayingState(val);
        },
    );
    //For Gift Icon
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [thumbnail, setThumbnail] = useState<string>(null);
    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const dispatch = useAppDispatch();

    const { videos, setGlobalVideos, isVideoUpdateRequired } =
        useGlobalVideos();

    const { verifyAwsKeys } = useVerifyAwsKeys();

    const { loading: videosLoading } = useVideos(
        !!videos && videos.length > 0,
        isVideoUpdateRequired,
    );

    const { videoGifts, receivedMemberVideoGifts, receivedProspectVideoGifts } =
        useGlobalGifts();

    const {
        loading: receivedMemberVideoGiftsLoading,
        loaded: receivedMemberGiftsLoaded,
        isGift,
    } = useReceivedMemberVideoGifts(
        !!receivedMemberVideoGifts && receivedMemberVideoGifts.length > 0,
    );

    const {
        loading: receivedProspectVideoGiftsLoading,
        loaded: receivedProspectGiftsLoaded,
    } = useReceivedProspectVideoGifts(
        !!receivedProspectVideoGifts && receivedProspectVideoGifts.length > 0,
    );
    const queryParams = useQueryParams();

    const { isMixedContent } = queryParams;

    const video: VideoContent | Gifts = useMemo(() => {
        if (videos?.filter((video) => video.nid === contentId).length > 0) {
            return videos?.find((video) => video.nid === contentId);
        } else if (
            receivedMemberVideoGifts?.filter((video) => video.nid === contentId)
                .length > 0
        ) {
            return receivedMemberVideoGifts?.find(
                (video) => video.nid === contentId,
            );
        } else {
            return receivedProspectVideoGifts?.find(
                (video) => video.nid === contentId,
            );
        }
    }, [
        videos,
        receivedMemberVideoGifts,
        contentId,
        receivedProspectVideoGifts,
    ]);

    const giftDashboardData = useMemo(() => {
        return toSentGift(video);
    }, [video]);

    const analyticsData = (action?: EventActions) => {
        const { sku_id: skuId, nid: nId, title: contentTitle } = video;
        return {
            action,
            contentTitle,
            skuId,
            nId,
            category: video?.isReceived
                ? EventCategories.GIFT_VIDEOS
                : EventCategories.VIDEOS,
        };
    };

    const {
        playerRef,
        setIsReady,
        handleRecentlyPlayed,
        handleContentCompleted,
        onMediaPlayerReady,
        onPlayerProgress,
    } = useResumeRecentlyVideo(video, contentId, setCurrentPlayingState);

    //Favorites
    const isFavoritesTab = isFavorite && JSON.parse(isFavorite?.toLowerCase());

    const { favoritesVideos, setGlobalFavoritesVideos } = useGlobalFavorites();
    const { refetch: fetchFavorites, loading: favoritesLoading } =
        useGetFavorites(!!favoritesVideos && favoritesVideos.length > 0);

    const isFavoriteVideo =
        favoritesVideos?.findIndex((x) => x.nid === video?.nid) !== -1;

    const { markFavoriteByNid, loading: markFavLoading } = useMarkFavorites();
    const { unMarkFavoriteByNid, loading: unMarkFavLoading } =
        useUnMarkFavorites();

    const { loading: videoGiftsLoading } = useVideoGifts(
        !!videoGifts && videoGifts.length > 0,
    );

    const {
        availableCredits,
        loading: availableCreditsLoading,
        getAvailableAudioCredits,
    } = useAvailableCredits();
    const { redeemByCredits, loading: redeemingContent } = useRedeemByCredits();
    const { redeemGiftByCredits, loading: redeemingGiftContent } =
        useRedeemGiftByCredits();

    const { addToCart, cart } = useAddContentToCart();
    const { purchaseByCard, loading: purchasingContent } = usePurchaseByCard();

    useCheckContentDownloaded(
        video?.nid,
        inProgressNid,
        "video",
        setIsVideoDownloaded,
        setDownloadedVideoUrl,
    );

    const [mediaURL, setMediaURL] = useState<string>("");
    const [purchaseEnabled, setPurchaseEnabled] = useState<boolean>(false);
    const [giftItem, setGiftItem] = useState<Gifts>(null);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        video?.is_redeemable_by_tokens === "1"
            ? PaymentMethod.MY_CREDITS
            : PaymentMethod.CARD,
    );

    const videoGiftSku = (giftType) => {
        if (video && !video?.isReceived) {
            if (
                (video as VideoContent)?.attach_skus[
                giftTypeStringToId(giftType)
                ]
            ) {
                return (video as VideoContent)?.attach_skus[
                    giftTypeStringToId(giftType)
                ];
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    const {
        giftQuantity: giftMemberQuanity,
        refetch: fetchGiftMemberQuanity,
        loading: loadingGiftMemeberQuanity,
    } = useGiftRemainingQuantity(
        videoGiftSku(GiftsTypes.MEMBER),
        video &&
        !video?.isReceived &&
        Object.keys((video as VideoContent)?.attach_skus).length === 0,
    );

    const {
        activeGiftItem,
        dialogs,
        selectedMembers,
        anchorSendGift,
        openSendGift,
        setDialogs,
        setActiveGiftItem,
        onQuantityChange,
        onSendContent,
        onToggleItemSelect,
        onCloseSelectMembers,
        setAnchorSendGift,
        onGiftSendMenuItem,
        onSendItemsClose,
    } = useHandleMediaGifts(giftMemberQuanity, setIsReady, setPurchaseEnabled);

    useEffect(() => {
        setPaymentMethod(
            isRedeemable(video, availableCredits)
                ? PaymentMethod.MY_CREDITS
                : PaymentMethod.CARD,
        );
    }, [
        video?.is_redeemable_by_tokens,
        availableCredits,
        video?.number_of_tokens,
    ]);

    const {
        giftQuantity: giftProspectQuanity,
        refetch: fetchGiftProspectQuanity,
        loading: loadingGiftProspectQuanity,
    } = useProsGiftRemainingQuantity(
        videoGiftSku(GiftsTypes.PROSPECT),
        video &&
        !video?.isReceived &&
        Object.keys((video as VideoContent)?.attach_skus).length === 0,
    );
    //Player Resume States
    const { handleChangeSeek } = usePlayerHandlers(playerRef);
    const { searchLosResults, searchQueryLos, setSearchQueryLos } =
        useSearchedLosMembers();

    //Send Gift
    const { sendGiftToUser, loading: sendingGifts } = useSendGifts();
    const { logFirebaseEvent } = useFirebaseLogger();

    const isMediaPlayable =
        video?.is_purchased ||
        video?.publish_free === "1" ||
        video?.publish_free == "true" ||
        video?.isReceived;

    const videoLoaded =
        videosLoading &&
        receivedProspectGiftsLoaded &&
        receivedMemberGiftsLoaded;

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeToLog(playerRef);
    };

    const isReceivedMemberVideo = isGift(contentId);

    const { miniPlayer, onClickMiniPlayer } = useMiniPlayerHandlers();

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        SentGiftType.VIDEO,
        video?.sender?.member_id,
        isReceivedMemberVideo && playing && !miniPlayer?.url,
        getDashboardActionDetails,
    );

    useEffect(() => {
        if (video) {
            const {
                media_url_prefix,
                media_url_postfix,
                media_file_name,
                nid,
            } = video;

            getSizeOfContent(
                `${media_url_postfix}/${media_file_name}`,
                media_url_prefix,
            )
                .then((res) => {
                    setSizeOfContent(res);
                })
                .catch((err) => {
                    setSizeOfContent(0);
                });
            getDexieConnectionRefresh()
                .videos.get(`${config.user.memberId}-${nid}`)
                .then((res) => {
                    if (res) {
                        setIsReady(false);
                        setIsVideoDownloaded(true);
                        setAddedToDownloadQueue(false);
                        setDownloadedVideoUrl(URL.createObjectURL(res.blob));
                    }
                })
                .catch((err) => {
                    setIsReady(true);
                    setIsVideoDownloaded(false);
                });

            logFirebaseEvent(
                video?.isReceived
                    ? EventNames.GIFT_VIDEO_OPENED
                    : EventNames.VIDEO_OPENED,
                analyticsData(EventActions.OPEN),
            );

            verifyAwsKeys().then(() => {
                fetchThumbnails();
                getPublicUrl(
                    media_url_prefix,
                    media_url_postfix,
                    media_file_name,
                )
                    .then((signedURL) => setMediaURL(signedURL))
                    .catch((err) => console.log(err));
            });
        }
    }, [video?.nid]);

    useEffect(() => {
        if (isReceivedMemberVideo && video) logGiftOpen();
    }, [video?.nid, isReceivedMemberVideo]);

    useEffect(() => {
        let interval;
        if (video && (inProgressNid === video?.nid || addedToDownloadQueue)) {
            const { nid } = video;
            interval = setInterval(() => {
                if (!isInDownloadQueue(nid)) {
                    setAddedToDownloadQueue(false);
                }
                getDexieConnectionRefresh()
                    .videos.get(`${config.user.memberId}-${nid}`)
                    .then((res) => {
                        if (res) {
                            setIsReady(false);
                            setIsVideoDownloaded(true);
                            setAddedToDownloadQueue(false);
                            setDownloadedVideoUrl(
                                URL.createObjectURL(res.blob),
                            );
                        }
                    })
                    .catch((err) => {
                        setIsReady(true);
                        setIsVideoDownloaded(false);
                    });

                if (isVideoDownloaded) {
                    clearInterval(interval);
                }
            }, 5000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [video, addedToDownloadQueue, isVideoDownloaded]);

    useEffect(() => {
        if (!video && videoLoaded) {
            history.goBack();
        }
    }, [video, videoLoaded, history]);

    const fetchThumbnails = async () => {
        if (!thumbnail) {
            const thumb = await getPublicUrl(
                video.image_url_prefix,
                video.image_url_postfix,
                video.image_file_name,
            );

            setThumbnail(thumb);
        }
    };

    const handleVideoStartedPlaying = () => {
        if (video) {
            if (!isVideoDownloaded) {
                logFirebaseEvent(
                    video?.isReceived
                        ? EventNames.GIFT_VIDEO_LIVESTREAM
                        : EventNames.VIDEO_LIVE_STREAM,
                    analyticsData(EventActions.LIVE_STREAM),
                );
            } else {
                logFirebaseEvent(
                    video?.isReceived
                        ? EventNames.GIFT_VIDEO_PLAYED
                        : EventNames.VIDEO_PLAYED,
                    analyticsData(EventActions.PLAY),
                );
            }
            handleRecentlyPlayed();
        }
    };

    const purchaseText = useMemo(() => {
        if (activeGiftItem.giftPurchasedEnabled) {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${+giftItem?.number_of_tokens * activeGiftItem?.quantity ||
                0
                }`
                : `Total: $ ${(
                    +giftItem?.content_price * activeGiftItem?.quantity || 0
                ).toFixed(2)}`;
        } else {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${+video?.number_of_tokens}`
                : `Total: $ ${(+video?.content_price || 0).toFixed(2)}`;
        }
    }, [activeGiftItem, giftItem, paymentMethod]);

    const onBuyGiftContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_VIDEOS,
                };
                redeemGiftByCredits(
                    shortcut?.sku_id,
                    activeGiftItem?.quantity,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete: true,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (video as VideoContent)?.attach_skus[
                                    giftTypeStringToId(
                                        activeGiftItem?.title,
                                    )
                                    ],
                                );
                            } else {
                                fetchGiftProspectQuanity(
                                    (video as VideoContent)?.attach_skus[
                                    giftTypeStringToId(
                                        activeGiftItem?.title,
                                    )
                                    ],
                                );
                            }
                        }
                        getAvailableAudioCredits();
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
        if (paymentMethod === PaymentMethod.CARD) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_VIDEOS,
                };
                purchaseByCard(
                    cart.cartId,
                    activeGiftItem.quantity,
                    shortcut?.sku_id,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete:
                                activeGiftItem?.giftPurchasedEnabled,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (video as VideoContent)?.attach_skus[
                                    giftTypeStringToId(
                                        activeGiftItem?.title,
                                    )
                                    ],
                                );
                            } else {
                                fetchGiftProspectQuanity(
                                    (video as VideoContent)?.attach_skus[
                                    giftTypeStringToId(
                                        activeGiftItem?.title,
                                    )
                                    ],
                                );
                            }
                        }
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
    };

    const onBuyContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: video?.nid,
                contentTitle: video?.title,
                category: EventCategories.VIDEOS,
            };
            redeemByCredits(video?.sku_id, firebaseEvent).then((val) => {
                if (val.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: false,
                    });
                    const toSaveVideos: VideoContent[] = videos.map((d) => {
                        if (d.nid === contentId) {
                            return {
                                ...d,
                                is_purchased: true,
                                isReceived: false,
                                publish_free: "1",
                                content_price: "0.0",
                            };
                        } else {
                            return d;
                        }
                    });
                    setGlobalVideos(toSaveVideos);
                    getAvailableAudioCredits();
                    setPurchaseEnabled(false);
                }
            });
        }
        if (paymentMethod === PaymentMethod.CARD) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: video?.nid,
                contentTitle: video?.title,
                category: EventCategories.VIDEOS,
            };

            purchaseByCard(cart.cartId, 1, video.sku_id, firebaseEvent).then(
                (val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete:
                                activeGiftItem?.giftPurchasedEnabled,
                        });
                        const toSaveVideos: VideoContent[] = videos.map((d) => {
                            if (d.nid === contentId) {
                                return {
                                    ...d,
                                    is_purchased: true,
                                    isReceived: false,
                                    publish_free: "1",
                                    content_price: "0.0",
                                };
                            } else {
                                return d;
                            }
                        });
                        setGlobalVideos(toSaveVideos);
                        setPurchaseEnabled(false);
                    }
                },
            );
        }
    };

    const onBuyConfirm = async () => {
        if (paymentMethod === PaymentMethod.CARD) {
            addToCart(
                activeGiftItem?.giftPurchasedEnabled
                    ? (video as VideoContent)?.attach_skus[
                    giftTypeStringToId(activeGiftItem?.title)
                    ]
                    : video.sku_id,
                activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity
                    : 1,
            ).then((res) => {
                if (res?.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: true,
                    });
                }
            });
        } else {
            setDialogs({
                ...dialogs,
                transactionConfirmation: true,
            });
        }
    };

    const onGiftMenuItemClick = (e, item, skuId) => {
        const selectedGift =
            videoGifts &&
            videoGifts.find(
                (x) => x.sku_id === (video as VideoContent).attach_skus[skuId],
            );
        if (
            videoGifts &&
            videoGifts.filter(
                (x) => x.sku_id === (video as VideoContent).attach_skus[skuId],
            ).length > 0
        ) {
            setGiftItem(selectedGift);
            setPurchaseEnabled(true);
            setActiveGiftItem({
                title: item,
                giftPurchasedEnabled: true,
                giftPurchasedSuccess: false,
                quantity: 1,
            });
            setAnchorEl(null);
        } else {
            enqueueSnackbar(messaging?.common?.noAccess, {
                variant: "warning",
            });
        }
    };

    const shortcutGift = () => {
        const giftSku = (video as VideoContent)?.attach_skus[
            giftTypeStringToId(activeGiftItem.title)
        ];
        return {
            ...videoGifts?.find((x) => x.sku_id === giftSku),
            sku_id: giftSku,
        };
    };

    const onSendSelectedMembers = async () => {
        if (selectedMembers.length >= 1) {
            const shortcut = shortcutGift();

            if (activeGiftItem.title === GiftsTypes.MEMBER) {
                sendGiftToUser(
                    [
                        {
                            nid: shortcut?.nid,
                            title: shortcut?.title,
                            sku: shortcut?.sku_id,
                            category: EventCategories.GIFT_VIDEOS,
                        },
                    ],
                    selectedMembers,
                ).then((val) => {
                    if (val?.response?.status === true) {
                        fetchGiftMemberQuanity(
                            (video as VideoContent).attach_skus[
                            giftTypeStringToId(activeGiftItem.title)
                            ],
                        );
                        onSendItemsClose();
                    }
                });
            }
        } else {
            enqueueSnackbar(messaging?.gifts?.selectAtLeastOneMember, {
                variant: "warning",
            });
        }
    };

    const handleFavouriteClick = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        const firebaseEvent: Partial<FirebaseEventAction> = {
            nId: video?.nid,
            skuId: video?.sku_id,
            contentTitle: video?.title,
            category: video?.isReceived
                ? EventCategories.GIFT_VIDEOS
                : EventCategories.VIDEOS,
        };
        if (isFavoriteVideo) {
            unMarkFavoriteByNid(video?.nid, firebaseEvent)
                .then((res) => {
                    if (res.status && res.key === 0) {
                        let tempArray = [...favoritesVideos];
                        tempArray?.splice(
                            tempArray?.findIndex((x) => x.nid === video.nid),
                            1,
                        );
                        setGlobalFavoritesVideos(tempArray);
                        enqueueSnackbar(messaging.favorites.success, {
                            variant: "success",
                        });
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        } else {
            markFavoriteByNid(video?.nid, firebaseEvent)
                .then((res) => {
                    if (res.status && res.key === 0) {
                        let tempArray = [...favoritesVideos];
                        tempArray.push(video as VideoContent);
                        setGlobalFavoritesVideos(tempArray);

                        enqueueSnackbar(messaging.favorites.success, {
                            variant: "success",
                        });
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        }
    };

    const handleDelete = async (nid: string) => {
        getDexieConnectionRefresh()
            .videos.delete(`${config.user.memberId}-${nid}`)
            .then((res) => {
                setIsReady(false);
                setIsVideoDownloaded(false);
                setAddedToDownloadQueue(false);
                enqueueSnackbar(messaging.download.deleteSuccess, {
                    variant: "success",
                });
                if (downloadedContentNids && downloadedContentNids.length) {
                    const downloadedNids = [...downloadedContentNids];
                    const updatedDownloadedNids = downloadedNids?.filter(
                        (downloadedNid) =>
                            downloadedNid !== `${config.user.memberId}-${nid}`,
                    );
                    dispatch(
                        setDownloadedContentNids({
                            downloadedContentNids: updatedDownloadedNids,
                        }),
                    );
                }
            })
            .catch((err) => {
                enqueueSnackbar(messaging.download.deleteError, {
                    variant: "error",
                });
            });
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const isContentLoaded =
        video &&
        !videosLoading &&
        !receivedMemberVideoGiftsLoading &&
        !receivedProspectVideoGiftsLoading;

    useEffect(() => {
        if (!!isVideoDownloaded) setIsReady(false);
    }, [isVideoDownloaded]);

    useEffect(() => {
        if (!videoURL || !isVideoDownloaded) {
            const contentURL = isVideoDownloaded
                ? downloadedVideoUrl
                : mediaURL;
            setVideoURL(contentURL);
        }
    }, [isVideoDownloaded, mediaURL]);

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleVideoDownload = (video: VideoContent | Gifts) => () => {
        showWarningSnackbarIfRequired();
        setAddedToDownloadQueue(true);
        const downloadingQueueLimit =
            appConstants.downloadingQueue.downloadingQueueLimit;
        const queueLength = getDownloadQueueLength();
        if (queueLength < downloadingQueueLimit) {
            const params: AddToDownloadOptions = {
                media_url_prefix: video.media_url_prefix,
                media_url_postfix: video.media_url_postfix,
                media_file_name: video.media_file_name,
                name: video.title,
                type: video.isReceived ? "giftVideo" : "video",
                nid: video.nid,
                skuId: video.sku_id,
                description: video.description,
                release_date: video.release_date,
            };
            addToDownloadQueue(params);
        } else {
            setQueueLimitReached(true);
            setAddedToDownloadQueue(false);
        }
    };

    const favIconLoading =
        markFavLoading || unMarkFavLoading || favoritesLoading;
    const [isLoop, setLoop] = useState(false);

    const handleNavigateToQueue = () => {
        const payload = {
            lastVisited: `${history.location.pathname}${history.location.search}`,
        };
        setGlobalVideosStack(payload);
        history.push(linkProvider.react.downloadingQueue().index("Video"));
    };

    const toggleQueueLimitReached = () => {
        setQueueLimitReached(!queueLimitReached);
    };

    const { isInsufficeintCredits, isRedeemDisabled } =
        isSufficientCreditAvailable(
            paymentMethod,
            availableCredits,
            activeGiftItem.quantity,
            +giftItem?.number_of_tokens,
        );

    const { downloadStatus, showWarningSnackbarIfRequired } =
        useDownloadingStatus(
            `${video?.media_url_postfix}/${video?.media_file_name}`,
            video?.media_url_prefix,
        );

    const miniplayerOptions = useRef<MiniPlayerOptions>(null);

    useMemo(() => {
        miniplayerOptions.current = {
            mediaUrl: videoURL,
            thumbnail: thumbnail,
            title: video?.title,
            content: video,
            singleLoop: isLoop,
            isPlaying: playing,
            parentLink: getCurrentUrl(),
        };
    }, [videoURL, playing, thumbnail, video, isLoop]);

    const miniPlayerClick = () => {
        const options = miniplayerOptions.current;
        if (options)
            onClickMiniPlayer({
                isAudio: false,
                mediaUrl: options.mediaUrl,
                thumbnail: options.thumbnail,
                title: options.title,
                content: options.content,
                singleLoop: options.singleLoop,
                parentLink: options.parentLink,
            })();
    };

    // this useEffect is for component unmounting
    useEffect(() => {
        return () => {
            if (
                miniplayerOptions.current &&
                miniplayerOptions.current.isPlaying
            )
                miniPlayerClick();
        };
    }, []);

    const playerEnded = () => {
        handleContentCompleted();
        if (isReceivedMemberVideo) logGiftCompleted();
    };
    const onBackClick = () => {
        if (!isMixedContent) {
            if (!homeStack?.isHomeTrack) {
                if (isFavoritesTab) {
                    history.push(
                        linkProvider.react.favorites().favoriteVideosListing(),
                    );
                } else if (categoryId === VideoCategoryTitle.INDEX) {
                    history.push(linkProvider.react.videos().index());
                } else {
                    history.push(
                        linkProvider.react.videos().listing(categoryId),
                    );
                }
            } else {
                history.goBack();
            }
        } else {
            history.push(linkProvider.react.mixedContent().index());
        }
    };

    return (
        <Layout
            onBuyClick={onBuyConfirm}
            method={paymentMethod}
            isPurchasing={purchaseEnabled && !dialogs.transactionComplete}
            purchaseEnabled={purchaseEnabled}
            purchaseNavText={purchaseText}
            activeGiftItem={activeGiftItem}
            availableCredits={availableCredits}
            isBuyDisabled={
                purchaseEnabled && activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity < 1 || isRedeemDisabled
                    : false
            }
            dialogs={dialogs}
        >
            <AppHeader
                title={video?.title}
                canGoBack={
                    purchaseEnabled &&
                        activeGiftItem?.giftPurchasedEnabled &&
                        dialogs?.selectMembers
                        ? false
                        : true
                }
                searchable={false}
                onBackClick={onBackClick}
            />

            <Grid container className={clsx(classes.root, className)} {...rest}>
                {isContentLoaded && (
                    <>
                        <>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    className={classes.greyA100}
                                    fontWeight={"bold"}
                                    variant="h6"
                                >
                                    {video.title}
                                </Typography>
                                <div className={classes.warpperFavAndDownload}>
                                    <IconButton>
                                        {favIconLoading ? (
                                            <AppCircularProgress loaderSize="small" />
                                        ) : (
                                            <FontAwesomeIcon
                                                onClick={handleFavouriteClick}
                                                style={{ fontSize: "22px" }}
                                                icon={[
                                                    isFavoriteVideo
                                                        ? "fas"
                                                        : "far",
                                                    "star",
                                                ]}
                                                className={classes.icon}
                                            />
                                        )}
                                    </IconButton>

                                    {isMediaPlayable &&
                                        !isVideoDownloaded &&
                                        !isInDownloadQueue(video.nid) &&
                                        !addedToDownloadQueue &&
                                        !(inProgressNid === video.nid) && (
                                            <>
                                                {invalidContentSize(
                                                    sizeOfContent,
                                                ) ||
                                                    downloadStatus ===
                                                    DownloadStatus.BLOCK ? (
                                                    <IconButton>
                                                        <DownloadDisabled />
                                                    </IconButton>
                                                ) : (
                                                    <DownloadWarningDialog
                                                        onConfirm={handleVideoDownload(
                                                            video,
                                                        )}
                                                    >
                                                        {(renderProps) => (
                                                            <IconButton
                                                                onClick={
                                                                    renderProps.askConfirmation
                                                                }
                                                            >
                                                                <AppDownloadingIcons iconType="download" />
                                                            </IconButton>
                                                        )}
                                                    </DownloadWarningDialog>
                                                )}
                                            </>
                                        )}
                                    {isVideoDownloaded && (
                                        <IconButton>
                                            <AppDownloadingIcons
                                                iconType="trash"
                                                onClick={
                                                    handleToggleConfirmDialog
                                                }
                                            />
                                        </IconButton>
                                    )}

                                    {!isVideoDownloaded &&
                                        (isInDownloadQueue(video?.nid) ||
                                            addedToDownloadQueue) &&
                                        !(inProgressNid === video?.nid) && (
                                            <IconButton>
                                                <AppDownloadingIcons
                                                    iconType="queued"
                                                    onClick={
                                                        handleNavigateToQueue
                                                    }
                                                />
                                            </IconButton>
                                        )}
                                    {inProgressNid === video.nid &&
                                        !isVideoDownloaded && (
                                            <IconButton>
                                                <AppDownloadingIcons
                                                    iconType="in-progress"
                                                    onClick={
                                                        handleNavigateToQueue
                                                    }
                                                />
                                            </IconButton>
                                        )}
                                    {isOpenConfirmDailog && (
                                        <AppConfimationDialog
                                            open={isOpenConfirmDailog}
                                            onConfirm={() =>
                                                handleDelete(video.nid)
                                            }
                                            btnConfirmLoader={deleteLoader}
                                            onClose={handleToggleConfirmDialog}
                                            popupMainText="Are you sure you want to delete video?"
                                            confirmText="Delete"
                                            cancelButtonText="Cancel"
                                            showConfirmFirst={true}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid
                                item
                                className={classes.grey500}
                                xs={12}
                                display="flex"
                            >
                                {!video.isReceived &&
                                    !video.is_purchased &&
                                    (video.is_redeemable_by_tokens === "1" ||
                                        video?.publish_free === "0" ||
                                        video?.publish_free === "false") && (
                                        <>
                                            {!video.is_purchased &&
                                                video.is_redeemable_by_tokens ===
                                                "1" && (
                                                    <>
                                                        <Typography
                                                            className={
                                                                classes.constMargin
                                                            }
                                                            fontWeight={"bold"}
                                                            variant="body1"
                                                        >
                                                            {`${video.number_of_tokens
                                                                } Credit${+video?.number_of_tokens >
                                                                    1
                                                                    ? "s"
                                                                    : ""
                                                                }`}
                                                        </Typography>
                                                        {(video.publish_free ===
                                                            "0" ||
                                                            video.publish_free ==
                                                            "false") && (
                                                                <>
                                                                    <Typography variant="body1">
                                                                        |
                                                                    </Typography>
                                                                    &nbsp;&nbsp;
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            {!video.is_purchased &&
                                                +video.content_price > 0 &&
                                                (video.publish_free === "0" ||
                                                    video.publish_free ==
                                                    "false") && (
                                                    <Typography
                                                        className={
                                                            classes.constMargin
                                                        }
                                                        fontWeight={"bold"}
                                                        variant="body1"
                                                    >
                                                        {`$${parseFloat(
                                                            video.content_price,
                                                        ).toFixed(2)}`}
                                                    </Typography>
                                                )}
                                        </>
                                    )}
                                <Typography variant="body1">
                                    {video.sku_id}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={classes.playerContainer}
                            >
                                {isMediaPlayable &&
                                    mediaURL &&
                                    mediaURL?.length > 0 ? (
                                    <MediaPlayer
                                        title={video?.title}
                                        mediaURL={videoURL}
                                        imageURL={thumbnail}
                                        onStart={handleVideoStartedPlaying}
                                        onUpdateExpiredUrl={() =>
                                            setIsReady(false)
                                        }
                                        isAudio={false}
                                        playerRef={playerRef}
                                        progressInterval={
                                            PLAYER_PROGRESS_INTERVAL
                                        }
                                        onReady={onMediaPlayerReady}
                                        onProgress={onPlayerProgress}
                                        loop={isLoop}
                                        onEnded={playerEnded}
                                        playing={
                                            isAutoPlay === "true" &&
                                            currentPlayingState &&
                                            !miniPlayer.url
                                        }
                                        onPlay={handlePlayerPlay}
                                        onPause={handlePlayerPause}
                                    />
                                ) : thumbnail && thumbnail?.length > 0 ? (
                                    <img
                                        alt={video?.title}
                                        src={thumbnail}
                                        className={classes.mediaImage}
                                    />
                                ) : (
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                        className={classes.dummyImageBox}
                                    >
                                        <span
                                            className={classes.graphicWrapper}
                                        >
                                            <GraphicEqIcon
                                                className={classes.graphicIcon}
                                                sx={{
                                                    fontSize: "60px",
                                                }}
                                            />
                                        </span>
                                    </Box>
                                )}
                            </Grid>
                        </>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={miniPlayer.url ? "end" : "center"}
                        >
                            {video && isMediaPlayable && (
                                <Box display="flex" justifyContent="center">
                                    <PlayerActionBtns
                                        handleChangeSeek={handleChangeSeek}
                                        isLoop={isLoop}
                                        onClickLoop={() => setLoop(!isLoop)}
                                        onClickMiniPlayer={miniPlayerClick}
                                    />
                                </Box>
                            )}
                            <RenderIf
                                isTrue={
                                    video &&
                                    !purchaseEnabled &&
                                    !video.isReceived &&
                                    !video.is_purchased &&
                                    (video.publish_free === "0" ||
                                        video.publish_free === "false")
                                }
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <AppButton
                                        onClick={() => setPurchaseEnabled(true)}
                                        buttonVariant="grey-outlined"
                                        rounded
                                    >
                                        BUY
                                    </AppButton>
                                </Grid>
                            </RenderIf>

                            {video &&
                                !video.isReceived &&
                                Object.keys(
                                    (video as VideoContent)?.attach_skus,
                                ).length > 0 && (
                                    <Box className={classes.controlIcons}>
                                        <Grid
                                            item
                                            className={
                                                classes.giftSectionWarpper
                                            }
                                        >
                                            {(loadingGiftMemeberQuanity ||
                                                loadingGiftProspectQuanity ||
                                                videoGiftsLoading) && (
                                                    <AppCircularProgress loaderSize="small" />
                                                )}
                                            {(!loadingGiftMemeberQuanity &&
                                                !loadingGiftProspectQuanity &&
                                                !videoGiftsLoading &&
                                                giftMemberQuanity > 0) ||
                                                (true && (
                                                    <>
                                                        <IconButton
                                                            onClick={(e) =>
                                                                setAnchorSendGift(
                                                                    e.currentTarget,
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                className={
                                                                    classes.icon
                                                                }
                                                                icon={[
                                                                    "far",
                                                                    "paper-plane",
                                                                ]}
                                                            />
                                                        </IconButton>
                                                        <AppMenuListing
                                                            id="gift-type-send-videos"
                                                            anchorEl={
                                                                anchorSendGift
                                                            }
                                                            open={openSendGift}
                                                            onClose={() =>
                                                                setAnchorSendGift(
                                                                    null,
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                (
                                                                    video as VideoContent
                                                                )?.attach_skus,
                                                            ).map(
                                                                (
                                                                    filter,
                                                                    index,
                                                                ) =>
                                                                    giftTypeIdToString(
                                                                        filter,
                                                                    ) ===
                                                                    GiftsTypes.MEMBER && (
                                                                        <MenuList
                                                                            key={`${filter}`}
                                                                        >
                                                                            {index !==
                                                                                0 && (
                                                                                    <AppDivider />
                                                                                )}
                                                                            <MenuItem
                                                                                onClick={(
                                                                                    e,
                                                                                ) =>
                                                                                    onGiftSendMenuItem(
                                                                                        e,
                                                                                        giftTypeIdToString(
                                                                                            filter,
                                                                                        ),
                                                                                        filter,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ListItemText>
                                                                                    {giftTypeIdToString(
                                                                                        filter,
                                                                                    )}
                                                                                </ListItemText>
                                                                                &nbsp;&nbsp;
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {!loadingGiftMemeberQuanity &&
                                                                                        !loadingGiftProspectQuanity &&
                                                                                        !videoGiftsLoading && (
                                                                                            <AppTypography
                                                                                                variant="body2"
                                                                                                className={
                                                                                                    classes.giftQuanityWrapper
                                                                                                }
                                                                                            >
                                                                                                {giftTypeIdToString(
                                                                                                    filter,
                                                                                                ) ===
                                                                                                    GiftsTypes.MEMBER
                                                                                                    ? giftMemberQuanity
                                                                                                    : giftProspectQuanity}
                                                                                            </AppTypography>
                                                                                        )}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    ),
                                                            )}
                                                        </AppMenuListing>
                                                    </>
                                                ))}
                                            <IconButton
                                                className={
                                                    (loadingGiftMemeberQuanity ||
                                                        loadingGiftProspectQuanity ||
                                                        videoGiftsLoading) &&
                                                    classes.giftIconButtonDisabled
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        loadingGiftMemeberQuanity ||
                                                        loadingGiftProspectQuanity ||
                                                        videoGiftsLoading
                                                    ) {
                                                        enqueueSnackbar(
                                                            messaging?.gifts
                                                                ?.fetchingGiftInfo,
                                                            {
                                                                variant:
                                                                    "warning",
                                                            },
                                                        );
                                                    } else {
                                                        setAnchorEl(
                                                            e.currentTarget,
                                                        );
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className={classes.icon}
                                                    icon={["fas", "gift"]}
                                                />
                                            </IconButton>
                                            <AppMenuListing
                                                id="gift-type-menu-videos"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={() =>
                                                    setAnchorEl(null)
                                                }
                                            >
                                                {Object.keys(
                                                    (video as VideoContent)
                                                        ?.attach_skus,
                                                ).map((filter, index) => (
                                                    <MenuList key={`${filter}`}>
                                                        {index !== 0 && (
                                                            <AppDivider />
                                                        )}
                                                        <MenuItem
                                                            onClick={(e) =>
                                                                onGiftMenuItemClick(
                                                                    e,
                                                                    giftTypeIdToString(
                                                                        filter,
                                                                    ),
                                                                    filter,
                                                                )
                                                            }
                                                        >
                                                            <ListItemText>
                                                                {giftTypeIdToString(
                                                                    filter,
                                                                )}
                                                            </ListItemText>
                                                            &nbsp;&nbsp;
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {!loadingGiftMemeberQuanity &&
                                                                    !loadingGiftProspectQuanity &&
                                                                    !videoGiftsLoading && (
                                                                        <AppTypography
                                                                            variant="body2"
                                                                            className={
                                                                                classes.giftQuanityWrapper
                                                                            }
                                                                        >
                                                                            {giftTypeIdToString(
                                                                                filter,
                                                                            ) ===
                                                                                GiftsTypes.MEMBER
                                                                                ? giftMemberQuanity
                                                                                : giftProspectQuanity}
                                                                        </AppTypography>
                                                                    )}
                                                            </Typography>
                                                        </MenuItem>
                                                    </MenuList>
                                                ))}
                                            </AppMenuListing>
                                        </Grid>
                                    </Box>
                                )}
                        </Grid>
                        {video && !purchaseEnabled && (
                            <>
                                <Grid item xs={12}>
                                    <Typography
                                        className={clsx(
                                            classes.grey500,
                                            classes.date,
                                        )}
                                        variant="body2"
                                    >
                                        {moment(
                                            +video?.release_date * 1000,
                                        ).format("LL")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.decriptionOverflow}
                                >
                                    <Typography
                                        className={classes.grey800}
                                        variant="body2"
                                    >
                                        {video?.description}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {purchaseEnabled && !dialogs?.selectMembers && (
                            <ContentPurchase
                                marginBottom={"30px"}
                                onBackClick={() => {
                                    setPurchaseEnabled(false);
                                    setActiveGiftItem({
                                        title: "",
                                        giftPurchasedEnabled: false,
                                        giftPurchasedSuccess: false,
                                        quantity: 1,
                                    });
                                }}
                                buying={
                                    purchasingContent ||
                                    redeemingContent ||
                                    redeemingGiftContent
                                }
                                cart={cart}
                                backTitle={
                                    activeGiftItem?.giftPurchasedEnabled
                                        ? activeGiftItem?.title
                                        : "Video Purchase"
                                }
                                credits={availableCredits}
                                availableCreditsLoading={
                                    availableCreditsLoading
                                }
                                requiredCredits={video?.number_of_tokens}
                                method={paymentMethod}
                                activeGiftItem={activeGiftItem}
                                onSendContent={onSendContent}
                                selectedMembers={selectedMembers}
                                totalPrice={`$${parseFloat(
                                    video?.content_price,
                                ).toFixed(2)}`}
                                contentPrice={
                                    activeGiftItem?.giftPurchasedEnabled
                                        ? giftItem?.content_price
                                        : video?.content_price
                                }
                                onMethodChange={(method) => {
                                    if (
                                        method === PaymentMethod.MY_CREDITS &&
                                        video.is_redeemable_by_tokens === "1"
                                    ) {
                                        setPaymentMethod(method);
                                    } else {
                                        if (
                                            method === PaymentMethod.MY_CREDITS
                                        ) {
                                            enqueueSnackbar(
                                                messaging?.redeeming
                                                    ?.notRedeemable,
                                                { variant: "warning" },
                                            );
                                        }
                                    }

                                    if (method === PaymentMethod.CARD) {
                                        setPaymentMethod(method);
                                    }
                                }}
                                dialogs={dialogs}
                                setDialogs={setDialogs}
                                onBuy={onBuyContent}
                                onBuyGift={onBuyGiftContent}
                                isRedemeableByToken={
                                    video.is_redeemable_by_tokens
                                }
                                onCloseConfirmDialog={onCloseSelectMembers}
                                quantityLoading={
                                    loadingGiftMemeberQuanity ||
                                    loadingGiftProspectQuanity
                                }
                            />
                        )}
                        {purchaseEnabled &&
                            activeGiftItem?.giftPurchasedEnabled &&
                            !dialogs?.selectMembers && (
                                <GiftQuantity
                                    activeGiftItem={activeGiftItem}
                                    onQuantityChange={onQuantityChange}
                                    method={paymentMethod}
                                    credits={availableCredits}
                                    isInsufficeintCredits={
                                        isInsufficeintCredits
                                    }
                                />
                            )}
                        {purchaseEnabled &&
                            activeGiftItem?.giftPurchasedEnabled &&
                            dialogs?.selectMembers && (
                                <SelectMembersDialog
                                    open={true}
                                    searchable={true}
                                    searchPlaceholder="Search by name or LTD ID"
                                    searchQuery={searchQueryLos}
                                    setSearchQuery={setSearchQueryLos}
                                    selectedMembers={selectedMembers}
                                    checkboxList={searchLosResults}
                                    onToggleItemSelect={onToggleItemSelect}
                                    onClose={onCloseSelectMembers}
                                    onSend={onSendSelectedMembers}
                                    giftSendLoading={sendingGifts}
                                    availableQuantity={
                                        activeGiftItem?.quantity -
                                            selectedMembers?.length >
                                            0
                                            ? activeGiftItem?.quantity -
                                            selectedMembers?.length
                                            : 0
                                    }
                                />
                            )}
                        {queueLimitReached && (
                            <AppConfimationDialog
                                titleText={
                                    messaging?.downloadingQueue?.QueueLimitation
                                }
                                showTitle={true}
                                open={queueLimitReached}
                                popupMainText={
                                    messaging?.downloadingQueue?.limitReached
                                }
                                cancelButtonText="OK"
                                onClose={toggleQueueLimitReached}
                            />
                        )}
                    </>
                )}
                {!isContentLoaded && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.video}
                    />
                )}
                {favIconLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.processing}
                    />
                )}
            </Grid>
        </Layout>
    );
};

const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { isMixedContent = false } = parsedQueryString;

        return {
            isMixedContent: !!isMixedContent ? true : false,
        };
    }, [location?.search]);

    return queryParams;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        decriptionOverflow: {
            overflowWrap: "break-word",
        },
        warpperFavAndDownload: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);

export interface VideoContentDetailProps
    extends InternalStandardProps<GridProps> { }
