import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { messaging } from "JS/Helpers/UserMessaging";
import { MediaEssentials } from "JS/Models/MediaEssentials";

export const initialState: MediaEssentials = {
    allow_rule_engine_requests: true,
    allow_seamless_update_react: true,
    has_event_started: false,
    download_warning_count: 1,
    download_warning_msg: messaging.mediaEssentials.downloadWarningMsg,
    download_warning_cleared_on_logout: true,
    allow_fetch_team_status: true,
    allow_speaker_feature: false,
    allow_consent_form_feature: true,
    access_key_react: "",
    secret_key_react: "",
    player_progress_interval_courses: 2000,
};

export const mediaEssentialsStateSlice = createSlice({
    name: "mediaEssentialsState",
    initialState,
    reducers: {
        setGlobalMediaEssentials: (
            state,
            data: PayloadAction<MediaEssentials>,
        ) => {
            state.allow_rule_engine_requests =
                data.payload?.allow_rule_engine_requests;
            state.allow_seamless_update_react =
                data.payload?.allow_seamless_update_react;
            state.has_event_started = data.payload?.has_event_started;
            state.download_warning_count = data.payload?.download_warning_count;
            state.download_warning_msg = data.payload?.download_warning_msg;
            state.download_warning_cleared_on_logout =
                data.payload?.download_warning_cleared_on_logout;
            state.allow_fetch_team_status =
                data.payload?.allow_fetch_team_status;
            state.allow_speaker_feature = data.payload?.allow_speaker_feature;
            state.player_progress_interval_courses =
                data.payload?.player_progress_interval_courses;
            state.missing_consent_member_ids =
                data.payload?.missing_consent_member_ids;
            return state;
        },
        resetMediaEssentials: () => {
            return initialState;
        },
    },
});
export const { setGlobalMediaEssentials, resetMediaEssentials } =
    mediaEssentialsStateSlice.actions;
