import { useHistory } from "react-router-dom";
import { Grid, InternalStandardProps, Theme } from "@mui/material";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { messaging } from "JS/Helpers/UserMessaging";
import { Course, DownloadedCourseDetail } from "JS/Models";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { AppNoDataFound } from "JS/React/Components/AppNoDataFound";
import { useRouting } from "JS/React/Hooks/Routes";
import { useScrollToTop } from "JS/React/Hooks/Media";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    wrapperLoader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: `calc(100vh - (${theme.footer.height} + ${theme.header.height}))`,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.black,
    },
    emptyList: {
        marginTop: theme.spacing(3),
        color: theme.palette.grey[500],
    },
}));

export interface OfflineCourseListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    downloadedCourses: (Course | DownloadedCourseDetail)[];
}

export const OfflineCourseList = (props: OfflineCourseListProps) => {
    const classes = useStyles(props);
    const { className, downloadedCourses: content, ...rest } = props;

    const { linkProvider } = useRouting();
    const history = useHistory();

    useScrollToTop();

    const isDownloadedCourse = (course: Course | DownloadedCourseDetail) =>
        !!(course as DownloadedCourseDetail)?.steps;

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {content && content.length > 0 ? (
                content.map((c, idx) => (
                    <AppContentListItem
                        nid={c.nid}
                        key={idx}
                        type="media"
                        sender={(c as any).sender}
                        title={c.title}
                        skuId={c.sku_id}
                        description={c.description}
                        imageUrl={""}
                        releaseDate={(
                            (c as DownloadedCourseDetail)?.releaseDate ||
                            (c as Course)?.release_date
                        )?.toString()}
                        isAllowClick
                        onListItemClick={() => {
                            isDownloadedCourse(c) &&
                                history.push(
                                    linkProvider.react
                                        .offline()
                                        .courses()
                                        .detail(c.nid),
                                );
                        }}
                        disabled={!isDownloadedCourse(c)}
                    />
                ))
            ) : (
                <Grid className={classes.wrapperLoader} item xs={12} md={12}>
                    <AppNoDataFound
                        notFoundText={messaging?.common?.noDataFound}
                    />
                </Grid>
            )}
        </div>
    );
};
