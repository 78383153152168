import {
    Autocomplete,
    AutocompleteProps,
    Box,
    BoxProps,
    Chip,
    InternalStandardProps,
    TextField,
} from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { messaging } from "JS/Helpers/UserMessaging";
import { ContentSpeakerName } from "JS/Redux/Attributes";
import React, { useEffect, useMemo, useRef } from "react";
import { isAndroid } from "react-device-detect";
import { Theme } from "@mui/material/styles/createTheme";

export const AppMultiTagSelect = React.memo(
    (props: AppMultiSelectSpeakerProps) => {
        const classes = useStyles(props);
        const {
            className,
            value,
            optionList,
            optionKey,
            handleChange,
            placeholder,
            label,
            handleKeyPress,
            ...rest
        } = props;

        const theme = useTheme<Theme>();

        const { startAdornmentRef, startAdorWarpperRef } =
            useMultiSelectHandlers(props);

        const memorizedOptions = useMemo(
            () => optionList.map((option) => option[optionKey]),
            [optionList, optionKey],
        );

        return (
            <Box className={classes.root} {...rest}>
                <Autocomplete
                    filterSelectedOptions
                    disableClearable={true}
                    popupIcon={""}
                    noOptionsText={messaging?.speaker?.noOptionText}
                    className={classes.dropdown}
                    value={value}
                    onChange={handleChange}
                    multiple
                    id="tags-filled"
                    options={memorizedOptions}
                    filterOptions={(options, state) => {
                        if (state?.inputValue?.length > 1) {
                            return options?.filter((item) =>
                                String(item)
                                    ?.toLowerCase()
                                    ?.includes(
                                        state?.inputValue?.toLowerCase(),
                                    ),
                            );
                        }
                        return options;
                    }}
                    renderTags={(value: string[], getTagProps) => {
                        return value.map((option: string, index: number) => (
                            <>
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                                {value.length - 1 === index && (
                                    <div ref={startAdornmentRef}></div>
                                )}
                            </>
                        ));
                    }}
                    renderInput={(params) => {
                        const { InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } =
                            InputProps;
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={placeholder}
                                InputProps={{
                                    ...restInputProps,
                                    startAdornment: (
                                        <div
                                            className={classes.inputStartAdor}
                                            ref={startAdorWarpperRef}
                                        >
                                            {startAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        );
                    }}
                    onKeyDown={handleKeyPress}
                    componentsProps={{
                        paper: {
                            sx: {
                                bgcolor: theme?.palette?.background?.paper,
                                color: theme?.palette?.grey[600],
                            },
                        },
                        popper: {
                            sx: {
                                bgcolor: theme?.palette?.background?.paper,
                                color: theme?.palette?.grey[600],
                            },
                        },
                    }}
                    classes={{
                        noOptions: classes.noOptionsPopper,
                    }}
                />
            </Box>
        );
    },
);

export default AppMultiTagSelect;

const useMultiSelectHandlers = (props: AppMultiSelectSpeakerProps) => {
    const { value } = props;

    const startAdornmentRef = useRef(null);
    const startAdorWarpperRef = useRef(null);

    useEffect(() => {
        if (isAndroid) {
            startAdorWarpperRef?.current?.scrollTo(
                0,
                startAdorWarpperRef?.current?.scrollHeight,
            );
        } else {
            startAdornmentRef?.current?.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [value]);

    return {
        startAdornmentRef,
        startAdorWarpperRef,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(3),
            },
        },
        dropdown: {
            "& .MuiInputLabel-root": {
                color: `${theme.palette.common.black}`,
                paddingRight: "0 !important",
            },
            "&.Mui-focused .MuiInputLabel-standard": {
                color: `${theme.palette.primary.main} !important`,
            },
            "& .MuiInputLabel .Mui-focused": {
                color: `${theme.palette.primary.main} !important`,
            },
            "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
                fontSize: "16px",
                color: `${theme.palette.common.black}`,
                fontWeight: 500,
                "&::before": {
                    borderBottomColor: `${theme.palette.grey[600]} !important`,
                    color: `${theme.palette.common.black} !important`,
                },
                "&::after": {
                    borderBottomColor: `${theme.colors.textField.focus} !important`,
                    color: `${theme.palette.primary.main} !important`,
                },
            },
            "& .MuiAutocomplete-endAdornment": {
                right: "0px !important",
            },
            "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                color: `${theme.palette.grey[600]} !important`,
            },

            //Chip styling
            "& .MuiChip-root": {
                backgroundColor:
                    theme?.palette?.mode === "light"
                        ? `${theme.palette.grey[200]} !important`
                        : `${theme.palette.grey[900]} !important`,
            },
            "& .MuiChip-root MuiChip-outlined": {
                borderColor: `${theme.palette.common.black} !important`,
            },
            "& .MuiChip-label": {
                color: `${theme.palette.grey[600]} !important`,
            },
            "& .MuiChip-deleteIcon": {
                color: `${theme.palette.grey[600]} !important`,
            },
        },

        noOptionsPopper: {
            backgroundColor: `${theme.palette.background.paper} !important`,
            color: `${theme.palette.grey[600]} !important`,
        },
        inputStartAdor: {
            maxHeight: "20vh",
            overflowY: "auto",
        },
    }),
);

export type MultiAutocompleteProps = AutocompleteProps<
    any,
    true,
    undefined,
    undefined,
    "div"
>;

interface AppMultiSelectSpeakerProps extends InternalStandardProps<BoxProps> {
    value: string[];
    handleChange: MultiAutocompleteProps["onChange"];
    optionList: ContentSpeakerName[];
    optionKey: string;
    placeholder: string;
    label: string;
    handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}
