import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, IconButtonProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { setMiniPlayerInLocalStorage } from "JS/Helpers/MiniPlayerHelper";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import clsx from "clsx";
import { useState } from "react";
import { AppCircularProgress } from "../Progress/AppCircularProgress";

export const RefreshButton = (props: RefreshButtonProps) => {
    const classes = useStyles(props)();

    const { isMini } = props;
    const { miniPlayer } = useMiniPlayer();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefreshClick = () => {
        setIsRefreshing(true);
        if (miniPlayer && miniPlayer.url) {
            setMiniPlayerInLocalStorage(miniPlayer);
        }

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    return (
        <IconButton
            onClick={handleRefreshClick}
            className={clsx(!isMini && classes.positioning)}
        >
            {isRefreshing ? (
                <AppCircularProgress loaderSize="small" />
            ) : (
                <FontAwesomeIcon
                    className={clsx(classes.icon)}
                    id="expand"
                    icon={["fas", "rotate-right"]}
                />
            )}
        </IconButton>
    );

    // return (
    //     <AskConfirmationDialog
    //         popupMainText={"Are you sure you want to refresh the App?"}
    //         confirmText={"Yes"}
    //         cancelButtonText={"Cancel"}
    //         onConfirm={handleRefreshClick}
    //     >
    //         {(renderProps) => (
    //             <IconButton
    //                 onClick={renderProps.askConfirmation}
    //                 className={clsx(!isMini && classes.positioning)}
    //             >
    //                 <FontAwesomeIcon
    //                     className={clsx(classes.icon)}
    //                     id="expand"
    //                     icon={["fas", "rotate-right"]}
    //                 />
    //             </IconButton>
    //         )}
    //     </AskConfirmationDialog>
    // );
};

const useStyles = (props) =>
    makeStyles((theme: Theme) =>
        createStyles({
            positioning: {
                position: "absolute",
                top: 10,
                right: 10,
                background: "rgba(0,0,0,0.5) !important",
            },

            icon: {
                fontSize: "1.1rem",
                color: theme.palette.common.black,
            },
        }),
    );

export interface RefreshButtonProps extends IconButtonProps {
    isMini?: boolean;
}
