export const messaging = {
    common: {
        noAccess: "You do not have access to this Gift SKU.",
        noDataFound: "No Data Found",
        noItemsFound: "There are no items to be shown.",
        featureComingSoon: "This feature is coming soon.",
        notAllowedToLink: "You are not allowed to access this link",
        error: "Something went wrong.",
        okay: "okay",
    },
    gifts: {
        fetchingGiftInfo: "Fetching gift infomation.",
        inSufficientGiftQuantity: "Insufficient Gift Quantity.",
        selectAtLeastOneMember: "Please select at-least one LOS Member.",
        successMsg: (filesCount: number) =>
            `${filesCount} files were sent to selected LOS.`,
        successTitle: "Send Complete",
    },
    redeeming: {
        notRedeemable: "Content is not redeemable with credits.",
    },
    purchasing: {},
    events: {
        fetchingRecords: "Fetching records...",
        uploadingPhoto: "Uploading...",
        uploadedsuccesfully: "Uploaded succesfully.",
        uploadPhotoToViewTicket:
            "Please upload your photo to view this ticket.",
        disclaimer:
            "Disclaimer: Registrants are responsible for adhering to the Event Discloures displayed during registration, on their print view registration located within their LTD profile and located",
        selectValidFormat: "In-Valid Image Format",
        camaraError: "Please allow camera permissions",
    },

    pagination: { loadMore: "Loading More content" },
    loader: {
        loadingSession: `Loading`,
        audios: `Fetching Audios`,
        videos: `Fetching Videos`,
        recommendations: `Fetching Recommmendations`,
        favorites: `Fetching Favorites`,
        webcast: `Fetching Webcast`,
        tickets: `Fetching Tickets`,
        courses: `Fetching Courses`,
        home: `Loading Home`,
        gift: `Fetching Gifts`,
        content: `Loading Content`,
        course: `Loading Course`,
        video: `Loading Video`,
        audio: `Loading Audio`,
        playlist: `Loading Playlists`,
        create_playlist: `Creating Playlist`,
        fetch_playlist: `Loading Playlist Content`,
        add_to_playlist: "Adding to playlist",
        processing: "Processing...",
        documents: "Fetching Documents",
        document: "Fetching Document",
        data: "Fetching Data",
        addingFilesToQueue: "Adding files to the download queue",
        addingFileToQueue: "Adding file to the download queue",
        markingAttendance: "Marking Attendance",
        fectchingSpeakers: "Fetching speakers",
    },
    seamlessUpdate: {
        versionUpdated:
            "App is getting update. Please wait till it gets refreshed.",
    },
    download: {
        deleteError: "Something gone wrong please try again to delete content.",
        deleteSuccess: "Content deleted successfuly.",
        courseSizeInvalid:
            "This course has content that is too large to download.",
    },
    downloadingQueue: {
        deleteError: "Content is already processed by the download queue.",
        transitionError: "Unable to remove item from queue. Please try again.",
        success: "Downloading queue updated successfully.",
        downloading: "Downloading...",
        Queued: "Queued",
        deleteConfirmation: "Are you sure you want to cancel this download?",
        QueueLimitation: "Queue Limitation",
        limitReached:
            "Cannot add more downloads. Maximum download limit reached. Please wait to complete a download or delete some items from the download queue.",
    },
    favorites: {
        success: "Successfully Updated.",
        error: "Something went wrong.",
    },
    apiError: {
        network: "Network Error",
        somethingWentWrong: "Something Went Wrong",
    },
    dialogs: {
        recRedeemFail:
            "You do not have enough credits to redeem this content.\nWould you like to purchase it?",
        recRedeemFailed:
            "You do not have enough credits to redeem this content.",
    },
    attachSku: {
        offline:
            "Signal strength low or no connectivity. Please try again when network coverage is better.",
    },
    confirmation: {
        courseDelete:
            "Are you sure you want to delete the downloaded content in this course?",
        courseDownload:
            "Are you sure you want to download all content in this course?",
    },
    storageQuota: {
        warning:
            "Your phone is running on low storage, please free up some space for seamless downloading experience.",
        downloadError:
            "There is no more space for downloading content. Download queue has been cleared. Please free up some space to download more content.",
    },
    mediaEssentials: {
        downloadWarningMsg:
            "IMPT: 2GB minimum available device memory is required to download audios/videos/courses from this app.  Insufficient space may cause new downloads to overwrite previously downloaded content within the app.",
    },
    playlist: {
        emptyError: "This playlist does not contain any audios",
        emptyContent: "There are no audios in this playlist.",
        created: "Playlist created successfully.",
        error: "Something went wrong.",
        emptyInput:
            "Sorry, there must be some letters (a-z), numbers (0-9) in playlist name.",
        characterInput: "The playlist title must not exceed 45 characters.",
        requiredOneContent:
            "To create a playlist, at least one free or purchased content is mandatory.",
        createPlaylistInfo:
            "Only content available for free or purchased is available to add to the playlist.",
        noDownload: "This playlist does not contain any downloaded audios.",
        syncAudios:
            "Please sync audios from audios tab in online mode before opening this playlist.",
    },
    miniPlayer: {
        coursePrompt:
            "You need to close the miniplayer to play audios/videos from the course.",
    },
    notification: {
        permission: {
            prompt: "Allow LTD Media to send you notifications?",
            promptYes: "Allow",
            promptNo: "Don't Allow",
        },
    },
    logout: {
        failed: "Logout Failed. Please try again.",
    },
    speaker: {
        emptyContent: "There are no speaker available",
        noOptionText: "No Result(s) Found",
    },
    requireAuth: "Please go online and log in to view the content.",
    networkMode: {
        goingOffline:
            'Please check your network connection or Click "Go Offline" to switching to offline mode.',
        goOffline: "Go Offline",
    },
    giftDashboard: {
        fetching: "Fetching Dashboard Data",
        pushSetFailed: "Something went wrong. Please try again.",
        pushSetSuccess: "Successfully updated.",
    },
    subscription: {
        errorTitle: "Upgrade Subscription",
    },
    consentForm: {
        ssoRedirection:
            "Please verify your IBO Number, and provide consent for LTD and Amway to share data.",
        ltdConsentText: {
            header: "LTD Consent",
            subHeader:
                'By clicking on the box below, you are agreeing to allow Leadership Team Development Inc. ("LTD") to share your personal and business information with Amway Corporation ("Amway").',
            text: 'I authorize LTD to provide the following information about me to Amway to enable LTD and Amway to work with me, and for LTD to provide high-quality support to me in service of building my Amway business. The information LTD provides to Amway will enable Amway to track my activity with LTD, including whether and to what extent I have purchased Business Support Materials ("BSM") from LTD or an IBO affiliated with LTD. <br /> <ol>   <li>     Contact information (e.g., name, postal or e-mail address, fax number and     phone number);   </li>   <li>     IBO organizational information (e.g., IBO company name, profession and     ownership and management details);   </li>   <li>IBO registration number;</li>   <li>LTD Registration number (if applicable)</li> </ol> <br /> This consent will remain valid until I notify LTD that it is revoked. By clicking I agree, I am consenting for all parties named on my LTD Membership account. ',
        },
    },
};
