import {
    Box,
    Dialog,
    DialogProps,
    InternalStandardProps,
    Slide,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { clsx } from "clsx";
import { useHistory } from "react-router-dom";
import { useConsentForm, useGetConsentText } from "JS/React/Hooks/ConsentForm";
import { AppHeader } from "JS/React/Components/AppHeader";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { AppButton } from "JS/React/Components/AppButton";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { EventActions, EventNames } from "JS/Models";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    resetRedirectionState,
    setLoadingState,
    setRedirectionState,
} from "JS/Redux/ConsentForm";
import { config } from "JS/Config";
import { ConsentStatusService } from "JS/Services/ConsentForm/ConsentStatusService";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { useSnackbar } from "notistack";
import { ConsentFormDexie } from "JS/Database/Dexie";
import { setConsentStatusById } from "JS/Database/ConsentForm";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ConsentFormProps extends InternalStandardProps<DialogProps> {}

export const ConsentForm = (props: ConsentFormProps) => {
    const classes = useStyles(props);
    const { header, subHeader, text, loading, consentGiven, dismiss } =
        useHandlers();
    return (
        <Dialog
            scroll="body"
            fullScreen
            open={true}
            TransitionComponent={Transition}
        >
            {loading && (
                <AppBackdropProgress
                    backdropText={messaging.loader.loadingSession}
                    open={true}
                />
            )}
            <Box className={classes.root}>
                <AppHeader
                    title={header}
                    onBackClick={dismiss}
                    canGoBack={true}
                    searchable={false}
                />
                <AppTypography className={classes.text}>
                    {subHeader}
                </AppTypography>
                <Box
                    className={clsx(classes.textBox, classes.text)}
                    height={"70vh"}
                >
                    <AppTypography>{text}</AppTypography>
                </Box>
                <Box className={classes.buttonHolder}>
                    <AppButton
                        className={classes.button}
                        variant="contained"
                        buttonVariant="primary-filled"
                        onClick={consentGiven}
                    >
                        I Agree
                    </AppButton>
                    <AppButton
                        className={clsx(classes.button, classes.dismissButton)}
                        variant="contained"
                        onClick={dismiss}
                    >
                        Dismiss
                    </AppButton>
                </Box>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            height: "100vh",
        },
        text: {
            color: theme.palette.grey[600],
            padding: "0 15px",
        },
        textBox: {
            overflow: "auto",
            marginTop: "15px",
            border: `1px solid ${theme.palette.grey[600]}`,
            margin: "0 15px",
        },
        buttonHolder: {
            position: "absolute",
            bottom: "20",
            width: "100%",
            display: "flex",
        },
        button: {
            width: "50%",
            margin: "10px",
        },
        dismissButton: {
            background: theme.palette.grey[200],
            color: "black",
            "&:hover": {
                background: theme.palette.grey[400],
                color: "black",
            },
        },
    }),
);

const useHandlers = () => {
    const { loading: postLoading, success, consentGiven } = useConsentForm();
    const {
        header,
        subHeader,
        text,
        loading: textLoading,
    } = useGetConsentText();
    const history = useHistory();
    const { logFirebaseEvent } = useFirebaseLogger();
    const dispatch = useAppDispatch();
    const showRedirectionDialog = useAppSelector(
        (state) => state.consentForm.showRedirectionDialog,
    );
    const { enqueueSnackbar } = useSnackbar();

    const dismiss = () => {
        logFirebaseEvent(EventNames.CONSENT_FORM_DISMISSED, {
            action: EventActions.CONSENT_FORM,
        });

        const service = new ConsentStatusService(config.user.memberId);

        dispatch(setLoadingState({ loadingConsent: true }));

        service
            .getConsentFromCMLM()
            .then((response) => {
                if (!response?.data?.response?.status) return;

                const {
                    redirectToAmwayConsentForm,
                    ssoRedirectionPlace,
                    consentStatus,
                } = response?.data?.response?.data;
                const updatedConsent: ConsentFormDexie = {
                    id: config.user.memberId,
                    ltdConsentStatus: consentStatus,
                    redirectToAmwayConsentForm,
                    ssoRedirectionPlace,
                };

                setConsentStatusById(updatedConsent);
                dispatch(
                    setRedirectionState({
                        showRedirectionDialog: redirectToAmwayConsentForm,
                    }),
                );
            })
            .catch((error) => {
                dispatch(resetRedirectionState());
                captureSentryError(error, {
                    location: "ConsentFormApi",
                });
            })
            .finally(() =>
                dispatch(setLoadingState({ loadingConsent: false })),
            );

        history.goBack();
    };

    useEffect(() => {
        if (success) dismiss();
    }, [success]);

    return {
        header,
        subHeader,
        text,
        loading: textLoading || postLoading,
        success,
        consentGiven,
        dismiss,
    };
};
