import { Box, Fab, Grid, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    useGlobalPlaylists,
    usePlaylistAudios,
    usePlaylists,
    useRemoveAudio,
    useSortAudios,
} from "JS/React/Hooks/Playlist";
import { useHistory, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import {
    getDownloadedPartsNids,
    getPublicUrl,
    isBundleInProgress,
    isPlaylistDownloaded,
} from "JS/Helpers";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
    AudioContent,
    EventActions,
    EventCategories,
    EventNames,
    Gifts,
} from "JS/Models";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { store, useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setDownloadedContentNids } from "JS/Redux/download";
import { PlaylistElement } from "./Components/PlaylistElement";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { useDeleteAudios, useDownloadAudios } from "JS/React/Hooks/Database";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { config } from "JS/Config";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { AppDivider } from "JS/React/Components/AppDivider";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";

export const PlaylistDetail = (props: PlaylistDetailProps) => {
    const classes = useStyles(props);
    const { className } = props;
    const { userPlaylists: playlists, playlistResumeState } =
        useGlobalPlaylists();
    const params: any = useParams();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { id } = params;
    const { loading: playlistLoading, error, getAudios } = usePlaylistAudios();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const {
        audios: allAudios,
        resumeUserAudios,
        resumeUserBundles,
        isAudioUpdateRequired,
    } = useGlobalAudios();
    const [isEdit, setEdit] = useState(false);

    // download playlist
    const [isContentDownloaded, setIsContentDownloaded] =
        useState<boolean>(false);
    const [isPartialDownloaded, setIsPartialDownloaded] =
        useState<boolean>(false);
    const [isCheckingDownload, setIsCheckingDownload] = useState(true);
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { loading: audioLoading } = useAudios(
        allAudios && allAudios.length > 0,
        isAudioUpdateRequired,
    );
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();
    const { loading: rmgLoading } = useReceivedMemberAudioGifts(
        receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
    );
    const { loading: rmpLoading } = useReceivedProspectAudioGifts(
        receivedProspectAudioGifts && receivedProspectAudioGifts.length > 0,
    );
    const { loading: playlistsLoading } = usePlaylists(
        playlists && playlists.length > 0,
    );
    const { favoritesAudios } = useGlobalFavorites();

    const { deleteAudiosById } = useDeleteAudios();

    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { logFirebaseEvent } = useFirebaseLogger();

    const [downloadConfirmDialog, setDownloadConfirmDialog] = useState(false);

    const {
        loading: addToPlaylistLoading,
        downloadAudios,
        limitReached,
        setLimitReached,
    } = useDownloadAudios();

    const playlist = useMemo(() => {
        return playlists?.find((p) => p.playlist_id === id);
    }, [playlists]);

    useEffect(() => {
        if (playlist)
            logFirebaseEvent(EventNames.AUDIO_PLAYLIST_OPENED, {
                action: EventActions.OPEN,
                category: EventCategories.AUDIO_PLAYLIST,
                playlistId: playlist.playlist_id,
            });
    }, [playlist?.playlist_id]);

    const [audiosInPlaylist, setAudiosInPlaylist] =
        useState<(AudioContent | Gifts)[]>();

    useEffect(() => {
        setAudiosInPlaylist(
            playlist?.audios
                ?.map((a) => a)
                ?.sort((a, b) => +a.sort_order - +b.sort_order)
                ?.map((a) => {
                    const selfAudio = allAudios.find((aa) => aa.nid === a.nid);
                    if (selfAudio) return selfAudio;
                    const memberGiftAudio = receivedMemberAudioGifts.find(
                        (rmg) => rmg.nid === a.nid,
                    );
                    if (memberGiftAudio) return memberGiftAudio;
                    return receivedProspectAudioGifts.find(
                        (rgp) => rgp.nid === a.nid,
                    );
                })
                .filter((a) => a !== undefined),
        );
    }, [
        playlist,
        allAudios,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>({});

    useEffect(() => {
        if (audiosInPlaylist) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [audiosInPlaylist]);
    const { audioList, setAudioList, listingLoading, updateAudioStatus } =
        useListing(audiosInPlaylist, inProgressNid, isEdit);

    const {
        onConfirmRemoveAudioFromPlaylist,
        removeLoading,
        removeSuccess,
        removeError,
    } = useHandlers(
        audioList,
        setAudioList,
        audiosInPlaylist,
        setAudiosInPlaylist,
    );

    useEffect(() => {
        if (audiosInPlaylist && audiosInPlaylist?.length > 0) {
            isPlaylistDownloaded(audiosInPlaylist)
                .then((res) => {
                    setIsCheckingDownload(false);
                    setIsContentDownloaded(res.isCompleteDownloaded);
                    setIsPartialDownloaded(res.isPartiallyDownloded);
                })
                .catch((err) => {
                    setIsCheckingDownload(false);
                    setIsContentDownloaded(false);
                    setIsPartialDownloaded(false);
                });
        }
    }, [audiosInPlaylist?.length, inProgressNid]);

    const loading =
        playlistLoading ||
        audioLoading ||
        rmgLoading ||
        rmpLoading ||
        listingLoading ||
        playlistsLoading;

    const fetchThumbnails = async () => {
        let tempNails: {
            [key: string]: string;
        } = {};
        Promise.all(
            audiosInPlaylist.map(
                (a) =>
                    new Promise((resolve) => {
                        const thumb = getPublicUrl(
                            a.image_url_prefix,
                            a.image_url_postfix,
                            a.image_file_name,
                        );
                        thumb.then((value) => {
                            tempNails[a.nid] = value;
                            resolve(value);
                        });
                    }),
            ),
        ).then(() => setThumbnails(tempNails));
    };

    const isFavouriteContent = useCallback(
        (filteredAudio: Gifts | AudioContent) => {
            if (
                favoritesAudios &&
                favoritesAudios.find((x) => x.nid === filteredAudio.nid)
            ) {
                return true;
            } else {
                return false;
            }
        },
        [favoritesAudios],
    );

    useEffect(() => {
        getAudios(id);
    }, [id]);

    const [audioForRemoval, setAudioForRemoval] = useState<
        AudioContent | Gifts
    >();

    const {
        loading: sortLoading,
        error: sortError,
        success: sortSuccess,
        sortAudios,
    } = useSortAudios();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setAudiosInPlaylist(
            arrayMoveImmutable(audiosInPlaylist, oldIndex, newIndex),
        );
        setAudioList(arrayMoveImmutable(audioList, oldIndex, newIndex));
    };

    useEffect(() => {
        if (removeSuccess) {
            if (audioForRemoval) {
                logFirebaseEvent(
                    audioForRemoval.isReceived
                        ? EventNames.GIFT_AUDIO_DELETED_FROM_PLAYLIST
                        : EventNames.AUDIO_DELETED_FROM_PLAYLIST,
                    {
                        action: EventActions.DELETED_FROM_PLAYLIST,
                        category: audioForRemoval.isReceived
                            ? EventCategories.GIFT_AUDIOS
                            : EventCategories.AUDIOS,
                        skuId: audioForRemoval.sku_id,
                        nId: audioForRemoval.nid,
                        playlistId: id,
                        contentTitle: audioForRemoval.title,
                    },
                );
            }
            setAudioForRemoval(undefined);
            enqueueSnackbar(removeSuccess.success, {
                variant: "success",
            });
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (removeError) {
            setAudioForRemoval(undefined);
            enqueueSnackbar(removeError.error, {
                variant: "error",
            });
        }
    }, [removeError]);

    useEffect(() => {
        if (sortSuccess) {
            enqueueSnackbar(sortSuccess.success, {
                variant: "success",
            });
        }
    }, [sortSuccess]);

    useEffect(() => {
        if (sortError) {
            enqueueSnackbar(sortError.error, {
                variant: "error",
            });
        }
    }, [sortError]);

    const isResumeable = useCallback(
        (value: AudioContent) => {
            if (value?.media.length > 1) {
                let filterResumeBundle = resumeUserBundles?.filter(
                    (x) => x.nid === value.nid,
                );
                return filterResumeBundle?.length > 0;
            } else {
                if (
                    resumeUserAudios &&
                    resumeUserAudios.filter((x) => x.nid === value.nid).length >
                        0
                ) {
                    const resumeData = resumeUserAudios.find(
                        (x) => x.nid === value.nid,
                    );
                    return !!resumeData;
                } else {
                    return false;
                }
            }
        },
        [resumeUserAudios, resumeUserBundles],
    );

    const showDeletionSnackbar = () => {
        enqueueSnackbar(messaging.download.deleteSuccess, {
            variant: "success",
        });

        setDeleteLoader(false);
        setIsContentDownloaded(false);
        setIsPartialDownloaded(false);
        updateAudioStatus();
    };

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleDelete = async () => {
        const onDeleteSuccess = (deletedNids: string[]) => {
            const nids = store.getState().download.downloadedContentNids;

            const downloadedNids = nids && nids.length ? [...nids] : [];

            const deletedIds = deletedNids.map(
                (d) => `${config.user.memberId}-${d}`,
            );
            const newNids = downloadedNids.filter(
                (d) => !deletedIds.includes(d),
            );

            showDeletionSnackbar();
            dispatch(
                setDownloadedContentNids({
                    downloadedContentNids: newNids,
                }),
            );
        };

        setDeleteLoader(true);

        let toDeleteIds: string[] = [];

        for (let j = 0; j < audiosInPlaylist.length; j++) {
            const { nid } = audiosInPlaylist[j];

            if (+audiosInPlaylist[j].no_of_files > 1) {
                await getDownloadedPartsNids(nid).then(async (res) => {
                    if (res.length) {
                        toDeleteIds = [...toDeleteIds, ...res];
                    }
                });
            } else {
                toDeleteIds = [
                    ...toDeleteIds,
                    `${config.user.memberId}-${nid}`,
                ];
            }
        }

        await deleteAudiosById(toDeleteIds, () => {
            onDeleteSuccess(toDeleteIds);
        });
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleDownload = async () => {
        toggleDownloadDialog();
        downloadAudios(audiosInPlaylist);
    };

    const toggleDownloadDialog = () => {
        setDownloadConfirmDialog(!downloadConfirmDialog);
    };

    const toggleQueueLimitReached = () => {
        setLimitReached(undefined);
    };

    const playClick = () => {
        if (!!audioList && audioList.length > 0) {
            logFirebaseEvent(EventNames.AUDIO_PLAYLIST_PLAYED, {
                category: EventCategories.AUDIO_PLAYLIST,
                action: EventActions.PLAY,
                playlistId: id,
            });
            history.push(
                linkProvider.react
                    .playlist()
                    .play(
                        id,
                        playlistResumeState(
                            playlist,
                        )?.resumeAudioIndex?.toString(),
                        playlistResumeState(
                            playlist,
                        )?.resumeBundleIndex?.toString(),
                    ),
            );
        } else showEmptyPlaylistSnackbar();
    };

    const showEmptyPlaylistSnackbar = () =>
        enqueueSnackbar(messaging.playlist.emptyError, {
            variant: "warning",
        });

    return (
        <Layout fixProgress>
            <Box className={classes.headerWrapper}>
                <AppHeader
                    title={playlist?.playlist_name}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        history.push(linkProvider.react.playlist().index());
                    }}
                />
                <Box className={clsx(className, classes.fab)}>
                    <Fab
                        className={clsx(className, classes.floatingButton)}
                        color="primary"
                        aria-label="add"
                        onClick={playClick}
                    >
                        <FontAwesomeIcon icon={["fas", "play"]} />
                    </Fab>
                    <Box height={"5px"} />
                    <Box height={"5px"} />
                    <Fab
                        className={clsx(className, classes.floatingButton)}
                        color="primary"
                        aria-label="edit"
                        onClick={() => {
                            if (!!audioList && audioList?.length > 0) {
                                if (isEdit)
                                    sortAudios(
                                        id,
                                        audioList.map((a) => a.audio),
                                    );
                                setEdit(!isEdit);
                            } else showEmptyPlaylistSnackbar();
                        }}
                    >
                        {isEdit ? (
                            <FontAwesomeIcon icon={["fas", "circle-check"]} />
                        ) : (
                            <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                        )}
                    </Fab>
                    <Box height={"5px"} />
                    <Box height={"5px"} />
                    {audioList &&
                        audioList?.length > 0 &&
                        !isContentDownloaded &&
                        !isCheckingDownload && (
                            <>
                                <DownloadWarningDialog
                                    onConfirm={toggleDownloadDialog}
                                >
                                    {(renderProps) => (
                                        <Fab
                                            className={clsx(
                                                className,
                                                classes.floatingButton,
                                            )}
                                            color="primary"
                                            role="button"
                                            aria-label="download"
                                            onClick={
                                                renderProps.askConfirmation
                                            }
                                        >
                                            <AppDownloadingIcons
                                                iconType="download"
                                                isFloating
                                            />
                                        </Fab>
                                    )}
                                </DownloadWarningDialog>

                                <Box height={"5px"} />
                                <Box height={"5px"} />
                            </>
                        )}
                    {audioList && audioList?.length > 0 && isCheckingDownload && (
                        <>
                            <Fab
                                color="primary"
                                className={clsx(
                                    className,
                                    classes.floatingButton,
                                )}
                            >
                                <AppCircularProgress
                                    className={clsx(className, classes.loader)}
                                    loaderSize="small"
                                />
                            </Fab>
                            <Box height={"5px"} />
                            <Box height={"5px"} />
                        </>
                    )}

                    {audioList &&
                        audioList?.length > 0 &&
                        (isPartialDownloaded || isContentDownloaded) &&
                        !isCheckingDownload && (
                            <Fab
                                className={clsx(
                                    className,
                                    classes.floatingButton,
                                )}
                                color="primary"
                                role="button"
                                aria-label="delete"
                                onClick={handleToggleConfirmDialog}
                            >
                                <AppDownloadingIcons
                                    isFloating
                                    iconType="trash"
                                />
                            </Fab>
                        )}
                    {audioList && audioList?.length > 0 && isCheckingDownload && (
                        <Fab
                            color="primary"
                            className={clsx(className, classes.floatingButton)}
                        >
                            <AppCircularProgress
                                className={clsx(className, classes.loader)}
                                loaderSize="small"
                            />
                        </Fab>
                    )}
                </Box>
            </Box>

            <Box className={classes.contentWrapper}>
                {!loading && !error && audioList && audioList?.length > 0 && (
                    <Grid>
                        <SortableAudios
                            items={audioList}
                            isFavouriteContent={isFavouriteContent}
                            isEdit={isEdit}
                            isResumeable={isResumeable}
                            thumbnails={thumbnails}
                            id={id}
                            setAudioForRemoval={setAudioForRemoval}
                            onSortEnd={onSortEnd}
                            useDragHandle={true}
                            useWindowAsScrollContainer={true}
                        />
                    </Grid>
                )}

                {error && (
                    <AppTypography
                        align="center"
                        variant="h6"
                        className={clsx(classes, classes.content)}
                    >
                        {error}
                    </AppTypography>
                )}

                {playlist &&
                    !error &&
                    playlist.audios &&
                    playlist.audios.length === 0 && (
                        <AppTypography
                            align="center"
                            variant="h6"
                            className={clsx(classes, classes.content)}
                        >
                            {messaging.playlist.emptyContent}
                        </AppTypography>
                    )}
            </Box>

            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.fetch_playlist}
                />
            )}
            {removeLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Removing audio"}
                />
            )}
            {sortLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Updating playlist items position"}
                />
            )}

            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.fetch_playlist}
                />
            )}
            {addToPlaylistLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.addingFilesToQueue}
                />
            )}
            {removeLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Removing audio"}
                />
            )}
            {sortLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Updating playlist items position"}
                />
            )}

            {audioForRemoval && !removeLoading && (
                <AppConfimationDialog
                    open
                    onConfirm={() =>
                        onConfirmRemoveAudioFromPlaylist(
                            id,
                            audioForRemoval.nid,
                        )
                    }
                    onClose={() => setAudioForRemoval(undefined)}
                    popupMainText="Are you sure you want to remove this audio?"
                    confirmText="Yes"
                    cancelButtonText="No"
                />
            )}
            {isOpenConfirmDailog && (
                <AppConfimationDialog
                    open={isOpenConfirmDailog}
                    onConfirm={handleDelete}
                    btnConfirmLoader={deleteLoader}
                    onClose={handleToggleConfirmDialog}
                    popupMainText={`Are you sure you want to delete downloaded playlist?`}
                    confirmText="Delete"
                    cancelButtonText="Cancel"
                    showConfirmFirst={true}
                />
            )}
            {limitReached && (
                <AppConfimationDialog
                    titleText={messaging?.downloadingQueue?.QueueLimitation}
                    showTitle={true}
                    open={!!limitReached}
                    popupMainText={
                        limitReached?.count === 0
                            ? messaging?.downloadingQueue?.limitReached
                            : `There are ${limitReached?.count} audios to download. 50 audios can download at one time. Click Download All again to finish downloading all audios.`
                    }
                    cancelButtonText="OK"
                    onClose={toggleQueueLimitReached}
                />
            )}

            {audioList && audioList?.length > 0 && downloadConfirmDialog && (
                <AppConfimationDialog
                    open
                    onConfirm={handleDownload}
                    onClose={toggleDownloadDialog}
                    popupMainText="Are you sure you want to download all content in this playlist?"
                    confirmText="Yes"
                    cancelButtonText="No"
                />
            )}
        </Layout>
    );
};

interface PlaylistAudio {
    audio: AudioContent | Gifts;
    state: AudioDownloadState;
}

enum AudioDownloadState {
    DOWNLOADING,
    QUEUED,
    DOWNLOADED,
    NONE,
}

const useListing = (
    audiosInPlaylist: (AudioContent | Gifts)[],
    inProgressNid: string,
    isEdit: boolean,
) => {
    const [listingLoading, setListingLoading] = useState(false);
    const [audioList, setAudioList] = useState<PlaylistAudio[]>();
    const isInitialRun = useRef(true);
    const progressIdRef = useRef(inProgressNid);
    const audiosRef = useRef(audiosInPlaylist);

    const isDownloading = (nid: string, length: number) => {
        if (length > 1) return isBundleInProgress(nid);
        else return progressIdRef.current.includes(nid);
    };

    const isAudioDownloaded = async (audioNid: string, length: number) => {
        if (length > 1) {
            const downloadedPartsNids = await getDownloadedPartsNids(audioNid);
            return downloadedPartsNids?.length === length;
        } else {
            const response = await getDexieConnectionRefresh().audios.get(
                `${config.user.memberId}-${audioNid}`,
            );

            return response ? true : false;
        }
    };

    const updateAudioStatus = () => {
        if (!!audiosRef.current && audiosRef.current.length > 0) {
            setListingLoading(isInitialRun.current);
            if (isInitialRun.current) isInitialRun.current = false;
            Promise.all(
                audiosRef.current?.map(async (a) => {
                    let state = AudioDownloadState.NONE;
                    const downloading = isDownloading(a.nid, a?.media.length);
                    if (downloading) state = AudioDownloadState.DOWNLOADING;
                    else if (isInDownloadQueue(a.nid))
                        state = AudioDownloadState.QUEUED;
                    else {
                        const isDownloaded = await isAudioDownloaded(
                            a.nid,
                            a?.media.length,
                        );
                        if (isDownloaded) state = AudioDownloadState.DOWNLOADED;
                    }
                    return { audio: a, state } as PlaylistAudio;
                }),
            )
                .then((val) => {
                    setAudioList(val);
                })
                .catch((_) => {})
                .finally(() => setListingLoading(false));
        }
    };

    useEffect(() => {
        updateAudioStatus();

        const interval = setInterval(() => {
            if (!isEdit) updateAudioStatus();
        }, 15000);

        return () => {
            clearInterval(interval);
        };
    }, [isEdit]);

    useEffect(() => {
        progressIdRef.current = inProgressNid;
    }, [inProgressNid]);

    useEffect(() => {
        let isDifferent = audiosRef.current !== audiosInPlaylist;
        audiosRef.current = audiosInPlaylist;
        if (isDifferent || isInitialRun.current) updateAudioStatus();
    }, [audiosInPlaylist]);

    return {
        audioList,
        setAudioList,
        listingLoading,
        updateAudioStatus,
    };
};

const useHandlers = (
    audioList: PlaylistAudio[],
    setAudioList: (audios: PlaylistAudio[]) => void,
    audiosInPlaylist: (AudioContent | Gifts)[],
    setAudiosInPlaylist: (audios: (AudioContent | Gifts)[]) => void,
) => {
    const {
        loading: removeLoading,
        error: removeError,
        success: removeSuccess,
        removeAudio,
    } = useRemoveAudio();

    const onConfirmRemoveAudioFromPlaylist = async (
        id: string,
        audioNid: string,
    ) => {
        const deletedId = await removeAudio(id, audioNid);
        if (deletedId) {
            onDeleteAudioFromList(deletedId);
        }
    };

    const onDeleteAudioFromList = (id: string) => {
        const filtered = audioList.filter((d) => d.audio.nid !== id);
        setAudioList(filtered);
        setAudiosInPlaylist(audiosInPlaylist.filter((a) => a.nid !== id));
    };

    return {
        onConfirmRemoveAudioFromPlaylist,
        removeLoading,
        removeSuccess,
        removeError,
    };
};

export interface PlaylistDetailProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export interface PlaylistSortable {
    value: AudioContent | Gifts;
    isEdit: boolean;
    handleAudioRemoval: () => void;
    isDownloadInProgress?: boolean;
    isInDownloadQueue?: boolean;
    isDownloaded?: Promise<boolean>;
    inProgressDownloaded?: boolean;
    isResume: boolean;
    isFavouriteContent: boolean;
    imageUrl: string;
    handleListItemClick: () => void;
}

const SortableAudio = SortableElement((props: PlaylistSortable) => {
    const {
        value,
        isEdit,
        isDownloadInProgress,
        isInDownloadQueue,
        isDownloaded,
        inProgressDownloaded,
        isResume,
        imageUrl,
        isFavouriteContent,
        handleListItemClick,
        handleAudioRemoval,
    } = props;
    return (
        <PlaylistElement
            value={value}
            isEdit={isEdit}
            isDownloadInProgress={isDownloadInProgress}
            isInDownloadQueue={isInDownloadQueue}
            isDownloaded={isDownloaded}
            inProgressDownloaded={inProgressDownloaded}
            isResume={isResume}
            isFavouriteContent={isFavouriteContent}
            imageUrl={imageUrl}
            handleListItemClick={handleListItemClick}
            handleAudioRemoval={handleAudioRemoval}
        />
    );
});

export interface SortableAudiosProps {
    items: PlaylistAudio[];
    isFavouriteContent: (filteredAudio: Gifts | AudioContent) => boolean;
    isEdit: boolean;
    isResumeable: (value: AudioContent | Gifts) => boolean;
    thumbnails: {
        [key: string]: string;
    };
    id: string;
    setAudioForRemoval: React.Dispatch<
        React.SetStateAction<Gifts | AudioContent>
    >;
}

const SortableAudios = SortableContainer((props: SortableAudiosProps) => {
    const history = useHistory();
    const { linkProvider } = useRouting();
    const {
        items,
        isFavouriteContent,
        isEdit,
        isResumeable,
        thumbnails,
        id,
        setAudioForRemoval,
        ...rest
    } = props;

    return (
        <Grid {...rest}>
            {items?.map((a, i) => (
                <React.Fragment key={a.audio.nid}>
                    <SortableAudio
                        isEdit={isEdit}
                        isDownloadInProgress={
                            a.state === AudioDownloadState.DOWNLOADING
                        }
                        isInDownloadQueue={
                            a.state === AudioDownloadState.QUEUED
                        }
                        isDownloaded={
                            new Promise<boolean>((res, _) => {
                                res(a.state === AudioDownloadState.DOWNLOADED);
                            })
                        }
                        isResume={isResumeable(a.audio)}
                        isFavouriteContent={isFavouriteContent(a.audio)}
                        imageUrl={thumbnails && thumbnails[a.audio.nid]}
                        handleListItemClick={() => {
                            if (!isEdit)
                                history.push(
                                    linkProvider.react
                                        .playlist()
                                        .play(
                                            id,
                                            items?.indexOf(a).toString(),
                                            "0",
                                        ),
                                );
                        }}
                        handleAudioRemoval={() => setAudioForRemoval(a.audio)}
                        value={a.audio}
                        index={i}
                    />
                    <AppDivider />
                </React.Fragment>
            ))}
        </Grid>
    );
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        fab: {
            top: `calc(${theme.header.height})`,
            display: "flex",
            justifyContent: "start",
            columnGap: "5px",
            paddingLeft: "16px",
            flexDirection: "row",
            color: theme.colors.white,
        },
        floatingButton: {
            background:
                theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.grey[600],
            color: theme.colors.white,
            "&:hover": {
                background:
                    theme.palette.mode === "dark"
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
                color: theme.colors.white,
            },
        },
        loader: {
            color: theme.palette.common.white,
        },
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
        },
        editIcons: {
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        headerWrapper: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.black,
            width: "100%",
            paddingBottom: "10px",
            position: "fixed",
            zIndex: 100,
        },
        contentWrapper: {
            paddingTop: "130px",
            overflowX: "hidden",
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.black,
        },
    }),
);
