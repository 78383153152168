import axios from "axios";
import { AudioDexie, getDexieConnection, VideoDexie } from "JS/Database/Dexie";
import {
    setDownloadProgress,
    setDownloadedContentNids,
    setFailedContent,
    resetDownloadState,
} from "JS/Redux/download";
import { store } from "JS/Redux/Store";
import {
    getPublicUrl,
    getSignedPublicUrl,
    getSizeOfContent,
} from "JS/Helpers/S3Helper";
import { IndexableType, IndexableTypeArray, Table } from "dexie";
import { config } from "JS/Config";
import {
    AddToDownloadOptions,
    LogFirebaseEventDownloadOptions,
    DocumentQueueItem,
    QueueItem,
    DownloadQueueItemsType,
    QueueItemContentType,
    DexieDbTypes,
} from "JS/Models/Common";
import {
    CourseStep,
    DownloadedCourseDetail,
    EventActions,
    FirebaseEventAction,
} from "JS/Models";
import {
    getDownloadFirebaseCategory,
    getDownloadFirebaseEventName,
} from "./Helpers";
import { courseFirebaseService } from "JS/Services/Firebase";
import { appConstants } from "./Contants";
import { messaging } from "./UserMessaging";
import { captureSentryError } from "./SentryHelper";
import { setGlobalDownloadedCourses } from "JS/Redux/CourseDownload";
import {
    mutateCourseFirebaseEventDetail,
    createCourseIDBId,
    checkIsCourseType,
    extractStepIdFromCombinedNid,
} from "./CourseHelpers";
import { setCommonMessage } from "JS/Redux/media";

export enum DownloadStatus {
    ALLOWED,
    WARNING,
    BLOCK,
}

export const getActiveDb = (type: QueueItemContentType) => {
    const db = getDexieConnection();
    if (type === "document") {
        return db.documents;
    } else if (type === "video" || type === "giftVideo") {
        return db.videos;
    } else if (checkIsCourseType(type)) {
        return db.courses;
    } else {
        return db.audios;
    }
};

let isLock = false;

export const processDownloadQueue = async () => {
    let downloadQueue = getDownloadQueueFromStorage();
    let isReady =
        downloadQueue &&
        downloadQueue.length > 0 &&
        downloadQueue[0]?.status !== "inProgress";
    let downloadAttempts = downloadQueue[0]?.tries;

    if (isReady && !isLock) {
        isLock = true;
        if (downloadAttempts < 4) {
            downloadQueue[0].status = "inProgress";
            setDownloadQueueInStorage(downloadQueue);

            let signedUrl = "";
            const downloadQueueItem = downloadQueue[0];
            const {
                media_url_prefix,
                media_url_postfix,
                media_file_name,
                name,
                nid,
                type,
                skuId,
                description,
                release_date,
                media_title,
                position,
                no_of_files,
                courseNid,
            } = downloadQueueItem;

            const isBundleType =
                type === "audioBundle" || type === "giftAudioBundle";

            const isCourseType = checkIsCourseType(type);

            let firebaseEventDetails: Partial<LogFirebaseEventDownloadOptions> =
                {
                    category: type,
                    contentTitle: getMediaName(media_title, name),
                };

            if (type !== "document") {
                if (isCourseType) {
                    mutateCourseFirebaseEventDetail(
                        firebaseEventDetails,
                        downloadQueueItem,
                    );
                } else {
                    firebaseEventDetails = {
                        ...firebaseEventDetails,
                        nId: isBundleType ? nid?.split("-")[0] : nid,
                        skuId: isBundleType ? media_file_name : skuId,
                    };
                }
                await getPublicUrl(
                    media_url_prefix,
                    media_url_postfix,
                    media_file_name,
                )
                    .then((signedURL) => {
                        signedUrl = signedURL;
                    })
                    .catch((err) => {
                        signedUrl = "";
                    });
            } else {
                const docsConfig = config.s3.documents;
                await getSignedPublicUrl(`${docsConfig.baseUrl}/${nid}`).then(
                    (url) => (signedUrl = url),
                );
            }

            const inProgressNid = store?.getState()?.download?.inProgressNid;

            if (!inProgressNid && inProgressNid !== nid) {
                if (signedUrl) {
                    let updatedDownloadQueue = getDownloadQueueFromStorage();
                    updatedDownloadQueue[0].tries =
                        updatedDownloadQueue[0].tries + 1;

                    setDownloadQueueInStorage(updatedDownloadQueue);

                    const controller = new AbortController();
                    const startTime = Date.now();

                    logFirebaseEventDownload(
                        {
                            ...firebaseEventDetails,
                            action: EventActions.DOWNLOAD_STARTED,
                        } as LogFirebaseEventDownloadOptions,
                        downloadQueueItem,
                    );
                    const downloadStatus = await getDownloadingStatus(
                        `${media_url_prefix}${media_url_postfix}`,
                        media_file_name,
                    );
                    if (downloadStatus === DownloadStatus.BLOCK) {
                        clearDownloadQueue();
                        store.dispatch(resetDownloadState());
                        store.dispatch(
                            setCommonMessage({
                                message: messaging.storageQuota.downloadError,
                                show: true,
                                variant: "error",
                            }),
                        );

                        isLock = false;
                    } else {
                        await axios
                            .get(signedUrl, {
                                responseType: "blob",
                                signal: controller.signal,
                                onDownloadProgress: (progressEvent) => {
                                    let downloadPercentage = Math.floor(
                                        (progressEvent.loaded /
                                            progressEvent.total) *
                                            100,
                                    );
                                    const prevPercentage =
                                        store.getState().download
                                            .downloadContentPercentage;

                                    if (
                                        (downloadPercentage % 3 === 0 ||
                                            downloadPercentage === 100) &&
                                        downloadPercentage !== prevPercentage
                                    ) {
                                        store.dispatch(
                                            setDownloadProgress({
                                                showDownloadProgress:
                                                    downloadPercentage >= 100
                                                        ? false
                                                        : true,
                                                downloadContentName:
                                                    getMediaName(
                                                        media_title,
                                                        name,
                                                    ),
                                                downloadContentPercentage:
                                                    downloadPercentage,
                                                inProgressNid:
                                                    downloadPercentage >= 100
                                                        ? ""
                                                        : nid,
                                                loadedContent:
                                                    progressEvent.loaded,
                                                contentLength:
                                                    progressEvent.total,
                                                startTime: startTime,
                                            }),
                                        );
                                    }
                                },
                            })
                            .then(async (res) => {
                                store.dispatch(
                                    setDownloadProgress({
                                        showDownloadProgress: false,
                                        downloadContentName: getMediaName(
                                            media_title,
                                            name,
                                        ),
                                        downloadContentPercentage: 100,
                                        inProgressNid: "",
                                        loadedContent: "in success block",
                                        contentLength: "",
                                        startTime: startTime,
                                    }),
                                );
                                const activeDb: Table<
                                    DexieDbTypes,
                                    IndexableType
                                > = getActiveDb(type);
                                let tableItem: DexieDbTypes = {
                                    id: `${config.user.memberId}-${nid}`,
                                    blob: res.data,
                                    name: name,
                                    description,
                                };

                                if (isBundleType) {
                                    tableItem = {
                                        ...tableItem,
                                        skuId,
                                        release_date,
                                        isBundle: true,
                                        media_title,
                                        position,
                                        no_of_files,
                                    };
                                }
                                if (type !== "document" && !isBundleType) {
                                    tableItem = {
                                        ...tableItem,
                                        skuId,
                                        release_date,
                                    };
                                }

                                if (isCourseType) {
                                    const stepNid =
                                        extractStepIdFromCombinedNid(nid);
                                    tableItem = {
                                        id: createCourseIDBId(
                                            config.user.memberId,
                                            courseNid,
                                            stepNid,
                                        ),
                                        courseNid: courseNid,
                                        stepNid: stepNid,
                                        blob: res.data,
                                    };
                                }

                                await activeDb
                                    .add(tableItem)
                                    .then(() => {
                                        const newNid = isCourseType
                                            ? extractStepIdFromCombinedNid(nid)
                                            : nid;

                                        if (isCourseType) {
                                            const downloadedCourses =
                                                store.getState()
                                                    .downloadedCourse
                                                    .downloadedCourses;

                                            const newCourses =
                                                markStepDownloadedStatus(
                                                    downloadedCourses,
                                                    courseNid,
                                                    newNid,
                                                    true,
                                                );
                                            store.dispatch(
                                                setGlobalDownloadedCourses(
                                                    newCourses,
                                                ),
                                            );
                                        }

                                        const downloadedContentNids =
                                            store.getState().download
                                                .downloadedContentNids;

                                        const downloadedNids = Object.assign(
                                            [],
                                            downloadedContentNids,
                                        );

                                        const downloadedNid = isCourseType
                                            ? createCourseIDBId(
                                                  config.user.memberId,
                                                  courseNid,
                                                  newNid,
                                              )
                                            : `${config.user.memberId}-${nid}`;

                                        if (
                                            !downloadedNids.includes(
                                                downloadedNid,
                                            )
                                        ) {
                                            downloadedNids.push(downloadedNid);
                                        }

                                        store.dispatch(
                                            setDownloadedContentNids({
                                                downloadedContentNids:
                                                    downloadedNids,
                                            }),
                                        );
                                        removeFromDownloadQueue(nid);

                                        logFirebaseEventDownload(
                                            {
                                                ...firebaseEventDetails,
                                                action: EventActions.DOWNLOAD_FINISHED,
                                            } as LogFirebaseEventDownloadOptions,
                                            downloadQueueItem,
                                        );

                                        store.dispatch(resetDownloadState());
                                    })
                                    .catch((err) => {
                                        captureSentryError(err, {
                                            location: "IndexedDb",
                                            downloadNid: nid,
                                            downloadType: type,
                                        });
                                        if (
                                            err?.name !== "ConstraintError"
                                            // &&
                                            // err?.name !== "DataCloneError"
                                        ) {
                                            logFirebaseEventDownload(
                                                {
                                                    ...firebaseEventDetails,
                                                    action: EventActions.DOWNLOAD_FAILED,
                                                } as LogFirebaseEventDownloadOptions,
                                                downloadQueueItem,
                                            );

                                            store.dispatch(
                                                setFailedContent({
                                                    lastFailedContent: {
                                                        nid,
                                                        name,
                                                        msg:
                                                            downloadAttempts !==
                                                            3
                                                                ? `${getMediaName(
                                                                      media_title,
                                                                      name,
                                                                  )} failed to download. Retrying...`
                                                                : `${getMediaName(
                                                                      media_title,
                                                                      name,
                                                                  )} failed to download. Please try again.`,
                                                        variant:
                                                            downloadAttempts !==
                                                            3
                                                                ? "info"
                                                                : "error",
                                                    },
                                                }),
                                            );
                                            window.location.reload();
                                        }

                                        removeFromDownloadQueue(nid);
                                        store.dispatch(resetDownloadState());
                                    });
                            })
                            .catch((err) => {
                                if (
                                    err.message !== messaging.apiError.network
                                ) {
                                    captureSentryError(err, {
                                        downloadNid: nid,
                                        downloadType: type,
                                    });
                                }
                                if (
                                    !navigator.onLine ||
                                    document.visibilityState === "hidden" ||
                                    err?.message === "Network Error"
                                ) {
                                    logFirebaseEventDownload(
                                        {
                                            ...firebaseEventDetails,
                                            action: EventActions.DOWNLOAD_FAILED,
                                        } as LogFirebaseEventDownloadOptions,
                                        downloadQueueItem,
                                    );

                                    store.dispatch(
                                        setFailedContent({
                                            lastFailedContent: {
                                                nid,
                                                name: getMediaName(
                                                    media_title,
                                                    name,
                                                ),
                                                msg:
                                                    downloadAttempts !== 3
                                                        ? `${getMediaName(
                                                              media_title,
                                                              name,
                                                          )} failed to download. Retrying...`
                                                        : `${getMediaName(
                                                              media_title,
                                                              name,
                                                          )} failed to download. Please try again.`,
                                                variant:
                                                    downloadAttempts !== 3
                                                        ? "info"
                                                        : "error",
                                            },
                                        }),
                                    );
                                    resetFirstElementInDownloadQueue();
                                    controller.abort();
                                } else {
                                    logFirebaseEventDownload(
                                        {
                                            ...firebaseEventDetails,
                                            action: EventActions.DOWNLOAD_FAILED,
                                        } as LogFirebaseEventDownloadOptions,
                                        downloadQueueItem,
                                    );

                                    store.dispatch(
                                        setFailedContent({
                                            lastFailedContent: {
                                                nid,
                                                name: getMediaName(
                                                    media_title,
                                                    name,
                                                ),
                                                msg: `${getMediaName(
                                                    media_title,
                                                    name,
                                                )} failed to download.`,
                                                variant: "error",
                                            },
                                        }),
                                    );
                                    removeFromDownloadQueue(nid);
                                }

                                store.dispatch(resetDownloadState());
                            });
                        processQueueAfterDelay();
                    }
                }
            }
        } else {
            removeFromDownloadQueue(downloadQueue[0].nid);
            processQueueAfterDelay();
        }
    }
};

export const processQueueAfterDelay = () => {
    setTimeout(async () => {
        isLock = false;
        if (navigator.onLine && document.visibilityState === "visible") {
            processDownloadQueue();
        }
    }, 3000);
};

export const getMediaName = (
    media_title: string | undefined,
    name: string,
): string => {
    return media_title ? media_title : name;
};

export const enqueueItem = (queueItem: DownloadQueueItemsType) => {
    let downloadQueue = getDownloadQueueFromStorage();
    const inProgressNid = store.getState().download.inProgressNid;

    const downloadingQueueLimit =
        appConstants.downloadingQueue.downloadingQueueLimit;
    if (
        downloadQueue.length < downloadingQueueLimit &&
        !isInDownloadQueue(queueItem.nid) &&
        inProgressNid !== queueItem.nid
    ) {
        setDownloadQueueInStorage([...downloadQueue, queueItem]);
    }
};

export const logFirebaseEventDownload = (
    firebaseEventDetails: LogFirebaseEventDownloadOptions,
    queueItem: QueueItem,
) => {
    const { category } = firebaseEventDetails;

    const firebaseCategory = getDownloadFirebaseCategory(
        category,
        queueItem.courseType === "GiftCourse",
    );

    const firebaseAction: FirebaseEventAction = {
        ...firebaseEventDetails,
        category: firebaseCategory,
    };

    const eventName = getDownloadFirebaseEventName(
        category,
        firebaseEventDetails?.action,
        queueItem.courseType === "GiftCourse",
    );

    courseFirebaseService.logFirebaseEvent(eventName, firebaseAction);
};

export const addToDownloadQueue = async (options: AddToDownloadOptions) => {
    const {
        type,
        media,
        name,
        nid,
        skuId,
        description,
        release_date,
        media_url_prefix,
        media_url_postfix,
        media_file_name,
    } = options;

    if (type === "audioBundle" || type === "giftAudioBundle") {
        let downloadedBundleNids: IndexableTypeArray = [];
        const db = getDexieConnection();
        const collection = db.audios.filter((item) => item.isBundle === true);
        await collection.keys().then((res) => {
            downloadedBundleNids = res;
        });
        if (media) {
            for (const part of media) {
                const {
                    media_url_prefix: part_url_prefix,
                    media_url_postfix: part_url_postfix,
                    media_file_name: part_file_name,
                    position,
                    media_title,
                } = part;
                if (
                    !downloadedBundleNids.includes(
                        `${config.user.memberId}-${nid}-${position}`,
                    )
                ) {
                    const queueItem: QueueItem = {
                        media_url_prefix: part_url_prefix,
                        media_url_postfix: part_url_postfix,
                        media_file_name: part_file_name,
                        media_title,
                        no_of_files: media.length,
                        position,
                        name,
                        nid: `${nid}-${position}`,
                        type,
                        status: "queued",
                        skuId,
                        description,
                        release_date,
                        tries: 0,
                    };
                    enqueueItem(queueItem);
                }
            }
        }
    } else if (type === "document") {
        const queueItem: DocumentQueueItem = {
            name,
            nid,
            type,
            status: "queued",
            description,
            tries: 0,
        };
        enqueueItem(queueItem);
    } else {
        const db = getDexieConnection();
        const activeDb: Table<VideoDexie | AudioDexie, IndexableType> =
            type === "video" || type === "giftVideo" ? db.videos : db.audios;
        const response = await activeDb.get(`${config.user.memberId}-${nid}`);
        let alreadyDownloaded = response ? true : false;
        if (!alreadyDownloaded) {
            const queueItem: QueueItem = {
                media_url_prefix,
                media_url_postfix,
                media_file_name,
                name,
                nid,
                type,
                status: "queued",
                skuId,
                description,
                release_date,
                tries: 0,
            };
            enqueueItem(queueItem);
        }
    }

    processDownloadQueue();
};

export const removeFromDownloadQueue = (nid: string) => {
    let downloadQueue = getDownloadQueueFromStorage();

    const existing = downloadQueue.find((a) => a.nid === nid);
    if (!!existing) downloadQueue.splice(downloadQueue.indexOf(existing), 1);
    setDownloadQueueInStorage([...downloadQueue]);
};

export const resetFirstElementInDownloadQueue = () => {
    let downloadQueue = getDownloadQueueFromStorage();
    if (downloadQueue && downloadQueue.length > 0) {
        downloadQueue[0].status = "queued";
        setDownloadQueueInStorage(downloadQueue);
    }
};

export const isInDownloadQueue = (nid: string) => {
    let downloadQueue = getDownloadQueueFromStorage();
    const filteredElement = downloadQueue.filter(
        (element) => element.nid.includes(nid) && element.status === "queued",
    );

    return filteredElement.length > 0;
};

export const isDownloadInProgress = (nid: string) => {
    let downloadQueue = getDownloadQueueFromStorage();
    const filteredElement = downloadQueue.filter(
        (element) =>
            element.nid.includes(nid) && element.status === "inProgress",
    );

    return filteredElement.length > 0;
};

export const getDownloadQueueLength = (): number => {
    let downloadQueue = getDownloadQueueFromStorage();
    return downloadQueue.length;
};

export const getDownloadQueueFromStorage = () => {
    const item: QueueItem[] = localStorage.getItem("downloadQueue")
        ? JSON.parse(localStorage.getItem("downloadQueue"))
        : [];

    return item;
};

export const setDownloadQueueInStorage = (items: DownloadQueueItemsType[]) => {
    const stringify = JSON.stringify(items);

    localStorage.setItem("downloadQueue", stringify);

    return getDownloadQueueFromStorage();
};

export const markStepDownloadedStatus = (
    courses: DownloadedCourseDetail[],
    courseNid: DownloadedCourseDetail["nid"],
    stepNid: CourseStep["nid"],
    status: boolean,
): DownloadedCourseDetail[] => {
    return courses.map((d) => {
        if (d.nid !== courseNid) {
            return d;
        } else {
            const newSteps = d.steps.map((s) => {
                if (s.nid !== stepNid) {
                    return s;
                } else {
                    return {
                        ...s,
                        isDownloaded: status,
                    };
                }
            });

            const detail: DownloadedCourseDetail = {
                ...d,
                steps: newSteps,
            };

            return detail;
        }
    });
};

export const clearDownloadQueue = () => {
    localStorage.setItem("downloadQueue", "[]");
};

export const getSize = async (key: string, bucket: string) => {
    try {
        return await getSizeOfContent(key, bucket);
    } catch (_) {
        return 0;
    }
};

export const getStorageQuota = (): Promise<StorageEstimate | null> => {
    return navigator.storage?.estimate
        ? navigator.storage
              ?.estimate()
              .then((s) => s)
              .catch(() => null)
        : null;
};

const checkQuotaAvailableForDownload = async (
    //in bytes
    sizeToDownload: number,
): Promise<DownloadStatus> => {
    const storageEstimate = await getStorageQuota();
    if (!sizeToDownload) sizeToDownload = 0;
    // Do not remove commented code, this might come in handy later
    // if (storageEstimate) {
    //     const remainingBytes = storageEstimate.quota - storageEstimate.usage;
    //     const remainingGb = bytesToGB(remainingBytes - sizeToDownload);

    //     if (remainingGb <= config.storageQuota.lowerLimit)
    //         return DownloadStatus.BLOCK;

    //     if (remainingGb <= config.storageQuota.upperLimit)
    //         return DownloadStatus.WARNING;

    //     return DownloadStatus.ALLOWED;
    // }

    return DownloadStatus.ALLOWED;
};

export const getDownloadingStatus = async (key: string, bucket: string) => {
    return await checkQuotaAvailableForDownload(await getSize(key, bucket));
};

export const checkIfUrlIsBlob = (mediaUrl: string) => {
    if (mediaUrl?.search("blob:") === 0) {
        return true;
    }

    return false;
};
