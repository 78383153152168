import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    ListItemText,
    MenuItem,
    MenuList,
    Theme,
    Typography,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    Course,
    CourseStatsBody,
    CourseStep,
    EventActions,
    EventNames,
    GiftRemainingQuantity,
    GiftsTypes,
    Members,
    ReceivedCourse,
} from "JS/Models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Videocam as VideocamIcon } from "@mui/icons-material";
import { AppButton } from "JS/React/Components/AppButton";
import {
    useActiveCourse,
    useCourseDetail,
    useCourses,
    useGlobalCourses,
    usePurchasableCourses,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { AppCompletionProgress } from "JS/React/Components/AppCompletionProgress";
import { useEffect, useMemo, useState } from "react";
import { StepsListItem } from "./StepsListItem";
import { AppTypography } from "JS/React/Components/AppTypography";
import qs from "qs";
import { useRouting } from "JS/React/Hooks/Routes";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import { SelectMembersDialog } from "JS/React/Components/ContentPurchase/SelectMembersDialog";
import { GiftQuantity } from "JS/React/Components/ContentPurchase/GiftQuantity";
import { ContentPurchase } from "JS/React/Components/ContentPurchase/ContentPurchase";
import { useGiftRemainingQuantity, useSendGifts } from "JS/React/Hooks/Gifts";
import {
    useAvailableCredits,
    usePurchaseByCard,
    useRedeemByCredits,
    useRedeemGiftByCredits,
    useAddContentToCart,
} from "JS/React/Hooks/Purchase";
import { useSnackbar } from "notistack";
import {
    giftTypeIdToString,
    giftTypeStringToId,
    isSufficientCreditAvailable,
    calculatePercentage,
    getPublicUrl,
    isAndroidDevice,
    isRedeemable,
} from "JS/Helpers";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { useWriteCourseStatsFBFunc } from "JS/React/Hooks/Firebase/Database";
import moment from "moment";
import { ActiveCourseType } from "JS/Redux/Course";
import {
    EventCategories,
    FirebaseEventAction,
    CourseDetail as ICourseDetail,
} from "JS/Models";
import { GiftCategories } from "JS/React/Scenes/Gifts";
import { useObserveCourseStats } from "JS/React/Hooks/Firebase/Observers";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    useSearchedLosMembers,
    useVerifyAwsKeys,
    useScrollToTop,
} from "JS/React/Hooks/Media";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { AllTabs } from "JS/Models";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppAlternativeResponse } from "JS/Types";
import {
    useCourseDownloadQueue,
    useDeleteCourse,
    useDownloadedCourses,
} from "JS/React/Hooks/DownloadCourses";
import { config } from "JS/Config";
import { store, useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    createCourseIDBId,
    getCombinedCrsStepNid,
    getDownloadedSteps,
    sortStepsInDetail,
} from "JS/Helpers/CourseHelpers";
import {
    isDownloadInProgress,
    isInDownloadQueue,
    markStepDownloadedStatus,
} from "JS/Helpers/ContentDownloadHelper";
import {
    AskConfirmationDialog,
    AskConfirmationDialogRenderProps,
} from "JS/React/Components/AskConfirmationDialog";
import {
    DownloadStatusIcon,
    StepListDownloadType,
} from "JS/React/Components/Icon/DownloadStatusIcon";
import { useMultipleFileSizes } from "JS/React/Hooks/S3";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { useStepFromDb } from "JS/React/Hooks/Database/Course";
import { setGlobalDownloadedCourses } from "JS/Redux/CourseDownload";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { useMiniPlayerOpen } from "JS/React/Hooks/Course/MiniPlayerOpen";
import { AppDivider } from "JS/React/Components/AppDivider";
import { AppMenuListing } from "JS/React/Components/AppMenuListing";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { SentGiftType, toSentGift } from "JS/Models/Firebase/GiftDashboard";
import { CourseLogFirebaseEventSettings } from "./Steps/CourseStep";

export const CourseDetail = (props: CourseDetailProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const { queryParams, received, contentId, isGiftTab } =
        useQueryParamsAndStrings();

    const {
        activeCourse,
        courses,
        purchasableCoursesForMembers,
        receivedCoursesLoading,
        coursesLoading,
        courseLoaded,
        setGlobalCourses,
    } = useDetailOpen(received, contentId);

    const [showDesc, setShowDesc] = useState(false);

    const { withMedia, detail, detailLoading } = useCourseDetail(
        received,
        activeCourse,
    );
    const {
        allDownloaded,
        courseDownloadedStatus,
        confirmationPopupBtnText,
        confirmationPopupMainText,
        getStepDownloadStatus,
        syncDownloadedStatus,
    } = useDownloadedStatus(detail);

    const {
        observedCourseStats,
        nextStep,
        progressVal,
        sortedSteps,
        seqPosition,
    } = useObservedStats(detail);

    const {
        linkProvider,
        history,
        thumbnail,
        stepsThumbnails,
        goBack,
        handleNavigateToQueue,
    } = useIndexHooks(
        detail,
        activeCourse,
        courseLoaded,
        received,
        queryParams,
    );

    const { handleListItemClick } = useHandlers(
        activeCourse,
        detail,
        queryParams?.segment,
        isGiftTab,
        queryParams?.isMixedContent,
    );

    const { loading: purchasableCoursesLoading } = usePurchasableCourses(
        !!purchasableCoursesForMembers &&
            purchasableCoursesForMembers.length > 0,
    );

    const [activeGiftItem, setActiveGiftItem] = useState<ActiveGiftItemType>({
        title: "",
        giftPurchasedEnabled: false,
        giftPurchasedSuccess: false,
        quantity: 1,
    });
    const [dialogs, setDialogs] = useState<DialogsType>({
        transactionComplete: false,
        transactionConfirmation: false,
        selectMembers: false,
    });

    const {
        giftMemberQuanity,
        loadingGiftMemeberQuanity,
        fetchGiftMemberQuanity,
    } = useGiftMemberQuantity(received, activeCourse);

    const {
        availableCredits,
        purchaseEnabled,
        availableCreditsLoading,
        purchasingContent,
        redeemingContent,
        redeemingGiftContent,
        paymentMethod,
        cart,
        setPaymentMethod,
        setPurchaseEnabled,
        onBuyGiftContent,
        onBuyConfirm,
        onBuyContent,
        shortcutGift,
    } = usePurchaseHanlders(
        contentId,
        activeCourse,
        activeGiftItem,
        dialogs,
        courses,
        setGlobalCourses,
        setDialogs,
        fetchGiftMemberQuanity,
        purchasableCoursesForMembers,
    );

    const {
        anchorEl,
        anchorSendGift,
        openSendGift,
        selectedMembers,
        sendingGifts,
        giftItem,
        setAnchorEl,
        setAnchorSendGift,
        onGiftSendMenuItem,
        onGiftMenuItemClick,
        onSendContent,
        onQuantityChange,
        onToggleItemSelect,
        onCloseSelectMembers,
        onSendSelectedMembers,
    } = useUserGifts(
        activeCourse,
        dialogs,
        activeGiftItem,
        giftMemberQuanity,
        received,
        purchasableCoursesForMembers,
        setActiveGiftItem,
        setDialogs,
        setPurchaseEnabled,
        fetchGiftMemberQuanity,
    );

    const { isSizeValid, sizesLoading, showLimitDialog, toggleLimitDialog } =
        useCourseSizeAndLimits(detail, withMedia);

    useScrollToTop();

    const {
        askConfirmation,
        onDownloadOrDeleteClick,
        handleDownloadIconClick,
    } = useDownloadHandlers(
        allDownloaded,
        isSizeValid,
        sizesLoading,
        courseDownloadedStatus,
        toggleLimitDialog,
        handleNavigateToQueue,
    );

    const { searchLosResults, searchQueryLos, setSearchQueryLos } =
        useSearchedLosMembers();

    //For Gift Icon
    const open = Boolean(anchorEl);

    const loading = coursesLoading || receivedCoursesLoading || detailLoading;

    const purchaseText = useMemo(() => {
        if (activeGiftItem.giftPurchasedEnabled) {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${
                      +giftItem?.number_of_tokens * activeGiftItem?.quantity ||
                      0
                  }`
                : `Total: $ ${(
                      +giftItem?.content_price * activeGiftItem?.quantity || 0
                  ).toFixed(2)}`;
        } else {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${
                      (activeCourse as Course)?.number_of_tokens
                  }`
                : `Total: $ ${(
                      +(activeCourse as Course)?.content_price || 0
                  ).toFixed(2)}`;
        }
    }, [activeGiftItem, giftItem, paymentMethod]);

    const { isInsufficeintCredits, isRedeemDisabled } =
        isSufficientCreditAvailable(
            paymentMethod,
            availableCredits,
            activeGiftItem.quantity,
            +giftItem?.number_of_tokens,
        );

    useEffect(() => {
        let interval = null;
        if (detail && detail?.nid) {
            const { nid } = detail;
            syncDownloadedStatus(nid);

            interval = setInterval(() => {
                syncDownloadedStatus(nid);
            }, 10000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [detail?.nid]);

    const isAutoPlayable = (type: string) =>
        type === "Video" ? "true" : "false";

    const { isOpen, showSnackbar } = useMiniPlayerOpen();

    const startResumeClick = () => {
        if (isOpen) showSnackbar();
        else if (nextStep) {
            if (isGiftTab) {
                history.push(
                    linkProvider.react
                        .gifts()
                        .courseMediaStepDetail(
                            detail.sku_id,
                            nextStep.sku_id,
                            "true",
                            activeCourse?.displayMode === 1,
                            queryParams?.segment,
                            "true",
                        ),
                );
            } else {
                history.push(
                    linkProvider.react
                        .courses()
                        .mediaStepDetail(
                            detail.sku_id,
                            nextStep.sku_id,
                            "true",
                            activeCourse?.displayMode === 1,
                            queryParams?.segment,
                        ),
                );
            }
        }
    };

    return (
        <Layout
            onBuyClick={onBuyConfirm}
            method={paymentMethod}
            isPurchasing={purchaseEnabled && !dialogs.transactionComplete}
            purchaseEnabled={purchaseEnabled}
            purchaseNavText={purchaseText}
            activeGiftItem={activeGiftItem}
            availableCredits={availableCredits}
            isBuyDisabled={
                purchaseEnabled && activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity < 1 || isRedeemDisabled
                    : false
            }
            dialogs={dialogs}
        >
            <AppHeader
                title={detail?.title}
                canGoBack={
                    purchaseEnabled &&
                    activeGiftItem?.giftPurchasedEnabled &&
                    dialogs?.selectMembers
                        ? false
                        : true
                }
                searchable={false}
                onBackClick={goBack}
            />

            {showLimitDialog && (
                <AppConfimationDialog
                    titleText={messaging?.downloadingQueue?.QueueLimitation}
                    showTitle={true}
                    open={showLimitDialog}
                    popupMainText={messaging?.downloadingQueue?.limitReached}
                    cancelButtonText="OK"
                    onClose={toggleLimitDialog}
                />
            )}

            <Grid container className={clsx(classes.root, className)}>
                {detail && !loading && !dialogs?.selectMembers && (
                    <>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                className={classes.greyA100}
                                fontWeight={"bold"}
                                variant="h6"
                            >
                                {detail.title}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.grey500} xs={12}>
                            <Typography variant="body1">
                                {detail.sku_id}
                            </Typography>
                            {!received && (
                                <Box display="flex">
                                    <Typography
                                        className={classes.release}
                                        variant="body1"
                                    >
                                        Released:
                                    </Typography>
                                    <Typography variant="body1">
                                        {moment
                                            .unix(
                                                +(activeCourse as Course)
                                                    .release_date,
                                            )
                                            .format("LL")}
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} className={classes.playerContainer}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                className={classes.dummyImageBox}
                            >
                                {!!thumbnail ? (
                                    <img
                                        alt={detail?.title}
                                        src={thumbnail}
                                        className={classes.mediaImage}
                                    />
                                ) : (
                                    <span className={classes.graphicWrapper}>
                                        <VideocamIcon
                                            className={classes.graphicIcon}
                                            sx={{
                                                fontSize: "60px",
                                            }}
                                        />
                                    </span>
                                )}
                            </Box>
                        </Grid>
                        {!purchaseEnabled && !loading && (
                            <>
                                {(received ||
                                    (activeCourse as Course)?.is_purchased ||
                                    (activeCourse as Course)?.publish_free) && (
                                    <Grid item xs={12}>
                                        <AppCompletionProgress
                                            loading={!observedCourseStats}
                                            title={`Course Completion ${
                                                progressVal <= 100
                                                    ? progressVal.toFixed(0)
                                                    : 100
                                            }%`}
                                            value={
                                                progressVal <= 100
                                                    ? progressVal
                                                    : 100
                                            }
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Box
                                        className={classes.iconsWrapper}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        {received ||
                                        (activeCourse as Course)
                                            ?.is_purchased ||
                                        (activeCourse as Course)
                                            ?.publish_free ? (
                                            <>
                                                <AskConfirmationDialog
                                                    popupMainText={
                                                        confirmationPopupMainText
                                                    }
                                                    confirmText={
                                                        confirmationPopupBtnText
                                                    }
                                                    cancelButtonText={"Cancel"}
                                                    onConfirm={() =>
                                                        onDownloadOrDeleteClick(
                                                            detail,
                                                            activeCourse,
                                                        )
                                                    }
                                                >
                                                    {(renderProps) => {
                                                        return (
                                                            <DownloadWarningDialog
                                                                onConfirm={() =>
                                                                    handleDownloadIconClick(
                                                                        renderProps,
                                                                    )
                                                                }
                                                            >
                                                                {(
                                                                    warningProps,
                                                                ) => (
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            if (
                                                                                askConfirmation &&
                                                                                !allDownloaded
                                                                            ) {
                                                                                warningProps.askConfirmation();
                                                                            } else {
                                                                                handleDownloadIconClick(
                                                                                    renderProps,
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <DownloadStatusIcon
                                                                            downloadStatus={
                                                                                courseDownloadedStatus
                                                                            }
                                                                            isCourse
                                                                            sizesLoading={
                                                                                sizesLoading
                                                                            }
                                                                            isSizeValid={
                                                                                isSizeValid
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                )}
                                                            </DownloadWarningDialog>
                                                        );
                                                    }}
                                                </AskConfirmationDialog>

                                                <div>
                                                    {progressVal >= 100 ? (
                                                        <Box display="flex">
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    fontSize:
                                                                        "18px",
                                                                    marginRight:
                                                                        "8px",
                                                                }}
                                                                className={
                                                                    classes.grey600
                                                                }
                                                                icon={[
                                                                    "fas",
                                                                    "circle-check",
                                                                ]}
                                                            />
                                                            <AppTypography
                                                                className={
                                                                    classes.completionText
                                                                }
                                                                variant="body1"
                                                            >
                                                                Completed
                                                            </AppTypography>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {!!activeCourse?.displayMode && (
                                                                <AppButton
                                                                    rounded
                                                                    buttonVariant="grey-outlined"
                                                                    onClick={
                                                                        startResumeClick
                                                                    }
                                                                >
                                                                    {progressVal <=
                                                                    0
                                                                        ? "Start"
                                                                        : "Resume"}
                                                                </AppButton>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <AppButton
                                                onClick={() =>
                                                    setPurchaseEnabled(true)
                                                }
                                                rounded
                                                buttonVariant="grey-outlined"
                                            >
                                                Buy
                                            </AppButton>
                                        )}
                                        <Box display={"flex"}>
                                            <IconButton
                                                onClick={() =>
                                                    setShowDesc(!showDesc)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    style={{
                                                        fontSize: "22px",
                                                    }}
                                                    className={classes.grey600}
                                                    icon={[
                                                        "fas",
                                                        "circle-info",
                                                    ]}
                                                />
                                            </IconButton>
                                            {(loadingGiftMemeberQuanity ||
                                                purchasableCoursesLoading) && (
                                                <IconButton>
                                                    <AppCircularProgress
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                            &nbsp;
                                            {!loadingGiftMemeberQuanity &&
                                                !purchasableCoursesLoading &&
                                                giftMemberQuanity > 0 && (
                                                    <>
                                                        <IconButton
                                                            onClick={(e) =>
                                                                setAnchorSendGift(
                                                                    e.currentTarget,
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                className={
                                                                    classes.icon
                                                                }
                                                                icon={[
                                                                    "far",
                                                                    "paper-plane",
                                                                ]}
                                                            />
                                                        </IconButton>
                                                        <AppMenuListing
                                                            id="gift-type-send-videos"
                                                            anchorEl={
                                                                anchorSendGift
                                                            }
                                                            open={openSendGift}
                                                            onClose={() =>
                                                                setAnchorSendGift(
                                                                    null,
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                (
                                                                    activeCourse as Course
                                                                )?.attach_skus,
                                                            ).map(
                                                                (
                                                                    filter,
                                                                    index,
                                                                ) =>
                                                                    giftTypeIdToString(
                                                                        filter,
                                                                    ) ===
                                                                        GiftsTypes.MEMBER && (
                                                                        <MenuList
                                                                            key={`${filter}`}
                                                                        >
                                                                            {index !==
                                                                                0 && (
                                                                                <AppDivider />
                                                                            )}
                                                                            <MenuItem
                                                                                onClick={(
                                                                                    e,
                                                                                ) =>
                                                                                    onGiftSendMenuItem(
                                                                                        e,
                                                                                        giftTypeIdToString(
                                                                                            filter,
                                                                                        ),
                                                                                        filter,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ListItemText>
                                                                                    {giftTypeIdToString(
                                                                                        filter,
                                                                                    )}
                                                                                </ListItemText>
                                                                                &nbsp;
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {!loadingGiftMemeberQuanity &&
                                                                                        !purchasableCoursesLoading && (
                                                                                            <AppTypography
                                                                                                variant="body2"
                                                                                                className={
                                                                                                    classes.giftQuanityWrapper
                                                                                                }
                                                                                            >
                                                                                                {giftTypeIdToString(
                                                                                                    filter,
                                                                                                ) ===
                                                                                                GiftsTypes.MEMBER
                                                                                                    ? giftMemberQuanity
                                                                                                    : 0}
                                                                                            </AppTypography>
                                                                                        )}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    ),
                                                            )}
                                                        </AppMenuListing>
                                                    </>
                                                )}
                                            {!received &&
                                                Object.keys(
                                                    (activeCourse as Course)
                                                        ?.attach_skus,
                                                )?.length > 0 && (
                                                    <>
                                                        <IconButton
                                                            className={clsx(
                                                                (loadingGiftMemeberQuanity ||
                                                                    purchasableCoursesLoading) &&
                                                                    classes.giftIconButtonDisabled,
                                                            )}
                                                            onClick={(e) => {
                                                                if (
                                                                    loadingGiftMemeberQuanity ||
                                                                    purchasableCoursesLoading
                                                                ) {
                                                                    enqueueSnackbar(
                                                                        "Fetching gift infomation.",
                                                                        {
                                                                            variant:
                                                                                "warning",
                                                                        },
                                                                    );
                                                                } else {
                                                                    setAnchorEl(
                                                                        e.currentTarget,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    fontSize:
                                                                        "22px",
                                                                }}
                                                                className={
                                                                    classes.grey600
                                                                }
                                                                icon={[
                                                                    "fas",
                                                                    "gift",
                                                                ]}
                                                            />
                                                        </IconButton>
                                                        <AppMenuListing
                                                            id="gift-type-menu-courses"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={() =>
                                                                setAnchorEl(
                                                                    null,
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                (
                                                                    activeCourse as Course
                                                                )?.attach_skus,
                                                            ).map(
                                                                (
                                                                    filter,
                                                                    index,
                                                                ) =>
                                                                    giftTypeIdToString(
                                                                        filter,
                                                                    ) ===
                                                                        GiftsTypes.MEMBER && (
                                                                        <MenuList
                                                                            key={`${filter}`}
                                                                        >
                                                                            {index !==
                                                                                0 && (
                                                                                <AppDivider />
                                                                            )}
                                                                            <MenuItem
                                                                                onClick={(
                                                                                    e,
                                                                                ) =>
                                                                                    onGiftMenuItemClick(
                                                                                        e,
                                                                                        giftTypeIdToString(
                                                                                            filter,
                                                                                        ),
                                                                                        filter,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ListItemText>
                                                                                    {giftTypeIdToString(
                                                                                        filter,
                                                                                    )}
                                                                                </ListItemText>
                                                                                &nbsp;&nbsp;
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {!loadingGiftMemeberQuanity &&
                                                                                        !purchasableCoursesLoading && (
                                                                                            <AppTypography
                                                                                                variant="body2"
                                                                                                className={
                                                                                                    classes.giftQuanityWrapper
                                                                                                }
                                                                                            >
                                                                                                {giftTypeIdToString(
                                                                                                    filter,
                                                                                                ) ===
                                                                                                GiftsTypes.MEMBER
                                                                                                    ? giftMemberQuanity
                                                                                                    : 0}
                                                                                            </AppTypography>
                                                                                        )}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    ),
                                                            )}
                                                        </AppMenuListing>
                                                    </>
                                                )}
                                        </Box>
                                    </Box>
                                </Grid>
                                {!showDesc &&
                                    sortedSteps.map((d, idx) => (
                                        <Grid item xs={12} key={idx}>
                                            <StepsListItem
                                                downloadStatus={(() =>
                                                    getStepDownloadStatus(
                                                        d.nid,
                                                    ))()}
                                                isResume={
                                                    observedCourseStats?.steps &&
                                                    observedCourseStats?.steps[
                                                        d.sku_id
                                                    ]?.analytics?.last_played >
                                                        0
                                                }
                                                isDisabled={
                                                    activeCourse.isSequenceOn &&
                                                    idx > seqPosition
                                                }
                                                isQuiz={
                                                    d.content_item_type_name ===
                                                    "quiz"
                                                }
                                                isCompleted={
                                                    observedCourseStats?.steps &&
                                                    observedCourseStats?.steps[
                                                        d.sku_id
                                                    ]?.analytics
                                                        ?.completed_count > 0
                                                }
                                                stepThumbnail={
                                                    stepsThumbnails &&
                                                    stepsThumbnails[d.nid]
                                                }
                                                step={d}
                                                isLocked={!withMedia}
                                                isAllowClick
                                                onListItemClick={() =>
                                                    handleListItemClick(
                                                        d,
                                                        isAutoPlayable(
                                                            d?.content_item_type_name,
                                                        ),
                                                    )
                                                }
                                                onPlayIconClick={() =>
                                                    handleListItemClick(
                                                        d,
                                                        isAutoPlayable(
                                                            d?.content_item_type_name,
                                                        ),
                                                    )
                                                }
                                            />
                                        </Grid>
                                    ))}

                                {showDesc && (
                                    <Grid item xs={12}>
                                        <AppTypography
                                            className={classes.grey600}
                                            variant="body1"
                                        >
                                            {detail.description}
                                        </AppTypography>
                                    </Grid>
                                )}
                            </>
                        )}
                    </>
                )}

                {purchaseEnabled && !loading && !dialogs?.selectMembers && (
                    <ContentPurchase
                        marginBottom={"30px"}
                        onBackClick={() => {
                            setPurchaseEnabled(false);
                            setActiveGiftItem({
                                title: "",
                                giftPurchasedEnabled: false,
                                giftPurchasedSuccess: false,
                                quantity: 1,
                            });
                        }}
                        buying={
                            purchasingContent ||
                            redeemingContent ||
                            redeemingGiftContent
                        }
                        cart={cart}
                        backTitle={
                            activeGiftItem?.giftPurchasedEnabled
                                ? activeGiftItem?.title
                                : "Course Purchase"
                        }
                        credits={availableCredits}
                        availableCreditsLoading={availableCreditsLoading}
                        requiredCredits={(
                            activeCourse as Course
                        )?.number_of_tokens.toString()}
                        method={paymentMethod}
                        activeGiftItem={activeGiftItem}
                        onSendContent={onSendContent}
                        selectedMembers={selectedMembers}
                        totalPrice={`$${parseFloat(
                            (activeCourse as Course)?.content_price,
                        ).toFixed(2)}`}
                        contentPrice={
                            activeGiftItem?.giftPurchasedEnabled
                                ? shortcutGift()?.content_price
                                : (activeCourse as Course)?.content_price
                        }
                        onMethodChange={(method) => {
                            if (
                                method === PaymentMethod.MY_CREDITS &&
                                (activeCourse as Course)
                                    ?.is_redeemable_by_tokens
                            ) {
                                setPaymentMethod(method);
                            } else {
                                if (method === PaymentMethod.MY_CREDITS) {
                                    enqueueSnackbar(
                                        messaging.redeeming.notRedeemable,
                                        { variant: "warning" },
                                    );
                                }
                            }

                            if (method === PaymentMethod.CARD) {
                                setPaymentMethod(method);
                            }
                        }}
                        dialogs={dialogs}
                        setDialogs={setDialogs}
                        onBuy={onBuyContent}
                        onBuyGift={onBuyGiftContent}
                        isRedemeableByToken={
                            (activeCourse as Course)?.is_redeemable_by_tokens
                        }
                        onCloseConfirmDialog={onCloseSelectMembers}
                        quantityLoading={loadingGiftMemeberQuanity}
                    />
                )}
                {purchaseEnabled &&
                    activeGiftItem?.giftPurchasedEnabled &&
                    !dialogs?.selectMembers && (
                        <GiftQuantity
                            activeGiftItem={activeGiftItem}
                            onQuantityChange={onQuantityChange}
                            method={paymentMethod}
                            credits={availableCredits}
                            isInsufficeintCredits={isInsufficeintCredits}
                        />
                    )}
                {purchaseEnabled &&
                    activeGiftItem?.giftPurchasedEnabled &&
                    dialogs?.selectMembers && (
                        <SelectMembersDialog
                            open={true}
                            searchable={true}
                            searchPlaceholder="Search by name or LTD ID"
                            searchQuery={searchQueryLos}
                            setSearchQuery={setSearchQueryLos}
                            selectedMembers={selectedMembers}
                            checkboxList={searchLosResults}
                            onToggleItemSelect={onToggleItemSelect}
                            onSend={onSendSelectedMembers}
                            onClose={onCloseSelectMembers}
                            giftSendLoading={sendingGifts}
                            availableQuantity={
                                activeGiftItem?.quantity -
                                    selectedMembers?.length >
                                0
                                    ? activeGiftItem?.quantity -
                                      selectedMembers?.length
                                    : 0
                            }
                        />
                    )}

                {loading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.course}
                    />
                )}
            </Grid>
        </Layout>
    );
};

const useDetailOpen = (received: boolean, contentId: string) => {
    const {
        purchasableCoursesForMembers,
        setGlobalCourses,
        courses: allCourses,
        isCourseUpdateRequired,
    } = useGlobalCourses();

    const {
        courses,
        loading: coursesLoading,
        loaded: coursesLoaded,
    } = useCourses(
        !allCourses || allCourses.length === 0,
        isCourseUpdateRequired,
    );

    const { setActiveCourse } = useActiveCourse();
    const {
        receivedCourses,
        loading: receivedCoursesLoading,
        loaded: receivedCoursesLoaded,
    } = useReceivedCourses();

    const courseLoaded = receivedCoursesLoaded && coursesLoaded;

    const activeCourse = useMemo(() => {
        if (received) {
            const active = receivedCourses?.find((c) => c.nid === contentId);
            setActiveCourse({
                type: ActiveCourseType.RECEIVED,
                course: active,
            });
            return active;
        }

        if (!received) {
            const active = courses?.find((c) => c.nid === contentId);
            setActiveCourse({
                type: ActiveCourseType.UNRECIEVED,
                course: active,
            });
            return active;
        }
    }, [received, receivedCourses, courses, contentId]);

    return {
        courses,
        purchasableCoursesForMembers,
        activeCourse,
        receivedCoursesLoading,
        coursesLoading,
        courseLoaded,
        setGlobalCourses,
    };
};

const usePurchaseHanlders = (
    contentId: string,
    activeCourse: Course | ReceivedCourse,
    activeGiftItem: ActiveGiftItemType,
    dialogs: DialogsType,
    courses: Course[],
    setGlobalCourses: (val: Course[]) => void,
    setDialogs: (val: DialogsType) => void,
    fetchGiftMemberQuanity: (
        skuId: any,
    ) => Promise<AppAlternativeResponse<GiftRemainingQuantity[]>>,
    purchasableCoursesForMembers: Course[],
) => {
    const [purchaseEnabled, setPurchaseEnabled] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        (activeCourse as Course)?.is_redeemable_by_tokens
            ? PaymentMethod.MY_CREDITS
            : PaymentMethod.CARD,
    );

    const {
        availableCredits,
        loading: availableCreditsLoading,
        getAvailableAudioCredits,
    } = useAvailableCredits();
    const { redeemByCredits, loading: redeemingContent } = useRedeemByCredits();
    const { redeemGiftByCredits, loading: redeemingGiftContent } =
        useRedeemGiftByCredits();

    const { addToCart, cart } = useAddContentToCart();
    const { purchaseByCard, loading: purchasingContent } = usePurchaseByCard();
    const { writeFBCourseStats } = useWriteCourseStatsFBFunc();

    const shortcutGift = () => {
        const giftSku = (activeCourse as Course)?.attach_skus[
            giftTypeStringToId(activeGiftItem?.title)
        ];
        return {
            ...purchasableCoursesForMembers?.find((x) => x.sku_id === giftSku),
            sku_id: giftSku,
        };
    };

    const onBuyGiftContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_COURSE,
                };
                redeemGiftByCredits(
                    shortcut?.sku_id,
                    activeGiftItem?.quantity,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete: true,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (activeCourse as Course)?.attach_skus[
                                        giftTypeStringToId(
                                            activeGiftItem?.title,
                                        )
                                    ],
                                );
                            }
                        }
                        getAvailableAudioCredits();
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
        if (paymentMethod === PaymentMethod.CARD) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_COURSE,
                };
                purchaseByCard(
                    cart.cartId,
                    activeGiftItem.quantity,
                    shortcut.sku_id,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete:
                                activeGiftItem?.giftPurchasedEnabled,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (activeCourse as Course)?.attach_skus[
                                        giftTypeStringToId(
                                            activeGiftItem?.title,
                                        )
                                    ],
                                );
                            }
                        }
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
    };

    const updatePurchaseState = () => {
        const toSaveCourse: Course[] = courses.map((course) => {
            if (course.nid === contentId) {
                const writeStatsToFB = async () => {
                    await writeFBCourseStats({
                        skuId: course.sku_id,
                        purchasedState: 2,
                        title: course.title,
                        contentType: "Course",
                    });
                };
                writeStatsToFB();
                return {
                    ...course,
                    is_purchased: true,
                };
            } else {
                return course;
            }
        });
        setGlobalCourses(toSaveCourse);
    };

    const onBuyContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: (activeCourse as Course)?.nid,
                contentTitle: (activeCourse as Course)?.title,
                category: EventCategories.COURSE,
            };
            redeemByCredits(
                (activeCourse as Course)?.sku_id,
                firebaseEvent,
            ).then((val) => {
                if (val.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: false,
                    });
                    updatePurchaseState();
                    getAvailableAudioCredits();
                    setPurchaseEnabled(false);
                }
            });
        }
        if (paymentMethod === PaymentMethod.CARD) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: (activeCourse as Course)?.nid,
                contentTitle: (activeCourse as Course)?.title,
                category: EventCategories.COURSE,
            };
            purchaseByCard(
                cart.cartId,
                1,
                (activeCourse as Course).sku_id,
                firebaseEvent,
            ).then((val) => {
                if (val.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: false,
                        transactionComplete:
                            activeGiftItem?.giftPurchasedEnabled,
                    });
                    updatePurchaseState();
                    setPurchaseEnabled(false);
                }
            });
        }
    };

    const onBuyConfirm = async () => {
        if (paymentMethod === PaymentMethod.CARD) {
            addToCart(
                activeGiftItem?.giftPurchasedEnabled
                    ? (activeCourse as Course)?.attach_skus[
                          giftTypeStringToId(activeGiftItem?.title)
                      ]
                    : (activeCourse as Course).sku_id,
                activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity
                    : 1,
            ).then((res) => {
                if (res?.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: true,
                    });
                }
            });
        } else {
            setDialogs({
                ...dialogs,
                transactionConfirmation: true,
            });
        }
    };

    useEffect(() => {
        setPaymentMethod(
            isRedeemable(activeCourse as Course, availableCredits)
                ? PaymentMethod.MY_CREDITS
                : PaymentMethod.CARD,
        );
    }, [
        (activeCourse as Course)?.is_redeemable_by_tokens,
        availableCredits,
        (activeCourse as Course)?.number_of_tokens,
    ]);

    return {
        cart,
        purchaseEnabled,
        availableCredits,
        availableCreditsLoading,
        redeemingContent,
        redeemingGiftContent,
        purchasingContent,
        paymentMethod,
        setPaymentMethod,
        setPurchaseEnabled,
        onBuyGiftContent,
        onBuyConfirm,
        onBuyContent,
        shortcutGift,
    };
};

const useGiftMemberQuantity = (
    received: boolean,
    activeCourse: Course | ReceivedCourse,
) => {
    const giftMembersQuntitySku = !received
        ? (activeCourse as Course)?.attach_skus[
              giftTypeStringToId(GiftsTypes.MEMBER)
          ]
            ? (activeCourse as Course)?.attach_skus[
                  giftTypeStringToId(GiftsTypes.MEMBER)
              ]
            : ""
        : "";

    const attachedskuLength = !received
        ? (activeCourse as Course) &&
          Object.keys((activeCourse as Course)?.attach_skus).length === 0
        : false;

    // Get Quantity By Attached skus
    const {
        giftQuantity: giftMemberQuanity,
        refetch: fetchGiftMemberQuanity,
        loading: loadingGiftMemeberQuanity,
    } = useGiftRemainingQuantity(giftMembersQuntitySku, attachedskuLength);

    return {
        giftMemberQuanity,
        fetchGiftMemberQuanity,
        loadingGiftMemeberQuanity,
    };
};

const useIndexHooks = (
    detail: ICourseDetail,
    activeCourse: Course | ReceivedCourse,
    courseLoaded: boolean,
    received: boolean,
    queryParams: {
        received: string;
        segment: string;
        isMixedContent: boolean;
    },
) => {
    const [thumbnail, setThumbnail] = useState<string>(null);
    const [stepsThumbnails, setStepsThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const { logFirebaseEvent } = useFirebaseLogger();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { writeFBCourseStats } = useWriteCourseStatsFBFunc();
    const { homeStack, setGlobalCoursesStack } = useGlobalNavStack();
    const { linkProvider } = useRouting();
    const history = useHistory();

    const { logGiftOpen } = useGiftDashboard(
        toSentGift(activeCourse),
        SentGiftType.COURSE,
        (activeCourse as ReceivedCourse)?.sender?.member_id,
        false,
    );

    const handleLogFirebaseEvents = (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => {
        if (type === "course") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: detail.title,
                nId: detail.nid,
                skuId: detail.sku_id,
                category: settings.category,
            });
        }
    };

    const onCourseOpen = async (detail: ICourseDetail) => {
        const courseStats: CourseStatsBody = {
            skuId: detail.sku_id,
            purchasedState: (activeCourse as Course).is_purchased ? 2 : 1,
            title: detail.title,
            contentType: "Course",
            steps: detail.steps.map((step) => ({
                sku_id: step.sku_id,
                position: step.position,
                title: step.title,
            })),
        };
        const updatedStats = await writeFBCourseStats(courseStats);

        if (updatedStats?.isCourseCompleted) {
            if (updatedStats.stats.type === "Course") {
                handleLogFirebaseEvents("course", {
                    action: EventActions.COMPLETED,
                    category: EventCategories.COURSE,
                    eventNames: EventNames.COURSE_COMPLETED,
                });
            }
            if (updatedStats.stats.type === "GiftCourse") {
                handleLogFirebaseEvents("course", {
                    action: EventActions.COMPLETED,
                    category: EventCategories.GIFT_COURSE,
                    eventNames: EventNames.GIFT_COURSE_COMPLETED,
                });
            }
        }

        if (activeCourse) {
            const { nid, sku_id, title, isReceived } = activeCourse;
            const action: FirebaseEventAction = {
                action: EventActions.OPEN,
                nId: nid,
                contentTitle: title,
                skuId: sku_id,
                category: isReceived
                    ? EventCategories.GIFT_COURSE
                    : EventCategories.COURSE,
            };
            logFirebaseEvent(
                isReceived
                    ? EventNames.GIFT_COURSE_OPENED
                    : EventNames.COURSE_OPENED,
                action,
            );
            if (isReceived) logGiftOpen();
        }
    };

    const fetchThumbnails = async () => {
        if (!thumbnail) {
            const { image } = detail;
            const thumb = await getPublicUrl(
                image.image_url_prefix,
                image.image_url_postfix,
                image.image_file_name,
            );

            setThumbnail(thumb);
        }

        detail.steps.forEach(async (s) => {
            if (
                !stepsThumbnails ||
                (stepsThumbnails && !stepsThumbnails[s.nid])
            ) {
                const thumb = await getPublicUrl(
                    s.media_data[0].image.image_url_prefix,
                    s.media_data[0].image.image_url_postfix,
                    s.media_data[0].image.image_file_name,
                );

                setStepsThumbnails((prev) => ({
                    ...prev,
                    [s.nid]: thumb,
                }));
            }
        });
    };

    const goBack = () => {
        if (!queryParams?.isMixedContent) {
            if (!homeStack?.isHomeTrack) {
                if (received) {
                    if (!!queryParams.segment) {
                        history.push(
                            linkProvider.react
                                .courses()
                                .index(queryParams?.segment),
                        );
                    } else {
                        history.push(
                            linkProvider.react
                                .gifts()
                                .listing(
                                    GiftCategories.RECEIVED_AS_MEMBER,
                                    AllTabs.COURSES,
                                ),
                        );
                    }
                } else {
                    history.push(
                        linkProvider.react
                            .courses()
                            .index(queryParams?.segment),
                    );
                }
            } else {
                if (homeStack?.isHomeTrack) {
                    history.push(
                        linkProvider.react
                            .courses()
                            .index(queryParams?.segment),
                    );
                } else {
                    history.goBack();
                }
            }
        } else {
            history.push(linkProvider.react.mixedContent().index());
        }
    };

    const handleNavigateToQueue = () => {
        const payload = {
            lastVisited: `${history.location.pathname}${history.location.search}`,
        };
        setGlobalCoursesStack(payload);
        history.push(linkProvider.react.downloadingQueue().index("Course"));
    };

    useEffect(() => {
        if (detail) onCourseOpen(detail);
    }, [detail]);

    useEffect(() => {
        if (!activeCourse && courseLoaded) {
            goBack();
        }
    }, [detail, courseLoaded]);

    useEffect(() => {
        if (detail) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [detail]);

    return {
        history,
        linkProvider,
        thumbnail,
        stepsThumbnails,
        goBack,
        handleNavigateToQueue,
    };
};

const useObservedStats = (detail: ICourseDetail) => {
    const { observedCourseStats } = useObserveCourseStats(detail?.sku_id);
    const progressVal = calculatePercentage(
        observedCourseStats?.analytics?.completed_steps,
        observedCourseStats?.analytics?.total_steps,
    );

    const sortedSteps = detail ? sortStepsInDetail(detail.steps) : [];

    let seqPosition = 0;
    const completedSteps = observedCourseStats
        ? observedCourseStats.analytics.completed_steps
        : 0;
    if (observedCourseStats && observedCourseStats.steps) {
        sortedSteps &&
            sortedSteps.every((item, idx) => {
                if (
                    (observedCourseStats?.steps.hasOwnProperty(item?.sku_id) &&
                        observedCourseStats.steps[item?.sku_id].analytics
                            .completed_count > 0) ||
                    completedSteps === detail?.steps.length
                ) {
                    seqPosition = idx;
                    return true;
                } else {
                    seqPosition = idx;
                    return false;
                }
            });
    }

    const nextStep = sortedSteps?.find((d) => +d.position === seqPosition);

    return {
        observedCourseStats,
        progressVal,
        completedSteps,
        sortedSteps,
        nextStep,
        seqPosition,
    };
};

const useUserGifts = (
    activeCourse: Course | ReceivedCourse,
    dialogs: DialogsType,
    activeGiftItem: ActiveGiftItemType,
    giftMemberQuanity: number,
    received: boolean,
    purchasableCoursesForMembers: Course[],
    setActiveGiftItem: (val: ActiveGiftItemType) => void,
    setDialogs: (val: DialogsType) => void,
    setPurchaseEnabled: (val: boolean) => void,
    fetchGiftMemberQuanity: (
        skuId: any,
    ) => Promise<AppAlternativeResponse<GiftRemainingQuantity[]>>,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorSendGift, setAnchorSendGift] = useState<null | HTMLElement>(
        null,
    );
    const [giftItem, setGiftItem] = useState<Course>(null);
    const openSendGift = Boolean(anchorSendGift);

    const { sendGiftToUser, loading: sendingGifts } = useSendGifts();

    const onSendContent = () => {
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            selectMembers: true,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            quantity: giftMemberQuanity,
        });
    };

    const onQuantityChange = (event, action) => {
        if (action === "INCREMENT") {
            setActiveGiftItem({
                ...activeGiftItem,
                quantity: activeGiftItem?.quantity + 1,
            });
        } else {
            setActiveGiftItem({
                ...activeGiftItem,
                quantity:
                    activeGiftItem?.quantity === 1
                        ? 1
                        : activeGiftItem?.quantity - 1,
            });
        }
    };

    const onGiftMenuItemClick = (e, item, skuId) => {
        const selectedItem =
            purchasableCoursesForMembers &&
            purchasableCoursesForMembers.find(
                (x) =>
                    x.sku_id === (activeCourse as Course)?.attach_skus[skuId],
            );
        if (!received && selectedItem) {
            setGiftItem(selectedItem);
            setPurchaseEnabled(true);
            setActiveGiftItem({
                title: item,
                giftPurchasedEnabled: true,
                giftPurchasedSuccess: false,
                quantity: 1,
            });
            setAnchorEl(null);
        } else {
            enqueueSnackbar(messaging.common.noAccess, {
                variant: "warning",
            });
        }
    };

    const onToggleItemSelect = (value) => {
        if (
            selectedMembers.findIndex(
                (x) => x.member_id === value.member_id,
            ) !== -1
        ) {
            let tempArray = [...selectedMembers];
            tempArray.splice(
                tempArray.findIndex((x) => x.member_id === value.member_id),
                1,
            );
            setSelectedMembers(tempArray);
        } else {
            if (selectedMembers.length < giftMemberQuanity) {
                setSelectedMembers([...selectedMembers, value]);
            } else {
                enqueueSnackbar(messaging.gifts.inSufficientGiftQuantity, {
                    variant: "warning",
                });
            }
        }
    };

    const onCloseSelectMembers = () => {
        setPurchaseEnabled(false);
        setSelectedMembers([]);
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            transactionConfirmation: false,
            selectMembers: false,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            giftPurchasedEnabled: false,
            title: "",
            quantity: 1,
            giftPurchasedSuccess: false,
        });
    };

    const onGiftSendMenuItem = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        item: string,
        id: string,
    ) => {
        if (item === GiftsTypes.MEMBER && giftMemberQuanity > 0) {
            setPurchaseEnabled(true);
            setActiveGiftItem({
                ...activeGiftItem,
                title: GiftsTypes.MEMBER,
                quantity: giftMemberQuanity,
                giftPurchasedEnabled: true,
            });
            setDialogs({
                ...dialogs,
                transactionComplete: false,
                selectMembers: true,
            });
            setAnchorSendGift(null);
        } else {
            enqueueSnackbar(messaging.common.noAccess, {
                variant: "warning",
            });
        }
    };

    const onSendSelectedMembers = async () => {
        if (selectedMembers.length >= 1) {
            if (activeGiftItem.title === GiftsTypes.MEMBER) {
                sendGiftToUser(
                    [
                        {
                            sku: (activeCourse as Course)?.attach_skus[
                                giftTypeStringToId(activeGiftItem.title)
                            ],
                            nid: activeCourse?.nid,
                            title: activeCourse?.title,
                            category: EventCategories.GIFT_COURSE,
                        },
                    ],
                    selectedMembers,
                ).then((val) => {
                    if (val?.response?.status === true) {
                        fetchGiftMemberQuanity(
                            (activeCourse as Course)?.attach_skus[
                                giftTypeStringToId(activeGiftItem.title)
                            ],
                        );
                        onSendItemsClose();
                    }
                });
            }
        } else {
            enqueueSnackbar("Please select at-least one LOS Member.", {
                variant: "warning",
            });
        }
    };

    const onSendItemsClose = () => {
        setPurchaseEnabled(true);
        setDialogs({
            ...dialogs,
            transactionComplete: true,
            transactionConfirmation: false,
            selectMembers: false,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            giftPurchasedEnabled: false,
            title: "",
            quantity: 1,
            giftPurchasedSuccess: true,
        });
    };

    return {
        anchorEl,
        anchorSendGift,
        openSendGift,
        selectedMembers,
        sendingGifts,
        giftItem,
        setAnchorEl,
        setAnchorSendGift,
        onGiftSendMenuItem,
        onGiftMenuItemClick,
        onSendContent,
        onQuantityChange,
        onToggleItemSelect,
        onCloseSelectMembers,
        onSendSelectedMembers,
    };
};

const useCourseSizeAndLimits = (detail: ICourseDetail, withMedia: boolean) => {
    const [showLimitDialog, setShowLimitDialog] = useState(false);

    const toCalculateSizes = useMemo(() => {
        if (detail && detail.steps && withMedia) {
            const params = detail.steps
                .filter((d) => d.content_item_type_name !== "quiz")
                .map((x) => {
                    const media = x.media_data[0].media;

                    return {
                        key: `${media.media_url_postfix}/${media.media_file_name}`,
                        bucket: media.media_url_prefix,
                    };
                });

            return params;
        }

        return [];
    }, [detail]);

    const { sizes, loading: sizesLoading } = useMultipleFileSizes(
        withMedia ? toCalculateSizes : null,
    );

    const isSizeValid = useMemo(() => {
        if (sizes && sizes.length) {
            const allSizes = sizes.map((x) => x.size);
            return !isAndroidDevice()
                ? allSizes.every(
                      (x) =>
                          x <= config?.downloading?.maxDownloadingSize &&
                          x !== 0,
                  )
                : allSizes.every((x) => x !== 0);
        }

        return false;
    }, [sizes]);

    const toggleLimitDialog = () => {
        setShowLimitDialog(!showLimitDialog);
    };

    return {
        sizesLoading,
        isSizeValid,
        showLimitDialog,
        toggleLimitDialog,
    };
};

const useDownloadHandlers = (
    allDownloaded: boolean,
    isSizeValid: boolean,
    sizesLoading: boolean,
    courseDownloadedStatus: StepListDownloadType,
    toggleLimitDialog: () => void,
    handleNavigateToQueue: () => void,
) => {
    const { addCourseInQueue } = useCourseDownloadQueue();
    const { deleteCompleteCourse } = useDeleteCourse();
    const { enqueueSnackbar } = useSnackbar();

    const isProcessing = useMemo(() => {
        return ["in-progress", "queued"].includes(courseDownloadedStatus);
    }, [courseDownloadedStatus]);

    const askConfirmation = !isProcessing && !sizesLoading && isSizeValid;

    const onDownloadOrDeleteClick = (
        detail: ICourseDetail,
        course: Course | ReceivedCourse,
    ) => {
        if (!allDownloaded) {
            handleDownloadClick(detail, course);
        }

        if (allDownloaded) {
            handleDeleteClick(detail);
        }
    };

    const handleDownloadClick = (
        detail: ICourseDetail,
        course: Course | ReceivedCourse,
    ) => {
        if (course && detail) {
            if (isSizeValid) {
                addCourseInQueue(
                    {
                        ...detail,
                        memberId: config.user.memberId,
                        releaseDate: course.release_date,
                        isSequenceOn: course.isSequenceOn,
                        displayMode: course.displayMode === 1,
                        isPurchased: (course as Course).is_purchased,
                    },
                    toggleLimitDialog,
                );
            }
        }
    };

    const handleDeleteClick = (detail: ICourseDetail) => {
        deleteCompleteCourse(detail.nid);
    };

    const handleDownloadIconClick = (
        renderProps: AskConfirmationDialogRenderProps,
    ) => {
        if (askConfirmation) {
            renderProps.askConfirmation();
        } else {
            if (!isSizeValid) {
                enqueueSnackbar(messaging.download.courseSizeInvalid, {
                    variant: "info",
                });
            }

            if (isProcessing) {
                handleNavigateToQueue();
            }
        }
    };

    return {
        askConfirmation,
        onDownloadOrDeleteClick,
        handleDownloadIconClick,
    };
};

const useDownloadedStatus = (course: ICourseDetail) => {
    const { userDownloadedCourses } = useDownloadedCourses();
    const { refetch: getStepFromDB } = useStepFromDb();

    const dispatch = useAppDispatch();
    const activeDownloaded = userDownloadedCourses.find(
        (x) => x.nid === course?.nid,
    );

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );

    const downloadedSteps = useMemo(() => {
        return getDownloadedSteps(activeDownloaded);
    }, [activeDownloaded]);

    const allDownloaded = useMemo(() => {
        return (
            course &&
            course.steps &&
            downloadedSteps.length === course.steps.length
        );
    }, [course, downloadedSteps]);

    const downloadedStepNids = downloadedSteps?.map((s) => s.nid);

    const getCourseDownloadStatus = (): StepListDownloadType => {
        if (course && course.steps) {
            const stepIds = course.steps
                .filter((x) => x.content_item_type_name !== "quiz")
                .map((s) => s.nid);

            const stepInQueue = stepIds.some((x) =>
                isInDownloadQueue(getCombinedCrsStepNid(course.nid, x)),
            );
            const downloadInProgress = stepIds.some((x) =>
                isDownloadInProgress(getCombinedCrsStepNid(course.nid, x)),
            );

            if (allDownloaded) return "downloaded";
            if (stepInQueue || downloadInProgress) return "in-progress";
        }

        return null;
    };

    const getStepDownloadStatus = (
        stepId: CourseStep["nid"],
    ): StepListDownloadType => {
        if (course) {
            const combinedId = getCombinedCrsStepNid(course.nid, stepId);
            if (checkStepDownloaded(stepId)) return "downloaded";
            if (inProgressNid === combinedId) return "in-progress";
            if (isInDownloadQueue(combinedId)) return "queued";
        }

        return null;
    };

    const checkStepDownloaded = (stepNid: CourseStep["nid"]) => {
        if (downloadedStepNids.includes(stepNid)) return true;

        return false;
    };

    const syncDownloadedStatus = (activeCourseNid) => {
        const activeDownloadedCourse = userDownloadedCourses.find(
            (x) => x.nid === activeCourseNid,
        );

        if (activeDownloadedCourse?.steps?.length) {
            activeDownloadedCourse.steps.forEach(async (step: CourseStep) => {
                const downloadedId = createCourseIDBId(
                    config.user.memberId,
                    course.nid,
                    step.nid,
                );
                const downloadedStep = await getStepFromDB(downloadedId);
                const downloadedCourses =
                    store.getState().downloadedCourse.downloadedCourses;
                const downloadStatus =
                    downloadedStep || step?.content_item_type_name === "quiz"
                        ? true
                        : false;
                const newCourses = markStepDownloadedStatus(
                    downloadedCourses,
                    activeDownloadedCourse?.nid,
                    step?.nid,
                    downloadStatus,
                );
                dispatch(setGlobalDownloadedCourses(newCourses));
            });
        }
    };

    const courseDownloadedStatus = getCourseDownloadStatus();

    const confirmationPopupBtnText =
        courseDownloadedStatus === "downloaded" ? "Delete" : "Download";
    const confirmationPopupMainText =
        courseDownloadedStatus === "downloaded"
            ? messaging.confirmation.courseDelete
            : messaging.confirmation.courseDownload;

    return {
        allDownloaded,
        courseDownloadedStatus,
        confirmationPopupBtnText,
        confirmationPopupMainText,
        getStepDownloadStatus,
        syncDownloadedStatus,
    };
};

const useQueryParamsAndStrings = () => {
    const location = useLocation();
    const params: any = useParams();

    const { id: contentId } = params;

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            received = "",
            segment = "",
            giftTab = "",
            isMixedContent = false,
        } = parsedQueryString;

        return {
            received: received ? received.toString() : null,
            segment: segment ? segment.toString() : null,
            giftTab: giftTab ? giftTab.toString() : null,
            isMixedContent: !!isMixedContent ? true : false,
        };
    }, [location?.search]);

    const received = queryParams.received === "true";
    const isGiftTab = queryParams.giftTab === "true";
    return {
        queryParams,
        received,
        contentId,
        isGiftTab,
    };
};

const useHandlers = (
    activeCourse: Course | ReceivedCourse,
    detail: ICourseDetail,
    segment: string,
    isGiftTab: boolean,
    isMixedContent: boolean,
) => {
    const history = useHistory();
    const { linkProvider } = useRouting();

    const handleListItemClick = (step: CourseStep, isAutoPlay: string) => {
        if (!isMixedContent) {
            if (isGiftTab) {
                history.push(
                    linkProvider.react
                        .gifts()
                        .courseMediaStepDetail(
                            detail.sku_id,
                            step.sku_id,
                            isAutoPlay,
                            activeCourse?.displayMode === 1,
                            segment,
                            "true",
                        ),
                );
            } else {
                history.push(
                    linkProvider.react
                        .courses()
                        .mediaStepDetail(
                            detail.sku_id,
                            step.sku_id,
                            isAutoPlay,
                            activeCourse?.displayMode === 1,
                            segment,
                        ),
                );
            }
        } else {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .courseMediaStepDetail(
                        detail.sku_id,
                        step.sku_id,
                        isAutoPlay,
                        activeCourse?.displayMode === 1,
                        segment,
                        { isMixedContent: true },
                    ),
            );
        }
    };

    return {
        handleListItemClick,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        release: {
            marginRight: theme.spacing(1),
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        iconsWrapper: {
            margin: theme.spacing(1.5, 0),
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        completionText: {
            color: theme.palette.grey[600],
            textAlign: "center",
            fontWeight: 500,
        },
    }),
);

type DialogsType = {
    transactionComplete: boolean;
    transactionConfirmation: boolean;
    selectMembers: boolean;
};

export type ActiveGiftItemType = {
    title: string;
    giftPurchasedEnabled: boolean;
    giftPurchasedSuccess: boolean;
    quantity: number;
};

export interface CourseDetailProps extends InternalStandardProps<GridProps> {}
