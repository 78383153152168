import { useState, useEffect } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FallbackWaveform from "Images/Content/FallbackWaveform.png";
import giftReceivedIcon from "Images/gifts_received.png";
import moment from "moment";

import { AppButton } from "./AppButton";
import { AppDownloadingIcons } from "./Icon/AppDownloadingIcons";
import { RenderIf } from "./Wrapper/RenderIf";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default} !important`,
        },
        flexVerticalCentered: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer !important",
        },
        contentCard: {
            marginBottom: "15px",
            paddingLeft: "10px",
            paddingRight: "20px",
            position: "relative",
            "&:last-child": {
                marginBottom: "20px",
            },
        },
        actions: {
            position: "absolute",
            top: "16px",
            right: "20px",
        },
        actionsbtn: {
            position: "absolute",
            top: "16px",
            right: "2px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        cardContent: {
            width: "75%",
        },
        skuId: {
            fontSize: "14px",
            color: theme.palette.grey[500],
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        [theme.breakpoints.down("md")]: {
            cardContent: {
                width: "70%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            cardContent: {
                width: "70%",
            },
        },
        shortContent: {
            width: "60%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        btnContentPrice: {
            height: "35px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderRadius: "16px",
        },
        chip: {
            width: "70px",
            color: theme.palette.grey[800],
            borderWidth: "2px",
            borderColor: theme.palette.grey[800],
        },
        numberOfTokens: {
            marginTop: "20px",
            color: theme.palette.grey[800],
            fontSize: "15px",
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
        purchasedQuantity: {
            position: "absolute",
            width: "25px",
            height: "25px",
            borderRadius: "100px",
            backgroundColor: theme.palette.grey[900],
            fontSize: "12px",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: "5px",
            right: "14px",
        },
        imageContainer: {
            width: "80px",
            display: "flex",
            justifyContent: "center",
            position: "relative",
        },
        boldGrey: {
            fontSize: "12px",
            color: theme.palette.grey[600],
            fontWeight: "bold",
        },
        singleLineDescription: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 0,
            right: 10,
        },
        resumePlay: {
            color: theme.palette.primary.main,
        },
        iconButton: {
            padding: 0,
        },
        lowOpacity: {
            opacity: "50%",
        },
    }),
);

export type AppContentListItemType =
    | "media"
    | "courses"
    | "purchasableCourses"
    | "gift"
    | "receivedGift";

export interface AppContentListItemProps
    extends InternalStandardProps<GridProps> {
    isAllowClick?: boolean;
    onListItemClick?: () => void;
    onItemPlayClick?: () => void;
    imageUrl: string;
    type: AppContentListItemType;
    contentPrice?: string;
    isRedeemableByToken?: string;
    numberOfTokens?: string;
    title: string;
    skuId: string;
    description: string;
    showPlayIcon?: boolean;
    showFavIcon?: boolean;
    isFavourite?: boolean;
    //Course Related Props
    isPurchased?: boolean;
    isResume?: boolean;
    creditsRequired?: number;
    publishFree?: boolean;
    isReceived?: boolean;
    isDownloadInProgress?: boolean;
    isInDownloadQueue?: boolean;
    isDownloaded?: Promise<boolean>;
    inProgressDownloaded?: boolean;
    giftChecked?: boolean;
    onGiftCheckChanged?: (nid: string, checked: boolean) => void;
    selectMode?: boolean;
    purchasedQuantity?: string;
    sortType?: string;
    releaseDate?: string;
    acquiredDate?: string;
    played?: string;
    sender?: {
        first_name?: string;
        last_name?: string;
        sending_date?: string;
    };
    attachedSku?: {
        prospect_sku_id?: string;
        member_sku_id?: string;
    };
    isGiftGiven?: boolean;
    disabled?: boolean;
    nid: string;
}

export const AppContentListItem = (props: AppContentListItemProps) => {
    const classes = useStyles(props);
    const {
        isAllowClick,
        onListItemClick,
        imageUrl,
        title,
        skuId,
        description,
        showPlayIcon,
        showFavIcon,
        isFavourite,
        //Course Related Props
        publishFree,
        isPurchased,
        creditsRequired,
        isReceived,
        contentPrice,
        isRedeemableByToken,
        numberOfTokens,
        isDownloadInProgress,
        isInDownloadQueue,
        isDownloaded,
        inProgressDownloaded,
        type,
        giftChecked,
        onGiftCheckChanged,
        selectMode,
        purchasedQuantity,
        sender,
        sortType,
        releaseDate,
        acquiredDate,
        played,
        attachedSku,
        isResume,
        isGiftGiven = false,
        onItemPlayClick,
        disabled,
        nid,
    } = props;

    const [isMediaDownloaded, setIsMediaDownloaded] = useState<boolean>(false);
    const { handlePlayIconClick } = useHandlers(isAllowClick, onItemPlayClick);

    const toggleGiftChecked = () => onGiftCheckChanged(nid, !giftChecked);
    const showDate = () => {
        if (sortType) {
            if (sortType === "acquired_date") {
                if (acquiredDate && acquiredDate !== "0")
                    return `${formatDate(+acquiredDate)}`;
                else return "";
            }
            if (sortType === "played") {
                if (played && played !== "0")
                    return `${formatDateTime(+played)}`;
                else return "";
            } else if (releaseDate && releaseDate !== "0")
                return `${formatDate(+releaseDate)}`;
            else return "";
        } else if (releaseDate && releaseDate !== "0")
            return `${formatDate(+releaseDate)}`;
        else return "";
    };

    const giftingAvailable = () => {
        if (attachedSku) {
            if (Object.keys(attachedSku).length === 1) {
                if (attachedSku.prospect_sku_id) {
                    return "Gift Available (P)";
                } else {
                    return "Gift Available (M)";
                }
            } else if (Object.keys(attachedSku).length === 2) {
                return "Gift Available (M, P)";
            }
        } else {
            return "";
        }
    };

    const formatDate = (date: number) => {
        return moment.unix(date).format("ll");
    };
    const formatDateTime = (date: number) => {
        return `${moment.unix(date).format("ll")} @ ${moment
            .unix(date)
            .format("hh:mma")}`;
    };

    useEffect(() => {
        if (!!inProgressDownloaded) {
            setIsMediaDownloaded(true);
        }
        if (!!isDownloaded)
            isDownloaded
                ?.then((res) => {
                    setIsMediaDownloaded(res);
                })
                .catch((err) => {
                    setIsMediaDownloaded(false);
                });
        else setIsMediaDownloaded(false);
    }, [isDownloaded, type, inProgressDownloaded, isDownloadInProgress]);

    const onCardClick = () => {
        if (selectMode) {
            toggleGiftChecked();
        } else {
            if (isAllowClick) {
                onListItemClick();
            }
        }
    };

    return (
        <Card
            onClick={onCardClick}
            elevation={0}
            className={clsx(
                classes.root,
                classes.flexVerticalCentered,
                classes.contentCard,
                disabled && classes.lowOpacity,
            )}
        >
            <Box className={clsx(classes.imageContainer)}>
                <img
                    src={imageUrl || FallbackWaveform}
                    style={{ width: "60px", height: "auto" }}
                    alt="thumbnail"
                />
                {!!purchasedQuantity && type === "gift" && (
                    <Typography className={clsx(classes.purchasedQuantity)}>
                        {purchasedQuantity}
                    </Typography>
                )}
            </Box>
            <CardContent
                className={clsx(
                    classes.cardContent,
                    type === "purchasableCourses" && classes.shortContent,
                )}
            >
                <Typography
                    className={clsx(
                        classes.title,
                        classes.grey600,
                        classes.singleLineDescription,
                    )}
                >
                    {title || ""}
                </Typography>
                {type !== "receivedGift" && (
                    <Typography className={classes.skuId}>
                        {skuId || ""}
                    </Typography>
                )}
                <Typography className={classes.skuId}>
                    {isGiftGiven ? `Sent Date: ${showDate()}` : showDate()}
                </Typography>
                <Typography className={classes.skuId}>
                    {giftingAvailable()}
                </Typography>
                <Typography
                    className={clsx(
                        type === "receivedGift" || type === "purchasableCourses"
                            ? classes.singleLineDescription
                            : classes.clampText,
                        classes.grey600,
                    )}
                    fontSize={"12px"}
                >
                    {description || ""}
                </Typography>
                {(((type === "courses" || type === "media") && isReceived) ||
                    type === "receivedGift") && (
                    <Box display="flex">
                        <Box
                            marginRight={"10px"}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                width={30}
                                height={30}
                                src={giftReceivedIcon}
                                alt={"gift-received-icon"}
                            />
                        </Box>
                        {sender && (
                            <Box>
                                {sender?.sending_date && (
                                    <Typography
                                        className={clsx(classes.boldGrey)}
                                    >
                                        {moment(sender?.sending_date).format(
                                            "LL",
                                        )}
                                    </Typography>
                                )}
                                <Typography className={clsx(classes.boldGrey)}>
                                    From: {sender?.first_name || ""}{" "}
                                    {sender?.last_name || ""}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </CardContent>

            {type === "purchasableCourses" && (
                <Box
                    className={clsx(classes.actions)}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent="center"
                >
                    {+contentPrice > 0 && (
                        <Chip
                            className={clsx(classes.chip)}
                            label={`$${parseFloat(contentPrice).toFixed(2)}`}
                            variant="outlined"
                        />
                    )}
                    {isRedeemableByToken === "1" && (
                        <Typography className={clsx(classes.numberOfTokens)}>
                            {numberOfTokens || ""} Credit
                            {+numberOfTokens > 1 ? "s" : ""}
                        </Typography>
                    )}
                </Box>
            )}

            <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                flexDirection={"column"}
                className={clsx(classes.actionsbtn)}
            >
                {type === "courses" &&
                    !publishFree &&
                    !isPurchased &&
                    !isReceived && (
                        <AppButton
                            className={classes.btnContentPrice}
                            rounded
                            buttonVariant="grey-outlined"
                        >
                            {`$ ${parseFloat(contentPrice).toFixed(2)}`}
                        </AppButton>
                    )}

                {type === "courses" &&
                    !publishFree &&
                    !isReceived &&
                    isRedeemableByToken === "1" &&
                    !isPurchased && (
                        <AppButton
                            className={classes.btnContentPrice}
                            buttonVariant="grey-text"
                        >
                            {creditsRequired} Credit
                            {+creditsRequired > 1 && "s"}
                        </AppButton>
                    )}
            </Box>

            <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                flexDirection={"column"}
                className={clsx(classes.actions)}
            >
                <RenderIf isTrue={type === "media" || type === "receivedGift"}>
                    <FontAwesomeIcon
                        style={{
                            fontSize: "22px",
                            marginBottom: "15px",
                            visibility: showFavIcon ? "visible" : "hidden",
                        }}
                        className={classes.grey600}
                        icon={[isFavourite ? "fas" : "far", "star"]}
                    />
                    <IconButton
                        className={classes.iconButton}
                        onClick={handlePlayIconClick}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: "22px",
                                marginBottom: "15px",
                                visibility: showPlayIcon ? "visible" : "hidden",
                            }}
                            className={clsx(
                                isResume ? classes.resumePlay : classes.grey600,
                            )}
                            icon={["fas", "play"]}
                        />
                    </IconButton>
                </RenderIf>
                <>
                    {isDownloadInProgress && (
                        <AppDownloadingIcons iconType="in-progress" />
                    )}
                    {isInDownloadQueue && (
                        <AppDownloadingIcons iconType="queued" />
                    )}
                    {isMediaDownloaded && (
                        <FontAwesomeIcon
                            style={{
                                fontSize: "22px",
                            }}
                            className={classes.grey600}
                            icon={["far", "floppy-disk"]}
                        />
                    )}
                </>
                {
                    /*type === "gift" &&*/ selectMode && (
                        <>
                            <Checkbox
                                checked={giftChecked}
                                icon={
                                    <RadioButtonUnchecked
                                        className={clsx(classes.checkedIcon)}
                                    />
                                }
                                checkedIcon={
                                    <CheckCircleOutline
                                        className={clsx(classes.checkedIcon)}
                                    />
                                }
                            />
                        </>
                    )
                }
            </Box>
        </Card>
    );
};

const useHandlers = (isAllowClick: boolean, onItemPlayClick?: () => void) => {
    const handlePlayIconClick = (e) => {
        if (isAllowClick && onItemPlayClick) {
            e.stopPropagation();
            onItemPlayClick();
        }
    };
    return { handlePlayIconClick };
};
