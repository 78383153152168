import React, { useCallback, useEffect, useMemo, useState } from "react";
import qs from "qs";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Grid, InternalStandardProps, Theme } from "@mui/material";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    useActiveCourse,
    useCourses,
    useGlobalCourses,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { useRouting } from "JS/React/Hooks/Routes";
import { useHistory, useLocation } from "react-router-dom";
import { ActiveCourseType } from "JS/Redux/Course";
import { Course, ReceivedCourse } from "JS/Models";
import { HomeSegment } from "JS/React/Hooks/NavigationEngine";
import { AppNoDataFound } from "JS/React/Components/AppNoDataFound";
import { messaging } from "JS/Helpers/UserMessaging";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { useScrollToTop, useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { getPublicUrl } from "JS/Helpers";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useDownloadedCourses } from "JS/React/Hooks/DownloadCourses";

export const Courses = (props: CoursesProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const { courses: allCourses, isCourseUpdateRequired } = useGlobalCourses();
    const { courses, loading: coursesLoading } = useCourses(
        !allCourses || allCourses.length === 0,
        isCourseUpdateRequired,
    );
    const { setActiveCourse } = useActiveCourse();
    useSeamlessUpdate();
    const { receivedCourses, loading: receivedCoursesLoading } =
        useReceivedCourses();

    const { linkProvider } = useRouting();
    const history = useHistory();
    const location = useLocation();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { downloadedCourses, checkIsCrsDownloaded } = useDownloadStatus(
        courses,
        receivedCourses,
    );

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { segment = "" } = parsedQueryString;

        return {
            segment: segment.toString(),
        };
    }, [location?.search]);
    const { coursesStack, setGlobalCoursesStack, homeStack } =
        useGlobalNavStack();

    const [searchQuery, setSearchQuery] = useState(coursesStack?.searchQuery);
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 10,
        total: 0,
    });

    const paramTab = useMemo(() => {
        if (queryParams.segment === HomeSegment.MY) {
            return Tabs.MY_COURSES;
        }
        if (queryParams.segment === HomeSegment.SAVED) {
            return Tabs.SAVED;
        }

        return Tabs.ALL_COURSES;
    }, [queryParams.segment]);

    const [selectedTab, setSelectedTab] = useState(paramTab);

    const filteredData = useMemo(() => {
        if (selectedTab === Tabs.ALL_COURSES) return courses;
        if (selectedTab === Tabs.MY_COURSES) {
            return [
                ...courses.filter((d) => d.is_purchased || d.publish_free),
                ...receivedCourses,
            ];
        }
        if (selectedTab === Tabs.SAVED)
            return downloadedCourses ? downloadedCourses.savedCourses : [];
    }, [courses, receivedCourses, selectedTab]);

    const searchResults = useMemo(() => {
        return filteredData?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredData, searchQuery]);

    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const getParamsSegment = (activeTab: string) => {
        if (activeTab === Tabs.MY_COURSES) {
            return HomeSegment.MY;
        }
        if (activeTab === Tabs.SAVED) {
            return HomeSegment.SAVED;
        }
        return HomeSegment.ALL;
    };

    const setQueryParamsTab = (tab) => {
        setSelectedTab(tab);
        setQueryParams({
            segment: getParamsSegment(tab),
        });
    };

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalCoursesStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    const finalData = useMemo(() => {
        return paginateData(searchResults, paginationInfo).data;
    }, [searchResults, paginationInfo]);

    useScrollToTop();

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (course: Course | ReceivedCourse) => {
        const url = await getPublicUrl(
            course.image.image_url_prefix,
            course.image.image_url_postfix,
            course.image.image_file_name,
        );

        return {
            id: course.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    const loading = coursesLoading || receivedCoursesLoading;

    useEffect(() => {
        if (coursesStack?.searchQuery !== "") {
            window.scrollTo(0, 500);
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Layout
            paginationInfo={!loading && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 10,
                });
            }}
        >
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title="Courses"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Courses by title/description"}
                    canGoBack={homeStack?.isHomeTrack}
                    onBackClick={() => {
                        if (homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                <Box display={"flex"} justifyContent={"space-around"}>
                    {Object.values(Tabs).map((tab) => (
                        <span
                            key={tab}
                            onClick={() => setQueryParamsTab(tab)}
                            className={clsx(
                                classes.tab,
                                selectedTab === tab && classes.grey600,
                            )}
                        >
                            {tab}
                        </span>
                    ))}
                </Box>

                {finalData && !loading && finalData.length > 0 ? (
                    finalData?.map((c, idx) => (
                        <AppContentListItem
                            nid={c.nid}
                            key={idx}
                            type={
                                (c as Course)?.publish_free ||
                                (c as Course)?.is_purchased ||
                                c.isReceived
                                    ? "courses"
                                    : "purchasableCourses"
                            }
                            sender={(c as any).sender}
                            title={c.title}
                            skuId={c.sku_id}
                            attachedSku={c?.attach_skus}
                            description={c.description}
                            imageUrl={thumbnails && thumbnails[c.nid]}
                            releaseDate={c.release_date}
                            acquiredDate={c.acquired_date}
                            isAllowClick
                            onListItemClick={() => {
                                if (c.isReceived) {
                                    /**received course */
                                    setActiveCourse({
                                        type: ActiveCourseType.RECEIVED,
                                        course: c as ReceivedCourse,
                                    });
                                } else {
                                    setActiveCourse({
                                        type: ActiveCourseType.UNRECIEVED,
                                        course: c as Course,
                                    });
                                }

                                history.push(
                                    linkProvider.react
                                        .courses()
                                        .detail(
                                            c.nid,
                                            c.isReceived ? "true" : "false",
                                            getParamsSegment(selectedTab),
                                        ),
                                );
                            }}
                            //Courses Props
                            publishFree={(c as Course)?.publish_free}
                            isPurchased={(c as Course)?.is_purchased}
                            isRedeemableByToken={
                                (c as Course)?.is_redeemable_by_tokens
                                    ? "1"
                                    : "0"
                            }
                            contentPrice={(c as Course)?.content_price}
                            creditsRequired={(c as Course)?.number_of_tokens}
                            numberOfTokens={(
                                c as Course
                            )?.number_of_tokens?.toString()}
                            isReceived={c.isReceived}
                            isDownloaded={checkIsCrsDownloaded(c)}
                        />
                    ))
                ) : (
                    <Grid
                        className={classes.wrapperLoader}
                        item
                        xs={12}
                        md={12}
                    >
                        <AppNoDataFound
                            notFoundText={messaging?.common?.noDataFound}
                        />
                    </Grid>
                )}

                {loading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.courses}
                    />
                )}
            </div>
        </Layout>
    );
};

const useDownloadStatus = (
    courses: Course[],
    receivedCourses: ReceivedCourse[],
) => {
    const { userDownloadedCourses } = useDownloadedCourses();

    const getUserDownloadedCourse = useCallback(
        (course: Course[], received: ReceivedCourse[]) => {
            let downloadedIds = [];
            userDownloadedCourses.forEach((x) => {
                const allDownloaded = x.steps.every((s) => s.isDownloaded);
                if (allDownloaded) downloadedIds.push(x.nid);
            });

            return [...course, ...received].filter((x) =>
                downloadedIds.includes(x.nid),
            );
        },
        [userDownloadedCourses],
    );

    const downloadedCourses = useMemo(() => {
        const crs = getUserDownloadedCourse(courses, receivedCourses);
        return {
            savedCourses: crs,
            savedCourseIds: crs.map((x) => x.nid),
        };
    }, [userDownloadedCourses, courses, receivedCourses]);

    const checkIsCrsDownloaded = (
        c: Course | ReceivedCourse,
    ): Promise<boolean> => {
        return new Promise((res) => {
            if (downloadedCourses.savedCourseIds.includes(c.nid)) {
                res(true);
            } else {
                res(false);
            }
        });
    };

    return {
        downloadedCourses,
        checkIsCrsDownloaded,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        tab: {
            cursor: "pointer",
            fontSize: "1rem",
            color: theme.palette.grey[300],
            fontWeight: 500,

            "&:hover": {
                color: theme.palette.grey[600],
            },
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - (${theme.footer.height} + ${theme.header.height}))`,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.black,
        },
    }),
);

export interface CoursesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

enum Tabs {
    ALL_COURSES = "All Courses",
    MY_COURSES = "My Courses",
    SAVED = "Saved",
}
