import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import clsx from "clsx";
import { uniqBy } from "lodash-es";
import { InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
    useGlobalGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useScrollWithoutDelay } from "JS/React/Hooks/Media";
import { VideoContent } from "JS/Models";
import { videoCategoryTitleToString } from "JS/Helpers/Helpers";
import { getUniqueValues } from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { PaginationInfo } from "JS/Types/Pagination";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { getDexieConnectionRefresh, VideoDexie } from "JS/Database/Dexie";
import { config } from "JS/Config";
import {
    VideoCategoryTitle,
    useFilterVideoHandlers,
    useVideoAttributesHandlers,
} from "./index";
import { VideoList } from "./Components/VideoList";
import { Layout } from "../Layout";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

export interface VideosContentListingProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const VideosContentListing = (props: VideosContentListingProps) => {
    const classes = useStyles(props);
    const { linkProvider } = useRouting();
    const { className, ...rest } = props;
    const {
        setGlobalVideosStack,
        resetGlobalVideosStack,
        videosStack,
        homeStack,
    } = useGlobalNavStack();
    const [filteredVideos, setFilteredVideos] = useState<VideoContent[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        videosStack?.searchQuery,
    );
    const [headerTitle, setHeaderTitle] = useState("");
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 50,
        total: 0,
    });
    const history = useHistory();

    const { videos, isVideoUpdateRequired } = useGlobalVideos();

    const { loading } = useVideos(
        !!videos && videos.length > 0,
        isVideoUpdateRequired,
    );

    const { receivedMemberVideoGifts, receivedProspectVideoGifts } =
        useGlobalGifts();

    const { loading: receivedMemberVideoGiftsLoading } =
        useReceivedMemberVideoGifts(
            !!receivedMemberVideoGifts && receivedMemberVideoGifts.length > 0,
        );

    const { loading: receivedProspectVideoGiftsLoading } =
        useReceivedProspectVideoGifts(
            !!receivedProspectVideoGifts &&
                receivedProspectVideoGifts.length > 0,
        );

    const {
        mediaEssentials,
        videoUniqueSpeakers,
        videoSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        videoContentSpeakers,
        handleClearFilters,
        handleResetVideoAttributes,
    } = useVideoAttributesHandlers();

    const params: any = useParams();
    const { categoryId } = params;

    const [downloadVideos, setVideos] = useState<VideoDexie[]>([]);

    useEffect(() => {
        (async () => {
            if (categoryId === "saved-videos") {
                const toRetVideos: VideoDexie[] = [];
                await getDexieConnectionRefresh().videos.each((video) =>
                    toRetVideos.push(video),
                );

                setVideos(
                    toRetVideos.filter((d) =>
                        d.id.startsWith(config.user.memberId),
                    ),
                );
            }
        })();
    }, []);

    useEffect(() => {
        if (categoryId === VideoCategoryTitle.ALL_VIDEOS) {
            const data = [
                ...videos,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ];
            setFilteredVideos(getUniqueValues(data, "nid"));
            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.ALL_VIDEOS),
            );
        } else if (categoryId === VideoCategoryTitle.MY_VIDEOS) {
            const videosList = videos?.filter(
                (video) => video.publish_free === "1" || video.is_purchased,
            );
            const data = [
                ...videosList,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.MY_VIDEOS),
            );
        } else if (categoryId === VideoCategoryTitle.RECENTLY_PLAYED) {
            const videosList = videos?.filter((video) => video.played !== "0");
            const data = [
                ...videosList,
                ...receivedMemberVideoGifts.filter(
                    (video) => video.played !== "0",
                ),
                ...receivedProspectVideoGifts.filter(
                    (video) => video.played !== "0",
                ),
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.RECENTLY_PLAYED),
            );
        } else if (categoryId === VideoCategoryTitle.SAVED) {
            const videoNids = [];
            if (downloadVideos) {
                downloadVideos.forEach((a) =>
                    videoNids.push(a.id.split("-")[1]),
                );
            }

            const data = [
                ...videos?.filter((video) => videoNids?.includes(video.nid)),
                ...receivedMemberVideoGifts.filter((video) =>
                    videoNids?.includes(video.nid),
                ),
                ...receivedProspectVideoGifts.filter((video) =>
                    videoNids?.includes(video.nid),
                ),
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.SAVED),
            );
        } else {
            const selectedVideos = videos?.filter(
                (video) => video.content_category_id === categoryId,
            );
            setFilteredVideos(selectedVideos);
            setHeaderTitle(
                selectedVideos[0]?.content_category_title || "Videos",
            );
        }
    }, [
        downloadVideos,
        categoryId,
        videos,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
    ]);

    const { searchResults } = useFilterVideoHandlers(
        filteredVideos,
        searchQuery,
        videoSelectedSpeakers,
        videoContentSpeakers,
    );

    useEffect(() => {
        resetPagination();
        setGlobalVideosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults, videoSelectedSpeakers]);

    const resetPagination = () => {
        const isSearchResultLength =
            videoSelectedSpeakers?.length === 0 ||
            !mediaEssentials?.allow_speaker_feature ||
            searchResults.length < 400;

        const totalRecords = searchResults
            ? isSearchResultLength
                ? searchResults.length
                : 400
            : 0;

        setPaginationInfo({
            ...paginationInfo,
            currentPage: 1,
            perPage: 50,
            total: totalRecords,
        });
    };

    const isContentLoaded =
        !loading &&
        !receivedMemberVideoGiftsLoading &&
        !receivedProspectVideoGiftsLoading &&
        !attributesLoading;

    useScrollWithoutDelay();

    return (
        <Layout
            paginationInfo={isContentLoaded && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 25,
                });
            }}
        >
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Videos"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Videos by title/description"}
                    onSearchFieldClick={resetPagination}
                    onBackClick={() => {
                        setFilteredVideos([]);
                        setSearchQuery("");
                        resetGlobalVideosStack();
                        handleResetVideoAttributes();
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.videos().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                    filterable={mediaEssentials?.allow_speaker_feature}
                    onToggleSpeakerSelect={onToggleSpeakerSelect}
                    uniqueSpeakers={videoUniqueSpeakers}
                    selectedSpeakers={videoSelectedSpeakers}
                    handleClearFilters={handleClearFilters}
                    resetPagination={resetPagination}
                />
                {isContentLoaded ? (
                    <VideoList
                        paginationInfo={paginationInfo}
                        videos={searchResults}
                        searchQuery={searchQuery}
                        categoryId={categoryId}
                        resetPagination={resetPagination}
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !attributesLoading
                                ? messaging.loader.videos
                                : messaging.loader.fectchingSpeakers
                        }
                    />
                )}
            </div>
        </Layout>
    );
};
