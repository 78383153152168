import React from "react";
import clsx from "clsx";
import { Box, Grid, InternalStandardProps, Theme } from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { SortableHandle } from "react-sortable-hoc";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        editIcons: {
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            color: theme.palette.common.black,
        },
        listWarpper: {
            width: "100% !important",
        },
    }),
);

export interface PlaylistElementProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    value: any;
    isEdit: boolean;
    handleAudioRemoval: () => void;
    isDownloadInProgress: boolean;
    isInDownloadQueue: boolean;
    isDownloaded: Promise<boolean>;
    inProgressDownloaded: boolean;
    isResume: boolean;
    isFavouriteContent: boolean;
    imageUrl: string;
    handleListItemClick: () => void;
}

export const PlaylistElement = (props: PlaylistElementProps) => {
    const classes = useStyles(props);
    const {
        value,
        isEdit,
        handleAudioRemoval,
        isDownloadInProgress,
        isInDownloadQueue,
        isDownloaded,
        inProgressDownloaded,
        isResume,
        isFavouriteContent,
        imageUrl,
        handleListItemClick,
    } = props;

    return (
        <Grid display={"flex"} flexDirection={"row"} key={value.nid}>
            {isEdit && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    paddingLeft={"5px"}
                >
                    <Box
                        className={clsx(classes, classes.editIcons)}
                        onClick={handleAudioRemoval}
                    >
                        <FontAwesomeIcon icon={["fas", "square-minus"]} />
                    </Box>
                    <DragHandle />
                </Box>
            )}
            <Box className={classes.listWarpper}>
                <AppContentListItem
                    nid={value.nid}
                    key={value.nid}
                    isDownloadInProgress={isDownloadInProgress}
                    isInDownloadQueue={isInDownloadQueue}
                    isDownloaded={isDownloaded}
                    inProgressDownloaded={inProgressDownloaded}
                    type="media"
                    isReceived={value.isReceived}
                    isResume={isResume}
                    sender={value.sender}
                    title={value.title}
                    skuId={value.sku_id}
                    description={value.description}
                    releaseDate={value.release_date}
                    showPlayIcon={true}
                    showFavIcon={isFavouriteContent}
                    isFavourite={isFavouriteContent}
                    imageUrl={imageUrl}
                    isAllowClick
                    onListItemClick={handleListItemClick}
                />
            </Box>
        </Grid>
    );
};

const DragHandle = SortableHandle((props) => {
    const classes = useStyles(props);

    return (
        <Box
            onClick={(e) => e.stopPropagation()}
            className={clsx(classes, classes.editIcons)}
        >
            <FontAwesomeIcon icon={["fas", "bars"]}></FontAwesomeIcon>
        </Box>
    );
});
